<template>
  <div>
    <div class="themes-tabs-panel">
      <v-tabs ref="tabs" v-model="tab" class="themes-tabs">
        <v-tab
          @click="open(theme.id)"
          class="themes-tab"
          v-for="theme in themes"
          :key="theme.id"
        >
          <div v-if="theme.active" class="theme-active">active</div>
          <span>
            {{ theme.title }}
          </span>
          <EditTheme
            @destroy="destroy"
            :value="theme"
            @submit="setThemes($event)"
          />
        </v-tab>
      </v-tabs>
      <NewTheme @submit="setThemes($event)" />
    </div>
  </div>
</template>

<script>
import NewTheme from "@/pages/themes/new.themes.page";
import EditTheme from "@/pages/themes/edit.themes.page";

export default {
  components: { NewTheme, EditTheme },
  data() {
    return {
      tab: 0,
      themes: [],
      themeSettingsWindow: false,
      newThemeWindow: false,
    };
  },
  mounted() {
    this.initTheme();
  },
  methods: {
    initTheme() {
      this.setThemes();
    },
    destroy(theme) {
      this.themes = this.themes.filter((t) => t.id != theme.id);
    },
    async setThemes(id) {
      const { data } = await this.$api.Theme.get();
      this.themes = [];
      await this.$nextTick();
      this.themes = data;
      await this.$nextTick();
      this.$refs.tabs.callSlider();
      this.open(id || this.$route.params.id || this.themes[0].id);
    },
    async open(id) {
      this.$emit("open", id);
      this.tab = this.themes.findIndex((t) => t.id == id);
    },
  },
};
</script>

<style scoped lang="scss">
.themes-tabs {
  max-width: calc(100% - 125px);
  &-panel {
    margin-top: 20px;
    border: 2px solid var(--v-border-base);
    border-radius: 5px 5px 0 0;
    height: 50px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &::v-deep {
    .v-tabs-bar {
      background: transparent;
    }
  }
}

.theme-active {
  font-size: 8px;
  color: white;
  background: var(--v-primary-base);
  padding: 3px 8px;
  border-radius: 3px;
  margin-right: 10px;
  letter-spacing: 1px;
}
</style>
