<template>
  <div class="submission-content__layout">
    <div class="submission-content__layout-preview">
      <SubmissionContentLayoutSelector
        v-model="layout.preview"
        label="Preview Field"
        :items="mediaFields"
      />
    </div>
    <div class="submission-content__layout-content">
      <div
        v-for="(item, idx) in layout.content"
        :key="idx"
        class="submission-content__layout-content-item"
      >
        <div class="submission-content__layout-content-item-container">
          <SubmissionContentLayoutSelector
            :items="nonMediaFields"
            label="Content Field"
            v-model="item.field_id"
            :rules="[validation.required]"
          />
          <div class="submission-content__layout-content-actions">
            <v-btn icon @click="moveUp(idx)">
              <v-icon>$arrow_up</v-icon>
            </v-btn>
            <v-btn icon @click="moveDown(idx)">
              <v-icon>$arrow_down</v-icon>
            </v-btn>
            <v-btn icon @click="deleteField(item.field_id)">
              <v-icon>$delete</v-icon>
            </v-btn>
          </div>
        </div>
        <CodeEditor label="Styles" v-model="item.styles" />
      </div>

      <v-btn color="primary" @click="addContentField">Add Field</v-btn>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    value: Object,
    mediaFields: Array,
    nonMediaFields: Array,
  },
  data() {
    return {
      layout: { ...this.value },
    };
  },
  methods: {
    addContentField() {
      const uuid = uuidv4();

      this.layout.content.push({
        uuid,
        styles: `.s-${uuid} {\n}`,
      });
      this.form = { ...this.form };
    },
    moveUp(idx) {
      if (idx < 1) return;
      const { content } = this.layout;
      const element = { ...content[idx] };
      content.splice(idx, 1);
      content.splice(idx - 1, 0, element);

      this.form = { ...this.form };
    },
    moveDown(idx) {
      const { content } = this.layout;
      if (idx >= content.length - 1) return;

      const element = { ...content[idx] };
      content.splice(idx, 1);
      content.splice(idx + 1, 0, element);

      this.form = { ...this.form };
    },
    deleteField(id) {
      const { content } = this.layout;
      this.layout.content = content.filter((t) => t.field_id != id);
    },
  },
  watch: {
    layout: {
      deep: true,
      handler() {
        if (JSON.stringify(this.layout) != JSON.stringify(this.value)) {
          this.$emit("input", { ...this.layout });
        }
      },
    },
    value: {
      deep: true,
      handler() {
        if (JSON.stringify(this.layout) != JSON.stringify(this.value)) {
          this.layout = { ...this.value };
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.submission-content {
  &__layout {
    display: flex;
    flex-direction: row-reverse;
    border: 1px solid #e7e7e7;

    &-preview {
      background: #e7e7e7;
      padding: 50px 15px 15px;
      width: 100%;
      max-width: 50%;
      display: flex;
      justify-content: center;
    }

    &-content {
      width: 100%;
      max-width: 50%;
      padding: 15px;

      &-item {
        margin-bottom: 25px;
        border: 1px solid #ccc;
        padding: 10px;

        &-container {
          display: flex;
          align-items: center;
        }
      }
      &-actions {
        margin-left: 10px;
      }
    }
  }
}
</style>
