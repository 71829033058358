<template>
  <TagSelector
    :multiple="multiple"
    :items="list"
    :value="value"
    @input="$emit('input', $event)"
    itemText="title"
    itemValue="id"
  />
</template>

<script>
export default {
  props: {
    value: [String, Number, Array],
    multiple: Boolean,
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.setInternalProjects();
  },
  methods: {
    async setInternalProjects() {
      const { data } = await this.$api.InternalProject.lite();
      this.list = [
        { title: "N/A", id: null },
        ...data.map((t) => ({ ...t, title: `${t.title} (${t.code})` })),
      ];
    },
  },
};
</script>

<style></style>
