<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Custom Component Info">
        <FormRow label="Title" required>
          <TextField placeholder="Title" v-model="form.title"></TextField>
        </FormRow>
      </FormPanel>
      <FormPanel label="Custom Component Code">
        <CustomComponentsSelector v-model="form.imported_component_ids" />
        <div class="custom-component-code">
          <div class="custom-component-code__editor">
            <CodeEditor lang="text/x-vue" v-model="form.code" />
          </div>
          <div class="custom-component-code__result"></div>
        </div>
      </FormPanel>
      <FormPanel label="Custom Component Details">
        <FormRow label="Display on Pages">
          <PagesSelector multiple v-model="form.page_ids"></PagesSelector>
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../../_extends/form.extends.js";

export default {
  extends: mixin,
};
</script>

<style scoped lang="scss"></style>
