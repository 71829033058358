<template>
  <div>
    <v-form ref="form">
      <FormPanels>
        <FormPanel label="Team Info">
          <FormRow required label="Team Name">
            <template #tooltip>
              A name for the group that will be working on your Commons
            </template>
            <TextField
              v-model="form.title"
              :rules="[validation.required]"
              placeholder="Team Name"
            />
          </FormRow>
          <FormRow label="Description">
            <template #tooltip>
              Add any other details about your team here.
            </template>
            <TextArea v-model="form.description" placeholder="Description" />
          </FormRow>
          <!-- <FormRow>
            <template #label>Preview<br />(200x200)</template>
            <Uploader v-model="form.preview" :width="200" :height="200" />
          </FormRow> -->
        </FormPanel>
        <FormPanel label="Team Members">
          <div class="members">
            <div class="add-member">
              <div class="add-member__title">New Member</div>
              <div class="add-member__container">
                <v-text-field
                  ref="email"
                  v-model="invitationEmail"
                  prepend-inner-icon="mdi-account-multiple-plus"
                  type="email"
                  placeholder="Email"
                  :rules="[validation.email]"
                  @keypress.enter="invite"
                />
                <v-btn
                  block
                  color="primary"
                  class="elevation-0"
                  outlined
                  @click="invite"
                >
                  Add
                </v-btn>
              </div>
            </div>
            <div
              v-for="(member, idx) in form.teams_members"
              :key="member.id"
              class="member"
            >
              <div v-if="member.full_name" class="member__name">
                {{ member.full_name }}
              </div>
              <div class="member__email">
                <a :href="`mailto:` + member.email">{{ member.email }}</a>
              </div>
              <div
                class="member__status"
                :class="[`member__status__${member.status}`]"
              >
                {{ member.owner ? "OWNER" : member.status }}
              </div>
              <div class="member__role member-row">
                <div class="member-label">Team Role:</div>
                <div class="member-value">
                  <span v-if="!editableMember(member)">{{
                    rolesMap[member.role]
                  }}</span>
                  <v-menu v-else offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <span class="member-selector" v-bind="attrs" v-on="on">
                        <span> {{ rolesMap[member.role] }} ▾ </span>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(role, idx) in roles"
                        :key="idx"
                        @click="member.role = role"
                      >
                        {{ rolesMap[role] }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>
              <div class="member__scope member-row">
                <div class="member-label">Access Scope:</div>
                <div class="member-value">
                  <span v-if="!editableMember(member)">{{
                    scopesMap[member.scope]
                  }}</span>
                  <v-menu v-else offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <span class="member-selector" v-bind="attrs" v-on="on">
                        <span> {{ scopesMap[member.scope] }} ▾ </span>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(scope, idx) in scopes"
                        :key="idx"
                        @click="member.scope = scope"
                      >
                        {{ scopesMap[scope] }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>
              <div
                v-if="member.scope == 'selected_projects'"
                class="member__scope member-row"
              >
                <div class="member-label">Projects:</div>
                <div class="member-value">
                  <ProjectsSelector
                    v-model="member.project_ids"
                    :disabled="!editableMember(member)"
                    multiple
                  />
                </div>
              </div>
              <div
                v-if="member.who_invited"
                class="member__who-invited member-row"
              >
                <div class="member-label">Invited by:</div>
                <div class="member-value">
                  <b>{{ member.who_invited }}</b>
                </div>
              </div>
              <v-btn
                v-if="
                  member.status != 'rejected_by_admin' || member.prev_status
                "
                :outlined="member.status != 'rejected_by_admin'"
                color="primary"
                class="elevation-0"
                x-small
                @click="removeMember(member, idx)"
                >{{
                  member.status == "rejected_by_admin" ? "Restore" : "Delete"
                }}</v-btn
              >
            </div>
          </div>
        </FormPanel>
      </FormPanels>
      <SubmitFooter v-if="btnText" :refs="$refs" :submit="submit">
        {{ btnText }}
      </SubmitFooter>
    </v-form>
  </div>
</template>

<script>
import mixin from "../_extends/form.extends.js";
export default {
  extends: mixin,
  data() {
    return {
      invitationEmail: "",
      rolesMap: {
        super_admin: "Super Admin",
        admin: "Admin",
        manager: "Content Manager",
      },
      scopesMap: {
        all_projects: "All Projects",
        selected_projects: "Selected Projects",
      },
    };
  },
  computed: {
    roles() {
      const { active_team_role } = this.currentUser;
      if (active_team_role == "super_admin") {
        return ["super_admin", "admin", "manager"];
      } else if (active_team_role == "admin") {
        return ["manager"];
      } else {
        return [];
      }
    },
    scopes() {
      const { active_team_role } = this.currentUser;

      if (["admin", "super_admin"].includes(active_team_role)) {
        return ["all_projects", "selected_projects"];
      } else {
        return [];
      }
    },
  },
  methods: {
    editableMember(member) {
      let editable = member.member_id != this.currentUser.id && !member.owner;
      editable =
        editable &&
        (this.currentUser.active_team_role == "super_admin" ||
          (this.currentUser.active_team_role == "admin" &&
            member.role == "manager"));
      return editable;
    },
    async removeMember(member, idx) {
      // if (member.id) {
      //   const result = await this.$confirm({
      //     title: "Are you sure?",
      //     description: `This member will lost his access to the ${
      //       this.form.title || "current"
      //     } team.`,
      //   });

      //   if (!result) return;
      // }

      if (member.id) {
        if (member.status == "rejected_by_admin") {
          member.status = member.prev_status;
        } else {
          member.prev_status = member.status;
          member.status = "rejected_by_admin";
        }
      } else {
        this.form.teams_members.splice(idx, 1);
      }
    },
    invite() {
      if (!this.$refs.email.validate(true)) return;
      this.form.teams_members.push({
        email: this.invitationEmail,
        status: "new",
        role: "manager",
        scope: "selected_projects",
        project_ids: [],
      });
      this.invitationEmail = "";
    },
  },
};
</script>

<style scoped lang="scss">
.members {
  display: flex;
  margin: -10px;
  flex-wrap: wrap;
}
.member {
  &-selector {
    color: var(--v-primary-base);
    border-bottom: 1px dotted;
  }
  &-row {
    display: flex;
    align-items: center;
    line-height: 1.1;
    margin: 10px 0;
  }
  &-label {
    font-size: 12px;
    text-transform: uppercase;
    margin-right: 10px;
    color: #565353;
  }
  &-value {
    color: #333;
    font-size: 14px;
  }
  &__name {
    font-weight: bold;
  }
  &__status {
    display: inline-block;
    padding: 2px 10px;
    font-size: 10px;
    text-transform: uppercase;
    text-transform: capitalize;
    color: var(--v-text-base);
    white-space: nowrap;
    text-transform: uppercase;

    &__new {
      background: #f7f7f7;
    }

    &__sent {
      background: #becee0;
    }

    &__accepted {
      background: #cce8ce;
    }

    &__rejected_by_admin,
    &__rejected_by_member {
      background: #ff00004f;
    }
  }
}
.member,
.add-member {
  border: thin solid rgba(0, 0, 0, 0.1);
  padding: 25px;
  min-width: 300px;
  max-width: 620px;
  margin: 10px;
}
.add-member {
  background: #f3f3f3;

  &__container {
    text-align: center;
    align-items: center;
    margin: auto;
  }

  &__title {
    font-weight: bold;
    font-size: 20px;
  }

  .v-btn {
  }
}

.members {
  &::v-deep {
    th {
      white-space: nowrap !important;
    }
  }
}
</style>
