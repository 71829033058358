<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="list"
      item-key="id"
      show-expand
      class="elevation-0 data-table"
    >
      <template v-slot:body="{ items }">
        <tbody v-for="item in items" :key="item.id">
          <tr class="data-table__row">
            <td />
            <td>
              {{ item.title }}
            </td>
            <td>
              <router-link :to="`/widgets/countdowns/${item.id}/edit`">
                {{
                  item.countdown_to
                    | moment(
                      'timezone',
                      currentUser.active_project.timezone,
                      'MM/DD/YY h:mm A'
                    )
                }}
              </router-link>
            </td>

            <td class="text-center nowrap">
              <Actions
                :path="`/widgets/countdowns/${item.id}`"
                @destroy="$emit('destroy', item)"
                @duplicate="$emit('duplicate', item)"
              />
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    headers() {
      return [
        {
          text: 'Countdown Name',
          align: 'start',
          value: 'title',
          sortable: false,
        },
        {
          text: 'Countdown To',
          align: 'start',
          value: 'countdown_to',
          sortable: false,
        },
        {
          align: 'center',
          sortable: false,
        },
      ];
    },
  },
};
</script>

<style></style>
