/* eslint-disable */
import Vue from "vue";

const store = {
  namespaced: true,
  state: {
    token: localStorage.getItem("token"),
  },
  mutations: {
    set(state, user) {
      state.current = user;
    },
    setToken(state, token) {
      state.token = token;
      if (token) {
        localStorage.setItem("token", token);
      } else {
        localStorage.removeItem("token");
      }
    },
  },
  actions: {
    async login({ commit, dispatch }, params) {
      const { data } = await Vue.prototype.$api.Auth.login(params);
      commit("setToken", data.token);
      return await dispatch("user/fetch", null, { root: true });
    },
    async register({ commit, dispatch }, params) {
      const { data } = await Vue.prototype.$api.Auth.register(params);
      commit("setToken", data.token);
      return await dispatch("user/fetch", null, { root: true });
    },
    async resetPassword(_, params) {
      return await Vue.prototype.$api.Auth.resetPassword(params);
    },
    async logout({ commit }) {
      commit("setToken", null);
      commit("user/set", null, { root: true });
    },
  },
  getters: {
    token: (state) => state.token,
    isAuthenticated: (state) => !!state.token,
  },
};

export default store;
