<template>
  <div>
    <Banner>
      <template v-slot:title> Dashboard </template>
      <template v-slot:description>
        This is your home base. It displays an overview of events, UGC
        submissions, site views, and more. It also shows a preview of your main
        “timeline”.
      </template>
      <template v-slot:image>
        <img src="@/assets/banners/dashboard.svg" alt="Widgets" />
      </template>
    </Banner>

    <perfect-scrollbar>
      <div class="dashboard__counters">
        <div
          v-for="(counter, idx) in counters"
          :key="idx"
          class="dashboard__counter"
        >
          <div
            class="dashboard__counter-count"
            :style="{
              fontSize: `${36 - (counter.count.toString().length - 2) * 5}px`,
              fontWeight: counter.count.toString().length > 3 ? 'normal' : 300,
            }"
          >
            <div
              class="dashboard__counter-count__blur"
              :style="{
                background: counterGradients[idx % counterGradients.length],
              }"
            />
            <div
              class="dashboard__counter-count__bg"
              :style="{
                background: counterGradients[idx % counterGradients.length],
              }"
            />
            <div class="dashboard__counter-count__value">
              {{ counter.count.toLocaleString() }}
            </div>
          </div>
          <div v-html="counter.title" class="dashboard__counter-title"></div>
        </div>
      </div>
    </perfect-scrollbar>
    <div class="dashboard-entities">
      <div v-if="pages.length" class="dashboard-entity">
        <div class="dashboard-entity__content">
          <v-icon>$file</v-icon>
          <div class="dashboard-entity__title">Pages</div>
          <div class="dashboard-entity__description">
            Libero fermentum diam nibh ut lectus est gravida malesuada. Maecenas
            amet massa, elit tortor sollicitudin non. Odio euismod sem nunc
            consectetur vitae.
          </div>
          <v-btn to="/pages/new" color="primary" rounded class="elevation-0">
            <v-icon>$plus</v-icon>
            <span>New Page</span>
          </v-btn>
        </div>
        <div class="dashboard-entity__items">
          <PageDataCard
            v-for="page in pages"
            :key="page.id"
            class="dashboard-entity__item"
            hide-destroy
            :page="page"
          />
        </div>
        <div class="dashboard-entity__more">
          <v-btn class="elevation-0" color="white" to="/pages" fab>
            <v-icon>$arrow_right</v-icon>
          </v-btn>
        </div>
      </div>
      <div v-if="events.length" class="dashboard-entity">
        <div class="dashboard-entity__content">
          <v-icon>$file</v-icon>
          <div class="dashboard-entity__title">Events</div>
          <div class="dashboard-entity__description">
            Libero fermentum diam nibh ut lectus est gravida malesuada. Maecenas
            amet massa, elit tortor sollicitudin non. Odio euismod sem nunc
            consectetur vitae.
          </div>
          <v-btn to="/events/new" color="primary" rounded class="elevation-0">
            <v-icon>$plus</v-icon>
            <span>New Event</span>
          </v-btn>
        </div>
        <div class="dashboard-entity__items">
          <EventDataCard
            v-for="event in events"
            :key="event.id"
            hide-destroy
            hide-duplicate
            :event="event"
            class="dashboard-entity__item"
          />
        </div>
        <div class="dashboard-entity__more">
          <v-btn class="elevation-0" color="white" fab to="/events">
            <v-icon>$arrow_right</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventDataCard from "@/components/datacard/event.datacard";
import PageDataCard from "@/components/datacard/page.datacard";
export default {
  name: "DashboardPage",
  components: { EventDataCard, PageDataCard },
  data() {
    return {
      counterGradients: [
        "linear-gradient(178.18deg, #FFE78F -13.56%, #E12D1B 91.17%)",
        "linear-gradient(178.18deg, #CABAE6 -13.56%, #E12D1B 91.17%)",
        "linear-gradient(178.18deg, #CCE8CE -13.56%, #E12D1B 91.17%)",
      ],
      counters: [],
      pages: [],
      events: [],
    };
  },
  created() {
    const { redirect } = this.$route.query;
    if (redirect && redirect != "/") {
      return this.$router.push(redirect);
    }

    this.setCounters();
    this.setPages();
    this.setEvents();
  },
  methods: {
    async setEvents() {
      const { data } = await this.$api.Dashboard.events();
      this.events = data;
    },
    async setPages() {
      const { data } = await this.$api.Dashboard.pages();
      this.pages = data;
    },
    async setCounters() {
      const { data } = await this.$api.Dashboard.counters();
      this.counters = data;
    },
  },
};
</script>

<style scoped lang="scss">
.dashboard {
  &__counters {
    margin: 30px 0;
    display: flex;
  }
  &__counters > &__counter:last-child {
    margin: 0;
  }
  &__counter {
    min-height: 165px;
    background: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    align-items: center;
    margin-right: 30px;
    min-width: 330px;
    width: 33.33%;

    &-count {
      width: 100px;
      min-width: 100px;
      height: 100px;
      min-height: 100px;
      color: white;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 36px;
      line-height: 45px;
      border-radius: 50%;
      position: relative;
      margin: 0 40px;

      &__value {
        position: relative;
      }

      &__blur {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 50%;
        filter: blur(10px);
      }

      &__bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 50%;
      }
    }

    &-title {
      font-weight: 900;
      font-size: 20px;
      line-height: 24px;
      white-space: pre-wrap;
    }
  }

  &-entities {
  }
  &-entity {
    background: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    margin-bottom: 30px;
    padding: 40px;
    display: flex;
    position: relative;

    &__content {
      width: 165px;
      min-width: 165px;
    }

    &__title {
      font-weight: 900;
      font-size: 20px;
      line-height: 24px;
    }

    &__description {
      padding: 20px 0;
      font-size: 12px;
    }

    &__items {
      display: flex;
      align-items: center;
      margin-left: 60px;
      overflow: hidden;
    }
    &__item {
      width: 220px;
      height: 220px;
      min-width: 220px;
      min-height: 220px;
      background: var(--v-border-lighten1);
      margin-left: 20px;
    }
    &__more {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateX(50%) translateY(-50%);
      .v-btn {
        border: 1px solid #f0f0f0 !important;
      }
    }
  }
}
</style>
