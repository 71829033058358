<template>
  <div class="projects-page">
    <div class="pull-right">
      <form
        method="post"
        style="display: inline-block"
        action="/api/v1/admin_side/projects/download.csv"
        target="_blank"
        v-if="['super_admin', 'admin'].includes(currentUser.active_team_role)"
      >
        <input
          type="hidden"
          name="token"
          :value="$store.getters['auth/token']"
        />
        <v-btn
          large
          outlined
          color="black"
          type="submit"
          style="margin-right: 10px"
          >Download CSV</v-btn
        >
      </form>
      <v-btn
        :to="`/teams/${currentUser.active_team.id}/edit`"
        large
        outlined
        color="primary"
        v-if="['super_admin', 'admin'].includes(currentUser.active_team_role)"
        >Edit Team</v-btn
      >
    </div>
    <div class="projects-page__group projects-page__featured">
      <div class="page-title">Featured</div>
      <div class="projects">
        <ProjectTile
          v-for="project in featuredProjects"
          :key="project.id"
          :project="project"
          @select="selectProject"
        >
          <template #actions>
            <a
              class="project__small-action"
              @click.stop="makeNotFeatured(project.id)"
              >Remove from Featured</a
            >
          </template>
        </ProjectTile>
        <div class="projects-page__featured-selector-wrapper">
          <Select
            class="projects-page__featured-selector"
            :placeholder="
              featuredProjectsMaxed
                ? '3 Opportunities max'
                : 'Add an Opportunity'
            "
            :items="featuredOptions"
            @input="makeFeatured"
            item-value="id"
            item-text="title"
            :disabled="featuredProjectsMaxed >= 3"
            v-model="featuredModelPlaceholder"
          />
        </div>
      </div>
    </div>
    <div
      class="projects-page__group"
      v-for="type in projectTypes"
      :key="type.value"
      v-show="activeProjects && activeProjects[type.value].length"
    >
      <div class="page-title">{{ type.label }}</div>
      <div class="projects">
        <ProjectTile
          v-for="project in activeProjects[type.value]"
          :key="project.id"
          :project="project"
          @select="selectProject"
        />
      </div>
    </div>
    <v-expansion-panels
      v-if="previousProjects && previousProjects.length"
      class="projects projects-previous"
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="page-title">
          <div>
            <v-icon size="32" class="projects-panel-icon" color="text">
              $chevron_down_bold
            </v-icon>
          </div>
          Previous Opportunities
        </v-expansion-panel-header>
        <v-expansion-panel-content class="projects">
          <ProjectTile
            v-for="project in previousProjects"
            :key="project.id"
            :project="project"
            @select="selectProject"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-expansion-panels
      v-if="
        currentUser.active_team_role == 'super_admin' &&
        archivedProjects &&
        archivedProjects.length
      "
      class="projects projects-recycle-bin"
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="page-title">
          <div>
            <v-icon size="32" class="projects-panel-icon" color="text">
              $chevron_down_bold
            </v-icon>
          </div>
          Recycle Bin
        </v-expansion-panel-header>
        <v-expansion-panel-content class="projects">
          <ProjectTile
            v-for="project in archivedProjects"
            :key="project.id"
            :project="project"
            @restore="unarchiveProject(project)"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import IndexPage from "./index-page";

export default {
  name: "ProjectsPage",
  extends: IndexPage,
  data() {
    return {
      featuredModelPlaceholder: {},
      projectTypes: [
        {
          label: "EVPs",
          value: "evp",
        },
        {
          label: "Gigs",
          value: "gig",
        },
        {
          label: "Ideation Shorts",
          value: "ideation_short",
        },
      ],
    };
  },
  created() {
    this.fetchList();

    this.$root.$on("projects/reload", () => {
      this.fetchList();
    });
  },
  beforeDestroy() {
    this.$root.$off("projects/reload");
  },
  methods: {
    async fetchList() {
      const { data } = await this.$api.Project.lite();
      if (!data.length) {
        return this.$router.push("/projects/new");
      }
      this.list = data;
      this.featuredModelPlaceholder = {};
    },
    async selectProject(project) {
      await this.$api.Project.makeActive({ id: project.id });
      this.$root.$emit("fetchEnv");
      this.$router.push("/");
    },
    async unarchiveProject(project) {
      const confirmed = await this.$confirm({
        title: "Are you sure?",
        description:
          "This project is in the recycle bin and scheduled to be destroyed soon. Are you sure you want to restore it?",
      });
      if (!confirmed) return;
      await this.$api.Project.unarchive({ id: project.id });
      this.fetchList();
    },
    async makeFeatured(id) {
      await this.$api.Project.makeFeatured({ id: id });
      this.fetchList();
    },
    async makeNotFeatured(id) {
      await this.$api.Project.makeNotFeatured({ id: id });
      this.fetchList();
    },
  },
  computed: {
    activeProjects() {
      let allTypes = this.list?.filter((p) => !p.archived && !p.previous);
      if (!allTypes)
        return {
          evp: [],
          gig: [],
          ideation_short: [],
        };
      return {
        evp: allTypes.filter((p) => p.project_type == "evp"),
        gig: allTypes.filter((p) => p.project_type == "gig"),
        ideation_short: allTypes.filter(
          (p) => p.project_type == "ideation_short"
        ),
      };
    },
    featuredOptions() {
      return this.list?.filter(
        (p) =>
          !p.archived &&
          !p.is_template &&
          !p.is_root &&
          !p.is_profile &&
          !p.never_featured &&
          p.slug != "referral" &&
          !p.featured
      );
    },
    archivedProjects() {
      return this.list?.filter((p) => p.archived);
    },
    previousProjects() {
      return this.list?.filter((p) => p.previous);
    },
    featuredProjects() {
      return this.list?.filter((p) => p.featured);
    },
    featuredProjectsMaxed() {
      return this.featuredProjects?.length >= 3;
    },
  },
};
</script>

<style scoped lang="scss">
.projects {
  display: flex;
  flex-wrap: wrap;
  margin: 20px -20px;

  &::v-deep {
    &.theme--light.v-expansion-panels .v-expansion-panel {
      background: transparent;
      &::before {
        box-shadow: none;
      }
    }
    .v-expansion-panel-header {
      color: var(--v-text-base);
      font-size: 36px;
      line-height: 45px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .v-expansion-panel-header__icon {
      display: none;
    }
    .v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
      flex-grow: 0;
    }
    .v-expansion-panel-content__wrap {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .v-expansion-panel.v-expansion-panel--active {
    .projects-panel-icon {
      transform: rotate(180deg);
    }
  }
}

.projects-page {
  max-width: 1200px;
  margin: auto;

  &__featured .projects {
    align-items: flex-end;
  }

  &__featured-selector-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 40px;
    margin-left: 20px;
  }
}
</style>
