<template>
  <div class="new-team">
    <div v-if="hiddenTodos">
      <WaitingApproval />
    </div>
    <div v-else>
      <div class="page-title">New Team</div>

      <Form v-if="form" v-model="form" :submit="create" btnText="Add Team" />
      <Spinner v-else />
    </div>
  </div>
</template>

<script>
import Form from "./_form.teams.page";
export default {
  components: { Form },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();

    this.setTeamAndProject();
  },
  methods: {
    async setTeamAndProject() {
      if (this.hiddenTodos) {
        const team = this.currentUser.teams[0];
        if (team) {
          if (!this.currentUser.active_team_id) {
            await this.$api.Team.makeActive({ id: team.id });
          }

          if (!this.currentUser.active_project_id) {
            const { data: projects } = await this.$api.Project.get();
            if (projects[0]) {
              await this.$api.Project.makeActive({ id: projects[0].id });
            }
          }
          await this.$store.dispatch("user/fetch");
        }
        return this.$router.push("/");
      }
    },
    async setForm() {
      const { data } = await this.$api.Team.new();
      this.form = data;
    },
    async create() {
      const { data } = await this.$api.Team.create(this.form);
      await this.$api.Team.makeActive({ id: data.id });
      this.$router.push("/projects/new");
    },
  },
};
</script>

<style scoped lang="scss">
.new-team {
  max-width: 800px;
  margin: auto;
}
</style>
