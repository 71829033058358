/* eslint-disable */
import Vue from "vue";

const store = {
  namespaced: true,
  state: {
    current: null,
  },
  mutations: {
    set(state, user) {
      state.current = user;
    },
  },
  actions: {
    async fetch({ commit }) {
      const { data } = await Vue.prototype.$api.Profile.get();
      commit("set", data);
      return data;
    },
    async update({ commit }, params) {
      const { data } = await Vue.prototype.$api.Profile.update(params);
      commit("set", data);
      return data;
    },
  },
  getters: {
    current(state) {
      return state.current;
    },
  },
};

export default store;
