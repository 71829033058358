<template>
  <div>
    <div class="page-title">Edit Page</div>
    <Form v-if="form" v-model="form" :submit="update" btnText="Update Page" />
    <Spinner v-else />
  </div>
</template>

<script>
import Form from "./_form.pages";
export default {
  components: { Form },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async update() {
      await this.$api.Page.update(this.form);
      this.$router.push("/pages");
    },
    async setForm() {
      const { data } = await this.$api.Page.get({ id: this.$route.params.id });
      this.form = data;
    },
  },
};
</script>

<style></style>
