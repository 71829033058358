<template>
  <div class="text-block" :class="`text-block__${layout}`">
    <div class="text-block__side">
      <TextblockItem :item="calcList[0]" />
    </div>
    <div
      class="text-block__side text-block__side-double"
      v-if="[2, 3].includes(layout)"
    >
      <TextblockItem :item="calcList[1]" v-if="[2, 3].includes(layout)" />
      <TextblockItem :item="calcList[2]" v-if="[3].includes(layout)" />
    </div>
  </div>
</template>

<script>
import TextblockItem from "./text-block/item.text-block";
export default {
  props: {
    list: Array,
    layout: Number,
  },
  components: { TextblockItem },
  computed: {
    calcList() {
      return this.list.slice(0, this.layout);
    },
  },
};
</script>

<style scoped lang="scss">
.text-block {
  margin: -10px;
  display: flex;
  flex-wrap: wrap;

  &__side {
    min-height: 300px;
    width: 50%;

    .text-block__item-wrapper {
      height: 100%;
    }
  }

  &__1 &__side {
    width: 100%;
  }

  &__3 &__side-double &__item-wrapper {
    height: 50%;
  }
}
</style>
