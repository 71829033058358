<template>
  <v-btn
    rounded
    class="elevation-0 next-btn"
    color="primary"
    large
    @click="$emit('submit')"
    :disabled="disabled"
  >
    <slot>
      Next
      <v-icon size="20">$arrow_right</v-icon>
    </slot>
  </v-btn>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
  },
};
</script>

<style scoped lang="scss">
.next-btn {
  .v-icon {
    margin-left: 5px;
    position: relative;
    top: 1px;
  }
}
</style>
