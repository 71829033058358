<template>
  <div>
    <div class="page-title">New Submission Form</div>
    <Form v-model="form" :submit="create" btnText="Create Submission Form" />
  </div>
</template>

<script>
import Form from "./_form.submission-forms.page";

export default {
  components: { Form },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async setForm() {
      const { data } = await this.$api.SubmissionForm.new();
      this.form = data;
    },
    async create() {
      await this.$api.SubmissionForm.create(this.form);
      this.$router.push("/widgets/submission-forms");
    },
  },
};
</script>

<style></style>
