<template>
  <v-form>
    <FormPanels>
      <FormPanel label="Message Info">
        <FormRow label="User">
          <div style="margin-top: 8px">
            <router-link :to="`/users/${form.user.id}/edit`">{{
              form.user.name
            }}</router-link>
          </div>
        </FormRow>
        <FormRow required label="Message">
          <TextArea placeholder="Message" v-model="form.message"></TextArea>
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter @submit="$emit('submit')">
      {{ btnText }}
      <template #actions>
        <div>
          <v-btn outlined color="primary" elevation="0" rounded large>
            Delete Message
          </v-btn>
          <v-btn outlined color="black" elevation="0" rounded large>
            Delete All User`s Messages
          </v-btn>
        </div>
      </template>
    </SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../../_extends/form.extends.js";
export default {
  extends: mixin,
};
</script>
