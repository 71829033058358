import app from "@/main";
import axiosDefault from "axios";
import Qs from "qs";
import router from "../router";

const axios = axiosDefault.create({
  baseURL: `/api/v1/admin_side`,
});

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

axios.interceptors.request.use(
  (requestConfig) => {
    if (requestConfig.jwt) {
      const token = localStorage.getItem("token");
      if (token) {
        requestConfig.headers["Auth"] = token;
      }
    }

    requestConfig.paramsSerializer = (params) => {
      return Qs.stringify(params, {
        arrayFormat: "brackets",
        encode: false,
      });
    };

    return requestConfig;
  },
  function(error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (responseConfig) => {
    if (responseConfig.data) {
      const { alert, redirect_to } = responseConfig.data;

      if (alert) {
        app.$alert(alert, "success");
      }

      if (redirect_to && router.currentRoute.path != redirect_to) {
        router.push(redirect_to);
      }
    }

    return responseConfig;
  },
  (error) => {
    const { errors, redirect_to } = error.response.data;
    if (errors) {
      app.$alert(errors.join("\n"), "error");
    }

    if (redirect_to && router.currentRoute.path != redirect_to) {
      router.push(redirect_to);
    }

    return Promise.reject(error);
  }
);

export { axios };
