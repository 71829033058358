<template>
  <v-dialog
    @keydown.enter="confirm"
    @click:outside="cancel"
    max-width="600"
    v-model="dialog"
  >
    <v-card>
      <v-btn class="dialog-close" icon @click="cancel">
        <v-icon>$close</v-icon>
      </v-btn>
      <v-card-title class="dialog-title">
        Duplicate everything on this page?
      </v-card-title>

      <v-card-text>
        Should the new page contain duplicated content, events, etc., so that editing the content of one page doesn’t effect the other? Or, should both pages contain the same content?
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="margin-right: 15px" text @click="cancel">
          Cancel
        </v-btn>

        <v-btn color="primary" @click="confirm(true)">
          Duplicate Everything
        </v-btn>

        <v-btn color="primary" @click="confirm(false)">
          Use the Same Content
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PageDuplicateDialog",
  data() {
    return {
      data: {},
      dialog: null,
    };
  },
  mounted() {
    this.$root.$on("PageDuplicateDialog", async (data) => {
      this.dialog = true;
      this.data = data;
    });
  },
  methods: {
    confirm(duplicateContent) {
      this.data.callback({
        confirmed: true,
        duplicateContent: duplicateContent
      });
      this.dialog = false;
      this.data = {};
    },
    cancel() {
      this.data.callback({
        confirmed: false
      });
      this.dialog = false;
      this.data = {};
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-close {
  position: absolute;
  right: 5px;
  top: 5px;
}

.dialog-title {
  padding-top: 25px !important;
}
</style>
