<template>
  <div class="env-selector-dialog-list">
    <div class="env-selector-dialog-list__header">
      <div class="env-selector-dialog-list__title">{{ title }}</div>

      <v-text-field
        v-model="search"
        placeholder="Search"
        hide-details
        dense
        class="env-selector-dialog-list__search"
      ></v-text-field>
    </div>
    <div class="env-selector-dialog-list__items">
      <slot></slot>
      <div class="env-selector-dialog-list__items-wrapper" v-if="items">
        <div v-if="filteredItems.length">
          <div
            class="env-selector-dialog-list__item"
            v-for="item in filteredItems"
            :key="item.id"
          >
            <slot v-bind:item="item" name="item"></slot>
          </div>
        </div>
        <div class="env-selector-dialog-list__empty" v-else>
          {{ placeholder }}
        </div>
      </div>
      <Spinner v-else />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
    title: String,
    placeholder: String,
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    filteredItems() {
      return this.items.filter(
        (t) =>
          !this.search ||
          t.title.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
};
</script>

<style scoped lang="scss">
.env-selector-dialog-list {
  flex: 1;
  margin: 15px;
  position: relative;

  &__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__title {
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 4px;
    width: 100%;
  }

  &__search {
    padding: 0;
    margin: 0;
    width: 200px !important;
    position: relative;
    top: -5px;
  }

  &__empty {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #aaa;
  }

  &__items {
    height: 100%;
    position: relative;
    &-wrapper {
      height: 100%;
      overflow: auto;
    }

    &:after {
      content: "";
      box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
      pointer-events: none;
    }
  }

  &__item {
    border-bottom: 1px solid #dedede;
    margin-bottom: -1px;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-content {
    }
    &-user {
      color: var(--v-primary-base);
      font-size: 10px;
      font-weight: bold;
    }
    &-title {
      font-size: 14px;
      font-weight: bold;
    }
    &-date {
      font-size: 10px;
    }
  }
}
</style>
