<template>
  <Select
    :placeholder="label"
    :items="localOptions"
    :value="localValue"
    item-value="uuid"
    item-text="title"
    @input="$emit('input', $event)"
  />
</template>
<script>
export default {
  props: {
    label: String,
    value: String,
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  computed: {
    localOptions() {
      return [{ title: "N/A", uuid: null }, ...this.options];
    },
  },
};
</script>
