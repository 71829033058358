<template>
  <div class="uploader-wrapper">
    <label class="uploader" @drop.prevent="drop" @dragover.prevent>
      <div class="uploader__placeholder">
        {{ filename || placeholder }}
      </div>
      <v-icon
        class="uploader__delete"
        color="primary"
        @mousedown="remove"
        v-if="src"
        >$delete</v-icon
      >
      <div class="uploader__upload">
        <v-icon size="20">$upload</v-icon>
      </div>
      <input
        :accept="acceptFiletypes"
        @change="handleChange"
        class="uploader__input"
        type="file"
        ref="fileUploader"
      />
    </label>
    <v-text-field
      ref="validation"
      class="uploader__validation"
      :value="value"
      type="hidden"
      hide-details="auto"
      :rules="rules"
    />
    <ImageCropper
      :open="dialog"
      :srcFile="uploadedFile"
      @cancel="onCancel"
      @confirm="onConfirm"
      :width="960"
      cropperClass="file-field-cropper"
      :ratio="ratio"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "FileField",
  props: {
    value: [File, Object],
    placeholder: String,
    ratio: {
      type: Number,
      default: 580 / 320,
    },
    circle: Boolean,
    position: {
      type: String,
      default: "center",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: "cover",
    },
    acceptFiletypes: {
      type: [String, Boolean],
      default: "image/*,video/*",
    },
    enableCropper: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
    uploadedFile: null,
  }),
  methods: {
    handleChange(event) {
      if(event.target.files.length == 0) return;
      const file = event.target.files[0];
      file.uuid = uuidv4();
      if (file) {
        if (this.enableCropper && this.acceptFiletypes.includes("image")) {
          this.openCropper(file);
        } else {
          this.$emit("input", file);
        }
      }
    },
    drop(event) {
      const file = event.dataTransfer.files[0];
      file.uuid = uuidv4();
      if (this.enableCropper && this.acceptFiletypes.includes("image")) {
        this.openCropper(file);
      } else {
        this.$emit("input", file);
      }
    },
    remove() {
      this.$emit("input", null);
      this.$refs.fileUploader.value = null;
    },
    openCropper(file) {
      this.uploadedFile = file;
      this.dialog = true;
    },
    onCancel() {
      this.dialog = false;
      this.$refs.fileUploader.value = null;
    },
    onConfirm(file) {
      this.dialog = false;
      file.uuid = uuidv4();
      this.$emit("input", file);
      this.$refs.fileUploader.value = null;
    },
  },
  computed: {
    src() {
      if (this.value instanceof File) {
        return URL.createObjectURL(this.value);
      }
      if (this.value instanceof Object) {
        return this.value.url;
      }
      return "";
    },
    filename() {
      if (this.value instanceof File) {
        return this.value.name;
      }
      if (this.value instanceof Object) {
        const url = this.value.url;
        if (url) {
          return decodeURI(_.last(url.split("/")));
        }
      }
      return "";
    },
  },
};
</script>

<style scoped lang="scss">
.uploader {
  cursor: pointer;
  transition: 0.2s;
  border: 1px solid var(--v-border-base);
  background-size: cover;
  background-position: center;
  display: inline-flex;
  align-items: center;
  position: relative;
  height: 50px;
  border-radius: 25px;
  width: 100%;

  &-wrapper {
    position: relative;
  }

  &__validation {
    &:not(.v-input--has-state) {
      display: none !important;
    }
    padding: 0 !important;
    &::v-deep {
      .v-input__slot {
        display: none !important;
      }
    }
  }

  &:hover,
  &:hover &__upload {
    border-color: rgba(0, 0, 0, 0.3);
  }

  &__placeholder {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #969595;
    padding: 0 10px;
    flex-grow: 1;
    word-break: break-all;
  }

  &__upload {
    border-left: 1px solid var(--v-border-base);
    height: 100%;
    padding: 8px 10px;
    transition: 0.3s;
  }

  &__upload-btn {
    background: #f5f5f5e0 !important;
  }

  &__input {
    display: none;
  }
}
</style>
