<template>
  <div>
    <Banner theme="orange">
      <template v-slot:title> Projects </template>
      <template v-slot:description> </template>
    </Banner>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="New Project"
      btn-to="/internal_projects/new"
      :sort-by.sync="sortBy"
      :search-text.sync="searchText"
      search-placeholder="Search Project"
    />

    <div class="page-subtitle">Projects</div>
    <ListPanels :loaded="!!list">
      <div v-if="computedList && computedList.length" style="width: 100%">
        <ListPanel
          v-for="section in computedList"
          :key="section.id"
          :action-path="`/internal_projects/${section.id}`"
          @destroy="destroySection(section)"
          hide-duplicate
        >
          <template #title>
            <span>
              {{ section.title }}
            </span>
            <span>({{ section.code }})</span>
            <span class="dates">
              {{
                section.start_date
                  | moment(
                    "timezone",
                    currentUser.active_project.timezone,
                    "MM/DD/YY h:mm A zz"
                  )
              }}
              –
              {{
                section.end_date
                  | moment(
                    "timezone",
                    currentUser.active_project.timezone,
                    "MM/DD/YY h:mm A zz"
                  )
              }}
            </span>
          </template>
        </ListPanel>
      </div>
      <div v-else class="no-entries">No Projects</div>
    </ListPanels>
  </div>
</template>

<script>
import IndexPage from "./index-page";

export default {
  name: "InternalProjectsPage",
  extends: IndexPage,
  methods: {
    async fetchList() {
      const { data } = await this.$api.InternalProject.get({
        sort_by: this.sortBy,
      });
      this.list = data;
    },
    async destroySection({ id }) {
      const confirmed = await this.$confirm({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.InternalProject.destroy({ id });
      this.list = this.list.filter((t) => t.id != id);
    },
  },
};
</script>

<style scoped lang="scss">
.dates {
  margin-left: 50px;
  font-size: 18px;
  color: rgb(83, 83, 83);
  font-weight: normal;
}
</style>

<style lang="scss">
.env-production {
  .dates {
    color: white;
  }
}
</style>
