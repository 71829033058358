<template>
  <div class="project-selector">
    <div class="project-selector__container">
      <!-- <div class="project-selector__preview">
        <div
          :style="{
            backgroundImage: `url(${currentUser.active_project.preview.thumb.url})`,
          }"
          v-if="currentUser.active_project.preview.thumb.url"
          class="project-selector__preview-image"
        ></div>
        <Avatar
          :id="currentUser.active_project.id"
          :name="currentUser.active_project.title"
          v-else
        />
      </div> -->
      <div class="project-selector__content">
        <div class="project-selector__title">
          {{ currentUser.active_project.title }}
        </div>
        <div class="project-selector__sub-title">
          <router-link class="switch-opps-link" to="/projects">Switch Opportunity</router-link>
        </div>
      </div>
    </div>
    <div class="project-selector__actions">
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.project-selector {
  cursor: pointer;
  justify-content: space-between;
  text-decoration: none;
  margin-left: 0;
  margin-top: 25px;
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  border: 1px solid #dcdce0;
  border-bottom: none;
  border-left: none;
  border-right: none;
  width: 100%;
  padding-top: 23px;

  &__title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.07143em;
    line-height: 1.286;
    margin-bottom: 0.25em;
  }

  &__sub-title {
    font-size: 12px;
    font-weight: 500;
    color: #a5a5a5;
  }

  &__container {
    padding: 10px;
    padding-left: 45px;
    width: 100%;
    color: var(--v-text-base);
    text-decoration: none;
    cursor: default;

    // &:hover {
    //   background: #ebebeb;
    // }
  }

  .v-icon {
    display: block;
  }

  &,
  &__container {
    display: flex;
    align-items: center;
  }

  &__action {
    padding: 5px;
    color: var(--v-text-base);

    &:hover {
      background: #ebebeb;
    }

    &.router-link-exact-active {
      background: var(--v-primary-base);
      color: white;
    }

    .v-icon {
      color: inherit;
    }
  }
  &__actions {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    transition: 0.3s;
    background: transparent;
    margin: 0 10px;
  }

  &__preview {
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
    border-radius: 30px;
    margin-right: 7px;
    background: #e7e7e7;
    background-size: cover;
    background-position: center;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    background: white;
    overflow: hidden;

    &-image {
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
    }
  }
}

.switch-opps-link {
  color: var(--v-primary-base);
  text-decoration: none;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
  &::after {
    content: '';
    display: inline-block;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQuNjc5ODUgOS41ODg5MUw3Ljg4MzcxIDYuMzg0OUM4LjA5NTU3IDYuMTczMDMgOC4wOTU1NyA1LjgyNjk3IDcuODgzNzEgNS42MTUxTDQuNjc5ODUgMi40MTEwOUM0LjMzNjE2IDIuMDY3MzggMy43NSAyLjMwOTg2IDMuNzUgMi43OTcxN0wzLjc1IDkuMjAyODNDMy43NSA5LjY5MDE0IDQuMzM2MTYgOS45MzI2MiA0LjY3OTg1IDkuNTg4OTFaIiBmaWxsPSIjRTEyRDFCIi8+Cjwvc3ZnPgo=");
    width: 1em;
    height: 1em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
</style>
