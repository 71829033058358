import { axios } from "@/api/api.config";
import objectToFormData from "./object-to-formdata";

export default (name) => ({
  new: (params) => axios.get(`/${name}/new`, { params, jwt: true }),
  get: (params = {}) =>
    axios.get(`/${name}${params.id ? "/" + params.id : ""}`, {
      params,
      jwt: true,
    }),
  create: (params, additional) =>
    axios.post(`/${name}`, objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const loaded = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (additional?.onUploadProgress) {
          return additional.onUploadProgress(loaded);
        }
      },
    }),
  update: (params, additional) =>
    axios.put(`/${name}/${params.id}`, objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const loaded = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (additional?.onUploadProgress) {
          return additional.onUploadProgress(loaded);
        }
      },
    }),
  destroy: (params) =>
    axios.delete(`/${name}/${params.id}`, { params, jwt: true }),
});
