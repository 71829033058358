import _ from "lodash";

export default {
  methods: {
    async duplicateProject(project) {
      let projectDuplicateParams = [
        "id",
        "title",
        "staging_domain",
        "production_domain",
        "restrict_production_access",
        "production_redirect_enabled",
        "production_redirect_url",
        "production_login",
        "production_password",
        "restrict_staging_access",
        "staging_login",
        "staging_password",
        "production_root_path",
        "staging_root_path",
        "headline",
        "project_type",
        "published",
      ];
      const projectParams = _.pick(project, projectDuplicateParams);
      await new Promise((resolve) => {
        this.$root.$emit("ProjectDuplicateDialog", {
          project: projectParams,
          callback: (value) => {
            resolve(value);
            //this.$router.go(`/projects/${value.project_id}/edit`);
            this.$router.push("/projects").catch(err => err)
            this.$root.$emit("projects/reload");
          },
        });
      });
    },
  }
};
