<template>
  <div class="form-row">
    <div class="form-label">
      <span class="form-label-text">
        <slot name="label">
          {{ label }}
        </slot>
        <span v-if="!clean">
          <span class="form-required" v-if="required">*</span>
        </span>
        <v-tooltip open-delay="500" v-if="!!tooltipExists" max-width="300" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" size="20" class="form-question"
              >$question_round</v-icon
            >
          </template>
          <span>
            <slot name="tooltip">
              {{ tooltip }}
            </slot>
          </span>
        </v-tooltip>
      </span>
      <div class="form-additional">
        <slot name="additional"></slot>
      </div>
      <div class="form-optional" v-if="!clean && !required">
        (optional)
      </div>
    </div>
    <div class="form-inputs">
      <slot></slot>
    </div>
    <div class="form-public-toggle" v-if="publicSettings">
      <div v-if="publicSettings.key">
        <v-switch
          inset
          dense
          @change="publicChange"
          v-model="publicState"
          color="#7AC182"
        ></v-switch>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    required: Boolean,
    clean: Boolean,
    empty: Boolean,
    tooltip: String,
    encouraged: Boolean,
    publicSettings: Object,
  },
  data() {
    return {
      publicState: false
    };
  },
  created(){
    this.publicState = (this.publicSettings && this.publicSettings.value) ? this.publicSettings.value[this.publicSettings.key] : false;
  },
  methods: {
    publicChange(){
      this.publicSettings.value[this.publicSettings.key] = this.publicState;
    }
  },
  computed: {
    tooltipExists() {
      return this.$slots.tooltip || this.tooltip;
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  &-question {
  }
  &-row {
    padding: 10px 0;
    display: flex;
    width: 100%;
  }
  &-additional {
    font-size: 14px;
    line-height: 1.2;
    color: var(--v-text-base);
    &:not(:empty) {
      margin-bottom: .25em
    }
  }
  &-label {
    font-weight: bold;
    font-size: 14px;
    line-height: 1.2;
    color: var(--v-text-base);
    min-width: 250px;
    margin-top: 15px;

    &-text {
      display: flex;
      align-items: top;
    }
  }
  &-inputs {
    width: 100%;
    padding: 0 30px;
  }

  &-required {
    color: var(--v-primary-base);
    margin-left: 3px;
    font-size: 14px;
  }

  &-optional {
    color: #ccc;
    text-transform: none;
  }

  &-question {
    margin-left: 5px;
    cursor: help;
    position: relative;
    top: -1px;
  }
  
  &-public-toggle {
    min-width: 3em;
    &::v-deep {
      .v-input--switch__track {
        border: 1px #979797 solid;
        background-color: white;
      }
      .v-input--switch__thumb {
        border: white solid 3px;
        color: var(--v-primary-base);
      }
      .v-input--selection-controls {
        margin-top: 14px;
        padding-top: 0;
      }
      .v-input--dense > .v-input__control > .v-input__slot {
        margin-bottom: 0;
      }
      .v-messages.theme--light {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .form-row {
    flex-direction: column;
  }

  .form-inputs {
    padding: 5px 0;
  }
}
</style>
