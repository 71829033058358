var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"elevation-0 app-bar",attrs:{"height":['sm', 'xs'].includes(_vm.$size) ? 70 : 83,"color":"primary","app":"","clipped-left":""}},[_c('div',{staticClass:"menu-toggle"},[_c('v-btn',{attrs:{"icon":"","color":"white"},on:{"click":function($event){return _vm.$emit('toggleMenu')}}},[_c('v-icon',{attrs:{"size":"30"}},[_vm._v("$menu")])],1)],1),_c('v-toolbar-title',{staticClass:"toolbar-title"},[_c('router-link',{staticClass:"logo-link",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/logo.svg"),"width":"300","alt":"Logo"}})])],1),_c('v-spacer'),(_vm.currentUser.active_team)?_c('Search',{staticClass:"menu-search",attrs:{"min-width":"400px","placeholder":"Search"}}):_vm._e(),_c('v-spacer'),(
      _vm.currentUser.active_team && _vm.currentUser.active_team_role == 'super_admin'
    )?_c('v-btn',{staticClass:"elevation-0 app-bar__item-create",attrs:{"color":"white"},on:{"click":_vm.openNewOppDialog}},[_vm._v("Create Opportunity")]):_vm._e(),(_vm.currentUser.onboarding_finished)?_c('v-menu',{attrs:{"offset-y":"","content-class":"app-bar__item-menu"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"elevation-0 app-bar__item app-bar__item-profile",attrs:{"color":"primary"}},'div',attrs,false),on),[(!_vm.currentUser.avatar.url)?_c('div',{staticClass:"account-item-icon"},[_c('v-icon',{attrs:{"color":"text"}},[_vm._v("$account")])],1):_c('div',[_c('div',{staticClass:"account-item-preview",style:({
              backgroundImage: ("url(" + (_vm.currentUser.avatar.thumb.url) + ")"),
            })})]),_c('div',[_c('div',{staticClass:"app-bar__full-name"},[_vm._v(_vm._s(_vm.currentUser.full_name))]),(_vm.currentUser.active_team)?_c('div',{staticClass:"app-bar__team"},[_vm._v(" "+_vm._s(_vm.currentUser.active_team.title)+" ")]):_vm._e()]),_c('v-icon',{staticClass:"app-bar__item-chevron_down",attrs:{"color":"white","size":"16"}},[_vm._v("$chevron_down")])],1)]}}],null,false,394483365)},[_c('v-list',[(_vm.currentUser.teams.length)?_c('div',[_c('div',{staticClass:"app-bar__item-menu-label"},[_vm._v("Teams")]),_vm._l((_vm.currentUser.teams),function(team){return _c('div',{key:team.id,staticClass:"app-bar__item-menu-item",attrs:{"dense":""},on:{"click":function($event){return _vm.setTeam(team)}}},[_c('div',{staticClass:"app-bar__team-title"},[_vm._v(" "+_vm._s(team.title)+" ")])])}),(!_vm.hiddenTodos)?_c('router-link',{staticClass:"app-bar__item-menu-item",attrs:{"to":"/teams/new","dense":""}},[_c('div',{staticClass:"app-bar__item-menu__new-team"},[_vm._v("+ New Team")])]):_vm._e(),_c('v-divider')],2):_vm._e(),_c('router-link',{staticClass:"app-bar__item-menu-item",attrs:{"to":"/settings","dense":""}},[_vm._v(" Settings ")]),_c('div',{staticClass:"app-bar__item-menu-item",attrs:{"dense":""},on:{"click":_vm.logout}},[_vm._v("Logout")])],1)],1):_vm._e(),(!_vm.currentUser.onboarding_finished)?_c('div',[_c('v-btn',{staticClass:"elevation-0 app-bar__item app-bar__item-create",attrs:{"color":"white"},on:{"click":_vm.logout}},[_vm._v("Sign out")])],1):_vm._e(),_c('NewOpportunityDialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }