<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Your Profile">
        <FormRow required label="First Name">
          <TextField
            placeholder="First Name"
            v-model="form.first_name"
            :rules="[validation.required]"
          ></TextField>
        </FormRow>
        <FormRow required label="Last Name">
          <TextField
            placeholder="Last Name"
            v-model="form.last_name"
            :rules="[validation.required]"
          ></TextField>
        </FormRow>
        <FormRow label="Company">
          <TextField placeholder="Company" v-model="form.company"></TextField>
        </FormRow>
        <FormRow label="Title">
          <TextField placeholder="Title" v-model="form.title"></TextField>
        </FormRow>
        <FormRow>
          <template #tooltip
            >This image will be used across the Admin site.</template
          >
          <template #label>Profile Picture<br />(200x200)</template>
          <Uploader v-model="form.avatar" :ratio="1" @input="$emit('input', form)" enableCropper/>
        </FormRow>
      </FormPanel>
      <FormPanel v-if="full" label="Teams">
        <v-data-table
          hide-default-footer
          :headers="teamsHeaders"
          :items="form.teams"
          :items-per-page="5"
          class="teams-table elevation-1"
          v-if="form.teams.length"
        >
          <template v-slot:body="{ items }">
            <tbody v-for="(item, idx) in items" :key="idx">
              <tr>
                <td>
                  {{ item.title }}
                </td>
                <td style="width: 1px">
                  <v-btn
                    class="elevation-0"
                    color="primary"
                    small
                    outlined
                    @click="exitTeam(item)"
                    >Exit</v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <div v-else class="no-entries">
          <div style="margin-bottom: 10px">
            No Teams
          </div>
          <v-btn to="/teams/new" outlined rounded color="primary"
            >Add Team</v-btn
          >
        </div>
      </FormPanel>
      <FormPanel v-if="full" label="Email & Password">
        <div v-if="!unlocked">
          <FormRow clean label="Current Password">
            <div class="login-unlock">
              <TextField
                placeholder="Current Password"
                v-model="form.current_password"
                type="password"
              ></TextField>
              <v-btn @click="unlock" color="primary" outlined>Unlock</v-btn>
            </div>
          </FormRow>
        </div>
        <div v-else>
          <FormRow required label="Email">
            <TextField placeholder="Email" v-model="form.email"></TextField>
          </FormRow>
          <FormRow label="New Password">
            <TextField
              type="password"
              placeholder="New Password"
              v-model="form.new_password"
            ></TextField>
          </FormRow>
          <FormRow label="New Password Confirmation">
            <TextField
              type="password"
              placeholder="New Password Confirmation"
              v-model="form.new_password_confirmation"
            ></TextField>
          </FormRow>
        </div>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" v-if="btnText" :submit="submit">{{
      btnText
    }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../_extends/form.extends.js";

export default {
  extends: mixin,
  props: {
    full: Boolean,
  },
  data() {
    return {
      unlocked: false,
      teamsHeaders: [
        {
          text: "Team Name",
          align: "start",
          value: "title",
        },
        {},
      ],
    };
  },
  methods: {
    async unlock() {
      await this.$api.Profile.unlock({
        password: this.form.current_password,
      });

      this.unlocked = true;
    },
    async exitTeam(team) {
      const confirmed = await this.$confirm({
        title: "Are you sure?",
        description: "You will lost access to all projects of the team.",
      });
      if (!confirmed) return;
      await this.$api.Team.exit(team);
      this.form.teams = this.form.teams.filter((t) => t.id != team.id);
      this.$store.dispatch("user/fetch");
    },
  },
};
</script>

<style scoped lang="scss">
.login-unlock {
  display: flex;
  align-items: center;
  max-width: 350px;

  .v-btn {
    margin-left: 15px;
  }
}

.teams-table {
  max-width: 300px;
}
</style>
