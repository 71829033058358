<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Creator Info">
        <FormRow>
          <template #label>Profile Picture<br />(200x200)</template>
          <Uploader
            v-model="form.avatar"
            :ratio="1"
            @input="$emit('input', form)"
            enableCropper
          />
        </FormRow>
        <FormRow required label="Email">
          <TextField placeholder="Email" v-model="form.email"></TextField>
        </FormRow>
        <FormRow required label="Password">
          <template #additional>
            <a @click="createSecurePassword()" class="form__secure-pw-link">
              <v-icon size="10">$lock</v-icon> Create secure password
            </a>
          </template>
          <TextField
            type="password"
            placeholder="Password"
            v-model="form.password"
            :key="`pw_${passwordKey}`"
          ></TextField>
        </FormRow>
        <FormRow required label="Confirm Password">
          <TextField
            type="password"
            placeholder="Password"
            v-model="form.password_confirmation"
            :key="`pw_${passwordKey}`"
          ></TextField>
        </FormRow>
        <v-divider></v-divider>
        <FormRow required label="First Name">
          <TextField
            placeholder="First Name"
            v-model="form.first_name"
          ></TextField>
        </FormRow>
        <FormRow required label="Last Name">
          <TextField
            placeholder="Last Name"
            v-model="form.last_name"
          ></TextField>
        </FormRow>
        <v-divider></v-divider>
        <FormRow label="Account Type" required>
          <Select
            placeholder="Account Type"
            v-model="form.account_type"
            :items="accountTypes"
            :rules="[validation.required]"
            style="width: 350px"
          />
        </FormRow>
        <FormRow
          label="Doing Business As"
          v-show="form.account_type == 'business'"
          :required="form.account_type == 'business'"
        >
          <TextField
            placeholder="Doing Business As"
            v-model="form.doing_business_as"
            :rules="
              form.account_type == 'business' ? [validation.required] : []
            "
            style="width: 350px"
          ></TextField>
        </FormRow>
        <FormRow
          label="Business"
          v-show="form.company"
          tooltip="This private field has been disabled in favor of an explicitly-public “Doing Business As” field for business accounts. You can clear this field and save, or use it for the Doing Business As for a business account, if appropriate."
          clean
        >
          <template #additional>
            <span class="form-optional">(deprecated)</span>
          </template>
          <div class="deprecated-clearable-field">
            <span class="deprecated-clearable-field__content">
              {{ form.company }}
            </span>
            <v-btn icon @click="form.company = ''"
              ><v-icon>$close</v-icon></v-btn
            >
          </div>
        </FormRow>
        <!-- <FormRow label="Title">
          <TextField placeholder="Title" v-model="form.title"></TextField>
        </FormRow> -->
        <v-divider></v-divider>
        <FormRow label="Birthday">
          <DateTimePicker
            placeholder="Birthday"
            v-model="form.birthday"
            only-date
            :start-year="new Date().getFullYear() - 40"
            :end-year="new Date().getFullYear() - 6"
          ></DateTimePicker>
        </FormRow>
        <v-divider></v-divider>
        <FormRow label="Agree to terms">
          <Checkbox
            label="Agree to terms"
            v-model="form.agree_to_terms"
            disabled
          ></Checkbox>
        </FormRow>
        <FormRow label="Sign me up for communications">
          <Checkbox
            label="Sign me up for communications"
            v-model="form.sign_me_up"
            disabled
          ></Checkbox>
        </FormRow>
      </FormPanel>
      <FormPanel label="Creator Badges">
        <FormRow label="Badges">
          <BadgePickSelector multiple v-model="form.badge_ids" />
        </FormRow>
      </FormPanel>
      <FormPanel label="Creator Extra Info">
        <FormRow label="Profile Header Image (Desktop)" :public-settings="{}">
          <FileField
            placeholder="Desktop Image"
            v-model="form.profile_header_image"
            enableCropper
            acceptFiletypes="image/*"
            :ratio="1500 / 245"
          ></FileField>
        </FormRow>
        <FormRow label="Profile Header Image (Mobile)" :public-settings="{}">
          <FileField
            placeholder="Mobile Image"
            v-model="form.profile_header_image_mobile"
            enableCropper
            acceptFiletypes="image/*"
            :ratio="375 / 418"
          ></FileField>
        </FormRow>
        <FormRow label="Bio" :public-settings="{}">
          <TextArea placeholder="Bio" v-model="form.bio"></TextArea>
        </FormRow>
        <FormRow
          label="Pronouns"
          encouraged
          :public-settings="{ key: 'pronouns', value: form.public_fields }"
        >
          <Select
            :items="datasources.pronouns"
            v-model="form.pronouns_multi"
            multiple
          ></Select>
        </FormRow>
        <FormRow
          label="Contact Email"
          encouraged
          :public-settings="{ key: 'contact_email', value: form.public_fields }"
        >
          <TextField
            :placeholder="'Contact Email'"
            v-model="form.contact_email"
          ></TextField>
        </FormRow>
        <FormRow label="Primary Specialty" :public-settings="{}">
          <div class="primary-specialty">
            <Select
              class="primary-specialty__select"
              placeholder="Choose One"
              itemText="title"
              itemValue="id"
              :items="datasources.skills"
              v-model="form.primary_role_id"
            ></Select>
            <!-- <v-checkbox
              label="iU Approved"
              :value="form.primary_role_id"
              :input-value="
                form.primary_role_id == form.primary_role_verified_id
              "
              :key="form.primary_role_id"
              v-model="form.primary_role_verified_id"
            /> -->
          </div>
        </FormRow>
        <FormRow
          label="Secondary Specialty(s)"
          :public-settings="{ key: 'skill_ids', value: form.public_fields }"
        >
          <SkillsSelector multiple v-model="form.users_skills" />
        </FormRow>

        <FormRow
          label="Primary Working Location"
          encouraged
          :public-settings="{}"
        >
          <LocationSelector
            source="place_name_en"
            v-model="form.primary_working_location"
            placeholder="City, State/Province, Country"
          ></LocationSelector>
        </FormRow>
        <FormRow
          label="Secondary Working Location(s)"
          :public-settings="{
            key: 'secondary_working_location',
            value: form.public_fields,
          }"
        >
          <LocationSelector
            source="place_name_en"
            v-model="form.secondary_working_location"
            placeholder="City, State/Province, Country"
          ></LocationSelector>
        </FormRow>
        <FormRow
          label="School"
          :public-settings="{ key: 'school', value: form.public_fields }"
        >
          <template #additional>If applicable</template>
          <SchoolSearch
            @input="updateSchool"
            :initial-value="form.school"
          ></SchoolSearch>
        </FormRow>
        <FormRow
          label="Grad Year"
          :public-settings="{ key: 'grad_year', value: form.public_fields }"
        >
          <Select
            placeholder="Grad Year"
            :items="years"
            v-model="form.grad_year"
          ></Select>
        </FormRow>

        <FormRow
          label="Instagram"
          :public-settings="{ key: 'instagram', value: form.public_fields }"
        >
          <TextField
            placeholder="Instagram"
            v-model="form.instagram"
            :rules="[validation.instagramHandle]"
          ></TextField>
        </FormRow>
        <!-- <FormRow
          label="Vimeo"
          :public-settings="{ key: 'vimeo', value: form.public_fields }"
        >
          <TextField
            placeholder="Vimeo"
            v-model="form.vimeo"
          ></TextField>
        </FormRow> -->
        <FormRow
          label="LinkedIn"
          :public-settings="{ key: 'linkedin', value: form.public_fields }"
        >
          <TextField
            :rules="[validation.strictUrl]"
            placeholder="LinkedIn"
            v-model="form.linkedin"
          ></TextField>
        </FormRow>

        <FormRow label="Work Examples" encouraged :public-settings="{}">
          <template #additional>Maximum of 3</template>
          <WorkExamplesEditor v-model="form.work_examples" />
        </FormRow>

        <FormRow>
          <template #label>Resume</template>
          <FileField v-model="form.resume" placeholder="Upload Resume" />
        </FormRow>

        <FormRow
          label="Website"
          :public-settings="{
            key: 'website_detailed',
            value: form.public_fields,
          }"
        >
          <div class="form__website">
            <TextField
              placeholder="Website Title"
              v-model="form.website_detailed.title"
            ></TextField>
            <TextField
              :rules="[validation.url]"
              placeholder="Url"
              v-model="form.website_detailed.url"
            ></TextField>
          </div>
        </FormRow>
      </FormPanel>

      <!-- <FormPanel label="Creator Links">
        <FormRow label="IMDB">
          <TextField
            :rules="[validation.url]"
            placeholder="IMDB"
            v-model="form.imdb"
          ></TextField>
        </FormRow>
        <FormRow :rules="[validation.url]" label="Reel">
          <TextField placeholder="Reel" v-model="form.reel"></TextField>
        </FormRow>
      </FormPanel>
      -->
      <FormPanel label="Creator Demography">
        <FormRow label="Race">
          <Select
            v-model="form.races_multi"
            :items="datasources.races"
            multiple
          />
        </FormRow>
        <FormRow
          label="Other Race"
          v-if="form.races_multi && form.races_multi.includes('other')"
        >
          <TextField
            placeholder="Other Race"
            v-model="form.custom_race"
          ></TextField>
        </FormRow>
        <FormRow label="Pronouns">
          <TextArea placeholder="Pronouns" v-model="form.pronouns"></TextArea>
        </FormRow>
      </FormPanel>
      <FormPanel label="Creator Contact">
        <FormRow label="Street Address">
          <TextField
            placeholder="Street Address"
            v-model="form.street"
          ></TextField>
        </FormRow>
        <FormRow label="Location">
          <LocationSelector
            source="place_name_en"
            v-model="form.location"
            placeholder="City, State, Country"
          ></LocationSelector>
        </FormRow>
        <FormRow label="Zipcode">
          <TextField placeholder="Zipcode" v-model="form.zip"></TextField>
        </FormRow>
        <FormRow label="Phone Number">
          <TextField
            placeholder="Phone Number"
            v-model="form.phone"
          ></TextField>
        </FormRow>
        <FormRow label="Payment Preference">
          <Select
            :items="datasources.payment_preferences"
            v-model="form.payment_preference"
          />
        </FormRow>
      </FormPanel>
      <FormPanel label="Registration Form Responses" v-if="showRegistration()">
        <div class="registration-note">
          Registered via “{{ registrationForm.title }}”
        </div>
        <FormRow
          v-for="(val, idx) in registrationFormFiltered"
          :label="val.label || val.title"
          :key="idx"
          clean
        >
          <div class="extra-field">
            <div class="extra-value" :class="`extra-value-${val.type}`">
              <TextField
                v-if="val.type == 'TextField' || val.type == 'EmailField'"
                :placeholder="val.label"
                v-model="form.extra[val.uuid]"
                :type="val.type == 'EmailField' ? 'email' : 'text'"
              />
              <Checkbox
                v-else-if="val.type == 'Checkbox'"
                v-model="form.extra[val.uuid]"
              />
              <Uploader
                v-else-if="val.type == 'MediaField'"
                :ratio="320 / 180"
                v-model="form.extra[val.uuid]"
              />
              <RadioGroup
                v-else-if="val.type == 'Radio'"
                v-model="form.extra[val.uuid]"
                :items="val.options"
                item-value="uuid"
                item-text="title"
              />
            </div>
          </div>
        </FormRow>
      </FormPanel>
      <FormPanel label="Creator Extra Information" v-if="showExtra()">
        <FormRow v-for="(val, key) in form.extra" :label="key" :key="key" clean>
          <div class="extra-field">
            <div class="extra-value">
              <TextField
                :placeholder="key"
                v-model="form.extra[key]"
              ></TextField>
            </div>
            <v-btn
              v-if="['super_admin'].includes(currentUser.active_team_role)"
              outlined
              color="primary"
              x-small
              @click="deleteField(key)"
              >Delete</v-btn
            >
          </div>
        </FormRow>
        <FormRow
          clean
          v-if="['super_admin'].includes(currentUser.active_team_role)"
        >
          <v-btn @click="showAddField = true" color="primary">
            + Add field
          </v-btn>
        </FormRow>
      </FormPanel>
      <FormPanel label="Creator Details">
        <FormRow
          label="Block User"
          tooltip="Check the box if this user needs to be blocked from accessing content on your site"
        >
          <Checkbox v-model="form.blocked" />
        </FormRow>
        <FormRow label="Roles">
          <template #tooltip>
            Here you can assign your pre-exisitng "roles" to a user.
          </template>
          <RolesSelector multiple v-model="form.role_ids"></RolesSelector>
        </FormRow>

        <FormRow
          label="Email Validation Results"
          tooltip="These are the results of an email validation check, from when the user registered."
          v-if="
            form.email_validation_results &&
            form.email_validation_results.errors
          "
        >
          <div v-if="form.email_validation_results.errors">
            Errors: {{ form.email_validation_results.errors.length }}
          </div>
          <div
            v-for="(result, index) in form.email_validation_results.results"
            :key="index"
          >
            <div>
              <span class="email-validation-results__email"
                >{{ result.email }}:
              </span>
              <span>{{ result.verdict }}</span>
            </div>

            <div>Source: {{ result.source }}</div>
            <div v-if="result.suggestion">
              (Suggestion: {{ result.suggestion }})
            </div>
          </div>
        </FormRow>
        <FormRow label="Average Profile Rating" clean>
          <template #additional>{{
            form.profile_reviews_count
              ? form.profile_reviews_count + " review(s) total"
              : "No reviews"
          }}</template>
          <ProfileRating
            :value="form.average_profile_rating"
            :count="form.profile_reviews_count"
            class="form__average-profile-rating"
          />
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">
      {{ btnText }}
      <template #actions>
        <div class="user-actions">
          <div v-if="form.id && !form.confirmed_at && !form.account_claimed_at">
            <div
              v-if="!form.claim_email_sent_at && !form.claim_link_created_at"
              class="user-actions__claim-actions"
            >
              <v-btn
                outlined
                color="primary"
                elevation="0"
                rounded
                large
                @click="sendClaimEmail"
              >
                Send Email to Claim Account
              </v-btn>
              <v-btn
                outlined
                color="primary"
                elevation="0"
                rounded
                large
                @click="createClaimLink"
              >
                Create Link to Claim Account
              </v-btn>
            </div>

            <div
              v-if="form.claim_email_sent_at || form.claim_link_created_at"
              class="user-actions__claim-link"
            >
              <v-btn icon @click.stop="copyClaimLinkToClipboard()">
                <v-icon>$copy_link</v-icon>
              </v-btn>
              <div
                class="user-actions__claim-status"
                v-if="form.claim_email_sent_at"
              >
                Claim Email sent at
                {{
                  form.claim_email_sent_at
                    | moment(
                      "timezone",
                      currentUser.active_project.timezone,
                      "MM/DD/YY h:mm A zz"
                    )
                }}
              </div>
              <div
                class="user-actions__claim-status"
                v-if="!form.claim_email_sent_at && form.claim_link_created_at"
              >
                Claim Account link created at
                {{
                  form.claim_link_created_at
                    | moment(
                      "timezone",
                      currentUser.active_project.timezone,
                      "MM/DD/YY h:mm A zz"
                    )
                }}
              </div>
            </div>
          </div>
          <div
            class="user-actions__claim-status"
            v-if="form.id && form.account_claimed_at"
          >
            Account claimed at
            {{
              form.account_claimed_at
                | moment(
                  "timezone",
                  currentUser.active_project.timezone,
                  "MM/DD/YY h:mm A zz"
                )
            }}
          </div>
          <div v-if="form.id && !form.confirmed_at">
            <v-btn
              outlined
              color="primary"
              elevation="0"
              rounded
              large
              @click="confirmUser"
            >
              Confirm
            </v-btn>
          </div>
        </div>
      </template>
    </SubmitFooter>
    <v-dialog max-width="300" v-model="showAddField">
      <v-card>
        <v-card-text>
          <br />
          <div class="title">Add new field</div>
          <br />
          <TextField label="Field Name" v-model="newFieldName" />
          <br />
          <TextField label="Field Value" v-model="newFieldValue" />
          <br />
          <v-btn block color="primary" @click="addNewField">Add field</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import mixin from "../../_extends/form.extends.js";
import { copyToClipboard } from "@/lib";
import { v4 as uuidv4 } from "uuid";

export default {
  extends: mixin,
  data() {
    return {
      showAddField: false,
      newFieldName: "",
      newFieldValue: "",
      registrationForm: null,
      passwordKey: 1,
      accountTypes: [
        {
          value: "individual",
          name: "Sole Proprietor (individual contractor)",
        },
        {
          value: "business",
          name: "Business (LLC, Partnership, S Corp, etc.)",
        },
      ],
    };
  },
  async mounted() {
    if (this.form.extra && this.form.registration_form_id) {
      const { data } = await this.$api.RegistrationForm.get({
        id: this.form.registration_form_id,
      });
      this.registrationForm = data;
    }
  },
  methods: {
    addNewField() {
      this.form.extra[this.newFieldName] = this.newFieldValue;
      this.form = { ...this.form };
      this.newFieldName = "";
      this.newFieldValue = "";
      this.showAddField = false;
    },
    async deleteField(field) {
      const confirmed = await this.$confirm({ title: "Are you sure?" });
      if (!confirmed) return;
      delete this.form.extra[field];
      this.form = { ...this.form };
    },
    async confirmUser() {
      const confirmed = await this.$confirm({
        title: "Are you sure?",
        description: `This participant hasn't confirmed their email. Are you sure you want to confirm their account?`,
      });
      if (!confirmed) return;
      await this.$api.User.confirm({ id: this.form.id });
      this.$parent.setForm();
    },
    async sendClaimEmail() {
      const { data } = await this.$api.User.sendClaimEmail({
        id: this.form.id,
      });
      if (data.msg) this.$alert(data.msg);
      this.$parent.setForm();
    },
    async createClaimLink() {
      const { data } = await this.$api.User.createClaimLink({
        id: this.form.id,
      });
      if (data.msg) this.$alert(data.msg);
      this.$parent.setForm();
    },
    copyClaimLinkToClipboard() {
      copyToClipboard(this.form.claim_account_link);
      this.$alert("Claim account link copied to clipboard");
    },
    createSecurePassword() {
      const pw = uuidv4();
      this.form.password = pw;
      this.form.password_confirmation = pw;
      this.passwordKey = this.passwordKey + 1;
      this.$alert("Secure password created");
    },
    showExtra() {
      return this.form.extra && !this.form.registration_form_id;
    },
    showRegistration() {
      return (
        this.form.extra &&
        this.form.registration_form_id &&
        this.registrationForm &&
        this.registrationForm.registration_form.length &&
        this.registrationFormFiltered.length
      );
    },
    publicChange(value, field) {
      this.form.public_fields[field] = value;
    },
    updateSchool(val) {
      this.form.school = val.title;
    },
  },
  computed: {
    registrationFormFiltered() {
      return this.registrationForm.registration_form.filter((field) => {
        if (!field.short_name) return true;
        return ![
          "first_name",
          "last_name",
          "password",
          "password_confirmation",
          "email",
          "phone",
          "zip",
          "agree_to_terms",
          "sign_me_up",
        ].includes(field.short_name.trim().toLowerCase());
      });
    },
  },
};
</script>

<style scoped lang="scss">
.extra-field {
  display: flex;
  align-items: center;
}

.extra-value {
  margin-right: 15px;
}

.extra-value-MediaField {
  flex-grow: 1;
}

.registration-note {
  font-weight: bold;
  font-size: 14px;
  line-height: 1.2;
  color: var(--v-text-base);
}

.email-validation-results__email {
  font-weight: bold;
}

.form {
  &-column-header__public {
    text-align: center;
    min-width: 3em;
  }

  &__website {
    > div:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }

  &__average-profile-rating {
    margin-top: 15px;
  }

  &__secure-pw-link {
    display: inline-block;
    color: var(--v-text-base);
    margin-top: 0.5em;
  }
}

.primary-specialty {
  display: flex;
  align-items: center;

  &__select {
    max-width: 250px;
    margin-right: 10px;
  }
}

.user-actions {
  display: flex;
  align-items: center;
  &__claim-actions {
    display: flex;
    flex-direction: column;
    > .v-btn:not(:last-child) {
      margin-bottom: 1em;
    }
  }
  &__claim-status {
    font-size: 14px;
    margin-right: 1em;
  }
  &__claim-link {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

.deprecated-clearable-field {
  color: gray;
  font-weight: bold;
  font-size: 0.8rem;
  margin-top: 10px;
}
</style>
