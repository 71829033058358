<template>
  <div>
    <ProfileForm ref="form" v-model="form" />
    <div class="onboarding__form-footer">
      <div></div>
      <OnboardingNextBtn @submit="nextStep" />
    </div>
  </div>
</template>

<script>
import mixin from "../_extends/form.extends.js";
import ProfileForm from "../settings/profile.page";

export default {
  extends: mixin,
  components: { ProfileForm },
  methods: {
    nextStep() {
      const { form } = this.$refs.form.$refs;
      if (form.validate()) {
        this.$emit("nextStep");
      } else {
        this.scrollToInvalidInput(form.inputs);
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
