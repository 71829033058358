<template>
  <TagSelector
    :multiple="multiple"
    :items="badges"
    :value="value"
    @input="$emit('input', $event)"
    itemText="title"
    itemValue="id"
    :label="label"
  />
</template>

<script>
export default {
  props: {
    value: [String, Number, Array],
    multiple: Boolean,
    label: String,
  },
  data() {
    return {
      badges: [],
    };
  },
  created() {
    this.setBadges();
  },
  methods: {
    async setBadges() {
      const { data } = await this.$api.Badge.lite();
      this.badges = data;
    },
  },
};
</script>

<style></style>
