<template>
  <v-btn
    rounded
    outlined
    class="elevation-0 next-btn"
    large
    @click="$emit('submit')"
  >
    <slot>
      <v-icon size="20">$arrow_left</v-icon>
      Back
    </slot>
  </v-btn>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.next-btn {
  .v-icon {
    margin-left: 5px;
    position: relative;
    top: 1px;
  }
}
</style>
