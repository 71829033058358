<template>
  <div>
    <div class="page-title">New Image Asset</div>
    <Form v-model="form" :submit="create" btn-text="Add Image Asset" />
  </div>
</template>

<script>
import Form from "./_form.image-assets.page";
export default {
  components: { Form },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async setForm() {
      const { data } = await this.$api.ImageAsset.new();
      this.form = data;
    },
    async create() {
      await this.$api.ImageAsset.create(this.form);
      this.$router.push("/dev-tools/image-library");
    },
  },
};
</script>
