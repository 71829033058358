var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0 data-table",attrs:{"headers":_vm.headers,"items":_vm.list,"item-key":"id","show-expand":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return _vm._l((items),function(item){return _c('tbody',{key:item.id},[_c('tr',{staticClass:"data-table__row",on:{"click":function($event){return _vm.$router.push('/users/participants/' + item.id)}}},[_c('td'),_c('td',[_vm._v(" "+_vm._s(item.email)+" ")]),_c('td',{staticClass:"nowrap td-name"},[_c('div',[_c('div',[_vm._v(_vm._s(item.full_name))]),(
                  item.account_type == 'business' && item.doing_business_as
                )?_c('div',[_vm._v(" ("+_vm._s(item.doing_business_as)+") ")]):_vm._e()]),_c('v-btn',{staticStyle:{"margin-left":"15px"},attrs:{"outlined":"","color":"primary","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.loginTo(item)}}},[_vm._v("Login")])],1),_c('td',[_vm._v(_vm._s(item.average_profile_rating))]),_c('td',[_c('v-tooltip',{attrs:{"open-delay":"500","top":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"participant-badges"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.badges .filter(function (it, i, ar) { return ar.indexOf(it) === i; }) .join(", "))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.badges .filter(function (it, i, ar) { return ar.indexOf(it) === i; }) .join(", ")))])])],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(item.verified_at, "timezone", _vm.currentUser.active_project.timezone, "MM/DD/YY h:mm A zz" ))+" ")])])])})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }