<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Badge Form Info">
        <FormRow required label="Type">
          <Select
            style="max-width: 270px"
            :items="datasources.badge_types"
            placeholder="Type"
            v-model="form.badge_type"
          ></Select>
        </FormRow>
        <FormRow required label="Title">
          <TextField placeholder="Title" v-model="form.title"></TextField>
        </FormRow>
        <FormRow label="Tech Name">
          <TextField placeholder="Tech Name" v-model="form.slug"></TextField>
        </FormRow>
        <FormRow label="Description">
          <TextArea
            placeholder="Description"
            v-model="form.description"
          ></TextArea>
        </FormRow>

        <FormRow>
          <template #label>"Badge" Thumbnail<br />(320x320)</template>
          <Uploader :ratio="320 / 320" v-model="form.preview" />
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../_extends/form.extends.js";

export default {
  extends: mixin,
};
</script>

<style></style>
