<template>
  <EnvSelectorDialogList
    title="Releases"
    placeholder="No Available Releases"
    :items="items"
    class="env-selector-dialog__releases"
  >
    <div class="env-selector-dialog__new-release">
      <v-text-field
        label="Release Name"
        dense
        hide-details
        outlined
        class="env-selector-dialog__new-release-name"
        v-model="releaseName"
      ></v-text-field>
      <v-btn
        :disabled="createReleasesInProgress"
        @click="createRelease"
        color="primary"
        >Create Release</v-btn
      >
    </div>

    <template #item="{ item }">
      <div class="env-selector-dialog-list__item-content">
        <div class="env-selector-dialog-list__item-title">
          {{ item.title }}
        </div>
        <div class="env-selector-dialog-list__item-user">
          {{ item.admin_name }}
        </div>
      </div>
      <v-btn
        @click="
          scheduleDeployModal = true;
          scheduleDeployModalForm = { ...item };
        "
        class="elevation-0"
        color="primary"
        x-small
        :disabled1="deployedIds.includes(item.id)"
        >Deploy</v-btn
      >
    </template>

    <v-dialog max-width="310" v-model="scheduleDeployModal">
      <v-card class="env-selector-dialog-list__item-deploy-at">
        <div class="page-title">Initiate Deploy</div>
        <DateTimePicker
          placeholder="Deploy at"
          :rounded="false"
          v-model="scheduleDeployModalForm.schedule_deploy_at"
          style="margin-bottom: 10px"
        ></DateTimePicker>
        <v-select
          label="strategy"
          v-model="scheduleDeployModalForm.strategy"
          :items="strategies"
          item-value="value"
          item-text="title"
        ></v-select>
        <br />
        <v-btn
          :disabled="deployInProgress"
          color="primary"
          block
          @click="deploy(scheduleDeployModalForm)"
        >
          <span v-if="scheduleDeployModalForm.schedule_deploy_at"
            >Schedule Deploy at
            <br />
            {{
              scheduleDeployModalForm.schedule_deploy_at
                | moment(
                  "timezone",
                  currentUser.active_project.timezone,
                  "MM/DD/YY h:mm A zz"
                )
            }}</span
          >
          <span v-else>Deploy Now</span>
        </v-btn>
      </v-card>
    </v-dialog>
  </EnvSelectorDialogList>
</template>

<script>
import EnvSelectorDialogList from "./EnvSelectorDialogList";

export default {
  components: {
    EnvSelectorDialogList,
  },
  props: {
    items: Array,
    deployedIds: Array,
  },
  data() {
    return {
      releaseName: "",
      createReleasesInProgress: false,
      scheduleDeployModal: false,
      scheduleDeployModalForm: {},
      deployInProgress: false,
    };
  },
  computed: {
    strategies() {
      let result = [];

      if (this.scheduleDeployModalForm.has_staging_data) {
        result = [
          ...result,
          {
            value: "staging_to_production",
            title: "Staging Release => Production Website",
          },
          {
            value: "staging_to_staging",
            title: "Staging Release => Staging Website",
          },
        ];
      }

      if (this.scheduleDeployModalForm.has_production_data) {
        result = [
          ...result,
          {
            value: "production_to_staging",
            title: "Production Release => Staging Website",
          },
          {
            value: "production_to_production",
            title: "Production Release => Production Website",
          },
        ];
      }
      return result;
    },
  },
  methods: {
    async createRelease() {
      this.createReleasesInProgress = true;
      try {
        await this.$api.Release.create({ title: this.releaseName });
        this.$emit("setReleases");
        this.releaseName = "";
      } finally {
        this.createReleasesInProgress = false;
      }
    },
    async deploy(form) {
      if (!form.strategy) {
        return this.$alert("Please, select a strategy for deploy", "error");
      }
      this.deployInProgress = true;
      try {
        const { data } = await this.$api.Deployment.create({
          release_id: form.id,
          schedule_deploy_at: form.schedule_deploy_at,
          strategy: form.strategy,
        });
        if (!form.schedule_deploy_at) {
          this.$emit("watchDeployment", data.id);
        }
        this.$emit("pushDeployed", form.id);
        this.$emit("setDeployments");
        this.scheduleDeployModal = false;
        this.scheduleDeployModalForm = {};
      } finally {
        this.deployInProgress = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.page-title {
  font-size: 20px;
}

.env-selector-dialog {
  &__releases {
    ::v-deep {
      .env-selector-dialog-list__empty {
        top: 71px;
      }
      .env-selector-dialog-list__items {
        padding-top: 71px;
      }
    }
  }
  &__new-release {
    padding: 15px;
    background: #f7f7f7;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 1;
    justify-content: space-between;
    border-bottom: 1px solid #dedede;

    &-name {
      max-width: 190px;

      &::v-deep fieldset {
        background: white;
      }
    }
  }

  &-list__item-deploy-at {
    padding: 20px;
    min-width: 250px;
  }
}
</style>
