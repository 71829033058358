const store = {
  namespaced: true,
  state: {
    loaded: false,
  },
  mutations: {
    setLoaded(state, val) {
      state.loaded = val;
    },
  },
  actions: {
    async init({ dispatch, commit }) {
      return new Promise((resolve) => {
        Promise.all([
          dispatch("user/fetch", null, { root: true }),
          dispatch("datasources/fetch", null, { root: true }),
        ]).finally(() => {
          commit("setLoaded", true);
          resolve();
        });
      });
    },
  },
};

export default store;
