<template>
  <div class="badges-selector">
    <div class="badges-list">
      <Badge v-for="badge in filteredBadges" :key="badge.id" :badge="badge">
        <div class="badge-count" v-if="countable">
          <v-btn
            @click="reduce(badge.id)"
            color="primary"
            fab
            x-small
            class="elevation-0"
          >
            <v-icon>$minus</v-icon>
          </v-btn>
          <div class="badge-count__value">{{ badge.count }}</div>
          <v-btn
            @click="add(badge.id)"
            color="primary"
            fab
            x-small
            class="elevation-0"
          >
            <v-icon>$plus</v-icon>
          </v-btn>
        </div>
      </Badge>
    </div>
    <v-btn
      @click="openAddBadgePopup = true"
      block
      outlined
      x-large
      color="primary"
      >Add Badge</v-btn
    >

    <AddBadgeModal
      :badges="badges"
      v-model="openAddBadgePopup"
      @add="add($event)"
    />
  </div>
  <!-- <TagSelector
    :multiple="multiple"
    :items="badges"
    :value="value"
    @input="$emit('input', $event)"
  /> -->
</template>

<script>
import Badge from "./BadgePickSelector/Badge";
import AddBadgeModal from "./BadgePickSelector/AddBadgeModal";
export default {
  components: { Badge, AddBadgeModal },
  props: {
    value: [String, Number, Array],
    multiple: Boolean,
    countable: {
      type: Boolean,
      default: true,
    },
    condition: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      badges: [],
      openAddBadgePopup: false,
    };
  },
  created() {
    this.setBadges();
  },
  computed: {
    filteredBadges() {
      let filtered = this.badges.filter((t) => this.value.includes(t.id));
      for (let item of filtered) {
        item.count = this.value.filter((t) => t == item.id).length;
      }
      return filtered;
    },
  },
  methods: {
    add(id) {
      const value = [...this.value, id];
      this.openAddBadgePopup = false;
      this.$emit("input", value);
    },
    reduce(id) {
      const value = [...this.value];
      value.find((o, i) => {
        if (o === id) {
          value.splice(i, 1);
          return true;
        }
        return false;
      });

      this.$emit("input", value);
    },
    async setBadges() {
      const { data } = await this.$api.Badge.get();
      this.badges = data.filter((badge) => this.condition(badge));
    },
  },
};
</script>

<style scoped lang="scss"></style>
