<template>
  <EnvSelectorDialogList
    title="Deployments"
    placeholder="No Deployments"
    :items="items"
  >
    <template #item="{ item }">
      <div class="env-selector-dialog-list__item-content">
        <div class="env-selector-dialog-list__item-title">
          {{ item.title }}
        </div>
        <div class="env-selector-dialog-list__item-user">
          {{ item.admin_name }}
        </div>
        <div class="env-selector-dialog-list__item-date">
          <span v-if="item.state == 'deployed'" class="deployed"
            >Deployed {{ item.strategy }} at
            {{
              item.deployed_at
                | moment(
                  "timezone",
                  currentUser.active_project.timezone,
                  "MM/DD/YY h:mm A"
                )
            }}</span
          >
          <span v-else-if="item.state == 'scheduled'" class="scheduled">
            <v-icon color="blue" size="16">$alarm</v-icon>
            Scheduled to deploy {{ item.strategy }} at
            <b>{{
              item.schedule_deploy_at
                | moment(
                  "timezone",
                  currentUser.active_project.timezone,
                  "MM/DD/YY h:mm A"
                )
            }}</b></span
          >
          <i v-else-if="item.state == 'inited'" class="initializing"
            >Initializing...</i
          >

          <i v-else-if="item.state == 'cancel'" class="cancel">Canceled</i>
          <i v-else-if="item.state == 'deploying'" class="deploying"
            >Deploying {{ item.strategy }}...</i
          >
        </div>
      </div>
      <v-btn
        @click="cancel(item)"
        class="elevation-0"
        color="primary"
        x-small
        outlined
        v-if="item.state == 'scheduled'"
      >
        Cancel
      </v-btn>
    </template>
  </EnvSelectorDialogList>
</template>

<script>
import EnvSelectorDialogList from "./EnvSelectorDialogList";

export default {
  components: { EnvSelectorDialogList },
  props: {
    items: Array,
  },
  methods: {
    async cancel(item) {
      await this.$api.Deployment.cancel(item);
      this.$emit("setDeployments");
    },
  },
};
</script>

<style scoped lang="scss">
.deployed {
  color: green;
}

.initializing,
.deploying {
  color: gray;
  font-weight: bold;
}

.scheduled {
  color: #2196f3;
  display: flex;
  align-items: center;

  .v-icon {
    margin-right: 3px;
  }

  b {
    margin-left: 3px;
  }
}

.cancel {
  color: red;
  b {
    margin-left: 3px;
  }
}
</style>
