<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="list"
      item-key="id"
      show-expand
      class="elevation-0 data-table"
    >
      <template v-slot:body="{ items }">
        <tbody v-for="item in items" :key="item.id">
          <tr class="data-table__row">
            <td />

            <td>
              <!-- <a :href="`mailto:${item.email}`">{{ item.email }}</a> -->
              {{ item.email }}
            </td>
            <td class="nowrap">
              <span v-if="item.status == 'Accepted'">
                <router-link :to="`/users/participants/${item.id}/edit`">
                  {{ item.full_name }}
                </router-link>
              </span>
              <span v-else>{{ item.full_name }}</span>
            </td>
            <td :class="'status--' + item.status">
              <span>
                {{ item.status }}
              </span>
            </td>
            <!-- <td class="nowrap"> -->
            <!-- {{ item.roles.map(t => t.name).join('; ') }} -->
            <!-- </td> -->
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Email",
          align: "start",
          value: "email",
        },
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        {
          text: "Status",
          align: "start",
          value: "status",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.participant-badges {
  overflow: hidden;
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    // overflow: auto;
    // white-space: pre-line;
  }
}

.status--Accepted span {
  color: white;
  background: green;
  padding: 3px 5px;
}

.status--Pending span {
  color: white;
  padding: 3px 5px;
  background: gray;
}
</style>
