<template>
  <div>
    <Banner theme="purple">
      <template v-slot:title> Chat Boxes </template>
      <template v-slot:description>
        Chat Boxes allow you to create spaces for guests to come together and
        communicate with simple text messages. Each chat box is fully moderated
        by your team. Create as many as you’d like and place them on specific
        pages within the site.
      </template>
      <template v-slot:image>
        <img src="@/assets/banners/chatbox.svg" alt="Chat Boxes" >
      </template>
    </Banner>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="New Chatbox"
      btn-to="/chats/new"
      :sort-by.sync="sortBy"
      search-placeholder="Search Chatbox"
      :search-text.sync="searchText"
    />

    <ListPanels :loaded="!!list">
      <div v-if="computedList && computedList.length" style="width: 100%">
        <ListPanel
          v-for="chat in computedList"
          :key="chat.id"
          :action-path="`/chats/${chat.id}`"
          hide-duplicate
          :title="chat.title"
          @destroy="destroyChat(chat)"
        >
          <ChatDataTable
            v-if="chat.messages.length"
            show-more
            :entity="chat"
            :list="chat.messages"
            @destroy="deleteMessage({ id: $event.id, chatId: chat.id })"
          />
          <div v-else class="no-entries">No Messages</div>
        </ListPanel>
      </div>
      <div v-else class="no-entries">No Chats</div>
    </ListPanels>
  </div>
</template>

<script>
import IndexPage from './index-page';
import ChatDataTable from '@/components/dataview/chat.datatable';

export default {
  name: 'ChatBoxPage',
  components: {
    ChatDataTable,
  },
  extends: IndexPage,
  methods: {
    async fetchList() {
      const { data } = await this.$api.Chat.get({
        sort_by: this.sortBy,
      });
      this.list = data;
    },
    async destroyChat(chat) {
      const confimed = await this.$confirm({
        title: 'Are you sure?',
      });
      if (!confimed) return;

      await this.$api.Chat.destroy({ id: chat.id });
      this.list = this.list.filter((t) => t.id != chat.id);
    },
    async deleteMessage({ id, chatId }) {
      const confimed = await this.$confirm({
        title: 'Are you sure?',
      });
      if (!confimed) return;
      await this.$api.Chat.deleteMessage({ message_id: id });
      this.list.forEach((chat) => {
        if (chat.id == chatId) {
          chat.messages = chat.messages.filter((t) => t.id != id);
        }
      });
      this.list = [...this.list];
    },
  },
};
</script>

<style scoped lang="scss"></style>
