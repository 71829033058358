<template>
  <div>
    <Banner theme="orange">
      <template v-slot:title> Creators </template>
      <template v-slot:description>
        Creators are all your participants on the live site.
      </template>
      <template v-slot:image>
        <img src="@/assets/banners/users/participants.svg" alt="Creators" />
      </template>
    </Banner>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="New User"
      btn-to="/users/participants/new"
      :sort-by.sync="sortBy"
      :search-text.sync="searchText"
      search-placeholder="Search Creators"
    />

    <div class="page-subtitle">Roles</div>
    <div v-if="rolesList">
      <Stories
        :click-path="(item) => `/users/participants/roles/${item.id}/edit`"
        create-path="/users/participants/roles/new"
        :list="rolesList"
        add-btn-text="New Role"
      />
    </div>
    <Spinner v-else relative />

    <div class="page-subtitle">
      <span> Creators </span>
      <span>
        <BadgeSelector label="Badges" multiple v-model="badges" />
      </span>
      <form
        method="post"
        action="/api/v1/admin_side/users/download.csv"
        target="_blank"
      >
        <input
          type="hidden"
          name="token"
          :value="$store.getters['auth/token']"
        />
        <v-btn type="submit" small outlined color="primary">
          Download CSV
        </v-btn>
      </form>
    </div>
    <div v-if="list">
      <div v-if="filteredList.length">
        <ParticipantsDataTable :list="filteredList" @destroy="destroy" />
        <!-- <Pagination :perPage.sync="perPage" :page.sync="page" /> -->
      </div>
      <div v-else class="no-entries">No Creators</div>
    </div>
    <Spinner v-else relative />
  </div>
</template>

<script>
import ParticipantsDataTable from "@/components/dataview/participants.datatable";
import IndexPage from "../index-page";
import _ from "lodash";

export default {
  name: "ParticipantsPage",
  components: { ParticipantsDataTable },
  extends: IndexPage,
  data() {
    return {
      rolesList: null,
      badges: [],
      searchFields: ["full_name", "email", "doing_business_as"],
    };
  },
  created() {
    this.fetchRoles();
  },
  methods: {
    async fetchRoles() {
      const { data } = await this.$api.Role.get();
      this.rolesList = data;
    },
    async fetchList() {
      const { data } = await this.$api.User.get({
        sort_by: this.sortBy,
      });
      this.list = data;
    },
    async destroy({ id }) {
      const confirmed = await this.$confirm({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.User.destroy({ id });
      this.list = this.list.filter((t) => t.id != id);
    },
  },
  computed: {
    filteredList() {
      if (!this.computedList.length) return this.computedList;
      return this.computedList.filter(
        (t) =>
          _.intersection(t.badge_ids, this.badges).length == this.badges.length
      );
    },
  },
};
</script>

<style scoped lang="scss">
.page-subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
