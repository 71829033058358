<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="list"
      item-key="id"
      show-expand
      class="elevation-0 data-table"
    >
      <template v-slot:body="{ items }">
        <tbody v-for="(item, index) in items" :key="item.id">
          <tr class="data-table__row">
            <td>
              <!-- <v-icon class="move">$menu</v-icon> -->
            </td>

            <td>
              <router-link
                :to="
                  `/widgets/content-sections/${entity.id}/blocks/${item.id}/edit`
                "
              >
                {{ item.title }}
              </router-link>
            </td>
            <td>
              {{ item.subtitle }}
            </td>
            <td class="text-center nowrap">
              <Actions
                :path="
                  `/widgets/content-sections/${entity.id}/blocks/${item.id}`
                "
                @destroy="$emit('destroy', item)"
                @duplicate="$emit('duplicate', item)"
              >
                <template #prepend>
                  <div style="display: contents;" v-if="entity.sort_by == 'position'">
                    <v-btn icon @click="$emit('moveUp', index)" :disabled="index == 0">
                      <v-icon>$arrow_up</v-icon>
                    </v-btn>
                    <v-btn icon @click="$emit('moveDown', index)" :disabled="index == items.length - 1">
                      <v-icon>$arrow_down</v-icon>
                    </v-btn>
                  </div>
                </template>
              </Actions>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    entity: Object,
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    headers() {
      return [
        {
          text: 'Content Block Title',
          align: 'start',
          value: 'title',
        },
        {
          text: 'Subtitle',
          align: 'start',
          value: 'subtitle',
        },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
        },
      ];
    },
  }
};
</script>

<style></style>
