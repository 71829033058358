<template>
  <FileField
    :placeholder="label"
    :value="value"
    @input="$emit('input', $event)"
    :rules="rules"
  />
</template>

<script>
export default {
  props: {
    label: String,
    value: [String, File, Object],
    rules: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style></style>
