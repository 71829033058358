<template>
  <div>
    <Banner theme="orange">
      <template v-slot:title>
        Content Sections
      </template>
      <template v-slot:description>
        Content Sections are rows of thumbnails (content blocks) that can be
        used to house pre-recorded video content, downloads, popups w/ imagery &
        copy, or external links. These can exist on any page but are not a part
        of the timeline.
      </template>
      <template v-slot:image>
        <img
          src="@/assets/banners/widgets/content-blocks.svg"
          alt="Content Blocks"
        />
      </template>
    </Banner>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="New Content Section"
      btn-to="/widgets/content-sections/new"
      :view.sync="view"
      :sort-by.sync="sortBy"
      search-placeholder="Search Content Section"
      :search-text.sync="searchText"
    />
    <ListPanels :loaded="!!list">
      <div v-if="computedList && computedList.length">
        <ListPanel
          v-for="section in computedList"
          :key="section.id"
          :action-path="`/widgets/content-sections/${section.id}`"
          :title="section.title"
          :label="section.label"
          @destroy="destroySection(section)"
          @duplicate="duplicateSection(section)"
        >
          <template #addAction>
            <v-btn
              :to="`/widgets/content-sections/${section.id}/blocks/new`"
              color="primary"
              elevation="0"
              rounded
              class="content-blocks__new-block-btn"
            >
              <slot name="btn">
                <v-icon>$plus</v-icon>
                <span class="content-blocks__new-block-btn-text">
                  Add Content Block
                </span>
              </slot>
            </v-btn>
          </template>

          <div v-if="section.blocks.length">
            <ContentSectionsDatagrid
              v-if="view == 'grid'"
              :list="section.blocks"
              :entity="section"
              @destroy="destroyBlock($event, section)"
              @duplicate="duplicateBlock($event, section)"
              @moveUp="moveUp($event, section)"
              @moveDown="moveDown($event, section)"
            />
            <ContentSectionsDatatable
              v-else-if="view == 'table'"
              :list="section.blocks"
              :entity="section"
              @destroy="destroyBlock($event, section)"
              @duplicate="duplicateBlock($event, section)"
              @moveUp="moveUp($event, section)"
              @moveDown="moveDown($event, section)"
            />
          </div>
          <div v-else class="no-entries">
            No Content Blocks
          </div>
        </ListPanel>
      </div>
      <div v-else class="no-entries">
        No Content Sections
      </div>
    </ListPanels>
  </div>
</template>

<script>
import _ from 'lodash';
import IndexPage from '../index-page';
import ContentSectionsDatagrid from '@/components/dataview/content-sections.datagrid';
import ContentSectionsDatatable from '@/components/dataview/content-sections.datatable';

export default {
  name: 'WidgetsContentSectionsPage',
  components: { ContentSectionsDatagrid, ContentSectionsDatatable },
  extends: IndexPage,
  methods: {
    async fetchList() {
      const { data } = await this.$api.ContentSection.get({
        sort_by: this.sortBy,
      });
      this.list = data;
    },
    async destroySection({ id }) {
      const confirmed = await this.$confirm({ title: 'Are you sure?' });
      if (!confirmed) return;
      await this.$api.ContentSection.destroy({ id });
      this.list = this.list.filter(t => t.id != id);
    },
    async destroyBlock(block, section) {
      const confirmed = await this.$confirm({ title: 'Are you sure?' });
      if (!confirmed) return;
      await this.$api.ContentBlock.destroy({ id: block.id });
      const _section = this.list.find(t => t.id == section.id);
      _section.blocks = section.blocks.filter(t => t.id != block.id);
      this.list = [...this.list];
    },
    async duplicateSection(section) {
      await this.$api.ContentSection.duplicate(section);
      this.fetchList();
    },
    async duplicateBlock(block, section) {
      await this.$api.ContentBlock.duplicate({
        block_id: block.id,
        section_id: section.id,
      });
      this.fetchList();
    },
    moveUp(idx, section) {
      if (idx < 1) return;
      const block = { ...section.blocks[idx] };
      section.blocks.splice(idx, 1);
      section.blocks.splice(idx - 1, 0, block);
      this.updateBlockPositions(section);
    },
    moveDown(idx, section) {
      if (idx >= section.blocks.length - 1) return;
      const block = { ...section.blocks[idx] };
      section.blocks.splice(idx, 1);
      section.blocks.splice(idx + 1, 0, block);
      this.updateBlockPositions(section);
    },
    updateBlockPositions: _.debounce(async function(section) {
      if(section.sort_by == "position") {
        section.blocks.forEach((block, index) => {
          block.position = index;
        });
        const sectionParams = _.pick(section, ['id', 'blocks']);
        await this.$api.ContentSection.updateBlocks(sectionParams);
      }
    }, 300),
  },
};
</script>

<style scoped lang="scss">
.content-blocks {
  &__new-block-btn {
  }
}

@media screen and (max-width: 960px) {
  .content-blocks {
    &__new-block-btn {
      padding: 0 !important;
      min-width: 36px !important;
      &-text {
        display: none;
      }
    }
  }
}
</style>
