<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Schedule Info">
        <FormRow
          label="Title"
          required
          tooltip="This is the title for your schedule as it will be displayed on the front-end of your site."
        >
          <TextField placeholder="Title" v-model="form.title"></TextField>
        </FormRow>
        <FormRow label="Description">
          <template #tooltip
            >Add any finer detail to be displayed on the front-end of your site
            under the "Title."</template
          >
          <TextArea
            placeholder="Description"
            v-model="form.description"
          ></TextArea>
        </FormRow>
      </FormPanel>

      <FormPanel
        label="Schedule Details"
        tooltip="Select the page or pages on which you'd like this Schedule to appear."
      >
        <FormRow label="Display Page(s)">
          <PagesSelector multiple v-model="form.page_ids"></PagesSelector>
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../../_extends/form.extends.js";

export default {
  extends: mixin,
};
</script>

<style></style>
