<template>
  <div>
    <div v-if="['super_admin', 'admin'].includes(currentUser.active_team_role)">
      <div class="page-title">Edit Team</div>
      <Form
        v-if="form"
        v-model="form"
        :submit="update"
        btn-text="Update Team"
      />
      <Spinner v-else />
    </div>
    <div v-else>
      <div class="page-title">Teams</div>
      <div>
        You don't have access to the team settings. Please, contact your admin
        to get more details.
      </div>
    </div>
  </div>
</template>

<script>
import Form from "./_form.teams.page";
export default {
  components: { Form },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async update({ onUploadProgress }) {
      await this.$api.Team.update(this.form, { onUploadProgress });
      this.$router.push("/projects");
    },
    async setForm() {
      const { data } = await this.$api.Team.get({
        id: this.$route.params.id,
      });
      this.form = data;
    },
  },
};
</script>

<style></style>
