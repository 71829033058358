<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Image Asset Info">
        <FormRow label="Title" required>
          <TextField v-model="form.title" placeholder="Title" />
        </FormRow>

        <FormRow label="File" required tooltip="This is the uploaded file.">
          <Uploader v-model="form.asset" position="top" :ratio="1200 / 600" />
        </FormRow>
        <FormRow
          v-if="(form.asset || {}).url"
          clean
          tooltip="Click this button to copy a link to the file to your clipboard."
        >
          <template #label>
            <v-btn @click.stop="copyAssetUrlToClipboard">
              <v-icon>$file</v-icon>
              Copy to Clipboard
            </v-btn>
          </template>
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">
      {{ btnText }}
    </SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../../_extends/form.extends.js";
import { copyToClipboard } from "@/lib";

export default {
  extends: mixin,
  methods: {
    copyAssetUrlToClipboard() {
      copyToClipboard(this.form.asset.url);
      this.$alert("Link Copied to Clipboard");
    },
  },
};
</script>

<style scoped lang="scss"></style>
