<template>
  <div>
    <TextArea placeholder="Label" v-model="localValue.label" />
    <div class="form-builder__select-options">
      <div
        class="form-builder__select-option"
        v-for="(option, idx) in localValue.options"
        :key="option.uuid"
      >
        <span style="margin-right: 10px">#{{ idx + 1 }}:</span>
        <TextField
          style="width: 100%"
          v-model="option.title"
          :placeholder="`Option #${idx + 1}`"
        />
        <Checkbox
          label="Right"
          v-model="option.right"
          v-if="currentUser.active_project.slug == 'gt2201'"
        />

        <div class="form-builder__select-actions">
          <v-btn icon @click="moveUp(idx)">
            <v-icon>$arrow_up</v-icon>
          </v-btn>
          <v-btn icon @click="moveDown(idx)">
            <v-icon>$arrow_down</v-icon>
          </v-btn>
          <v-btn icon @click="deleteOption(option.uuid)">
            <v-icon>$delete</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <v-btn @click="addOption" color="primary" small outlined rounded>
      <v-icon>$plus</v-icon>
      Add Option
    </v-btn>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import mixin from "./form-builder.mixin.js";

export default {
  extends: mixin,
  methods: {
    addOption() {
      if (!this.localValue.options) {
        this.localValue.options = [];
      }
      this.localValue = {
        ...this.localValue,
        options: [...(this.localValue.options || []), { uuid: uuidv4() }],
      };
    },
    deleteOption(uuid) {
      this.localValue = {
        ...this.localValue,
        options: this.localValue.options.filter((t) => t.uuid != uuid),
      };
    },
    moveUp(idx) {
      if (idx < 1) return;
      const options = [...(this.localValue.options || [])];
      const element = { ...options[idx] };

      options.splice(idx, 1);
      options.splice(idx - 1, 0, element);

      this.localValue = {
        ...this.localValue,
        options,
      };
    },
    moveDown(idx) {
      if (idx >= this.localValue.length - 1) return;
      const options = [...(this.localValue.options || [])];
      const element = { ...options[idx] };

      options.splice(idx, 1);
      options.splice(idx + 1, 0, element);

      this.localValue = {
        ...this.localValue,
        options,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.form-builder__select {
  &-actions {
    display: flex;
  }
  &-options {
    margin: 10px 0;
  }
  &-option {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}
</style>
