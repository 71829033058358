<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="School Info">
        <FormRow
          required
          label="Title"
          tooltip="This is the title of School."
        >
          <TextField placeholder="Title" v-model="form.title"></TextField>
        </FormRow>
        <FormRow
          label="Aliases"
          tooltip="Aliases are other names that the school might be known by, including acronyms. Setting common or popular aliases for a school will improve autocomplete search results by including, for example, Georgia Institute of Technology if a creator types “GAtech”."
        >
          <TextField placeholder="Aliases" v-model="form.aliases"></TextField>
        </FormRow>
        <FormRow
          label="Street Address"
          tooltip="Currently unused."
        >
          <TextField placeholder="Street Address" v-model="form.street_address"></TextField>
        </FormRow>
        <FormRow
          label="City"
          tooltip="The city where a school is located is also used in a creator's autocomplete search results, after title and aliases."
        >
          <TextField placeholder="State" v-model="form.city"></TextField>
        </FormRow>
        <FormRow
          label="State"
          tooltip="The US state where a school is located is also used in a creator's autocomplete search results, after title, aliases, and city."
        >
          <TextField placeholder="State" v-model="form.state"></TextField>
        </FormRow>
        <FormRow
          label="Postal Code"
          tooltip="Currently unused."
        >
          <TextField placeholder="Postal Code" v-model="form.zip"></TextField>
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../_extends/form.extends.js";

export default {
  extends: mixin,
};
</script>

<style></style>
