<template>
  <div>
    <div class="page-title">This page is in progress</div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
