<template>
  <div>
    <div class="page-title">Tickets</div>
    <div class="panel-box">
      <div v-if="support !== null">
        <SupportDataTable :entity="support" />
      </div>
      <Spinner v-else />
    </div>
  </div>
</template>

<script>
import SupportDataTable from "@/components/dataview/support.datatable";

export default {
  components: { SupportDataTable },
  data() {
    return {
      support: null,
    };
  },
  created() {
    this.setSupport();
  },
  methods: {
    async setSupport() {
      const { data } = await this.$api.SupportForm.get({
        id: this.$route.params.id,
      });

      this.support = data;
    },
  },
};
</script>

<style scoped lang="scss">
.no-tickets {
  font-size: 18px;
  text-align: center;
  font-weight: 600;
}
</style>
