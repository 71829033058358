<template>
  <div class="onboarding-page">
    <div class="page-title">Welcome aboard!</div>

    <v-stepper :value="step" v-if="hiddenTodos">
      <v-stepper-header
        class="elevation-0"
        style="border-bottom: 1px solid #e7e7e7"
      >
        <v-stepper-step :complete="step > 1" step="1">
          Personal Information
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content class="onboarding-content" step="1">
          <PersonalInformation v-model="form" @nextStep="submit" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-stepper :value="step" v-else>
      <v-stepper-header
        class="elevation-0"
        style="border-bottom: 1px solid #e7e7e7"
      >
        <v-stepper-step :complete="step > 1" step="1">
          Personal Information
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="step > 2" step="2">
          Team Setup
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3"> Site Creation </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content class="onboarding-content" step="1">
          <PersonalInformation v-model="form" @nextStep="nextStep" />
        </v-stepper-content>
        <v-stepper-content class="onboarding-content" step="2">
          <SetupTeam v-model="form" @backStep="backStep" @nextStep="nextStep" />
        </v-stepper-content>
        <v-stepper-content class="onboarding-content" step="3">
          <CreateProject
            :nextStepDisabled="nextStepDisabled"
            v-model="form"
            @backStep="backStep"
            @nextStep="submit"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import PersonalInformation from "./onboarding/personal-information.page";
import SetupTeam from "./onboarding/setup-team.page";
import CreateProject from "./onboarding/create-project.page";

export default {
  components: { SetupTeam, PersonalInformation, CreateProject },
  data() {
    return {
      step: 1,
      form: {},
      nextStepDisabled: false,
      stepRoutes: ["", "profile", "team", "project"],
    };
  },
  watch: {
    $route() {
      const stepName = this.$route.params.step;
      if (stepName != this.stepRoutes[this.step]) {
        this.step = this.stepRoutes.indexOf(stepName);
      }
    },
    step: {
      immediate: true,
      handler() {
        const stepName = this.stepRoutes[this.step];
        if (stepName != this.$route.params.step) {
          if (this.$route.path != `/onboarding/${stepName}`) {
            this.$router.push(`/onboarding/${stepName}`);
          }
        }
      },
    },
  },
  mounted() {
    this.form = { ...this.currentUser };
  },
  methods: {
    nextStep() {
      this.step += 1;
    },
    backStep() {
      this.step -= 1;
    },
    async submit() {
      this.nextStepDisabled = true;
      try {
        await this.$api.Profile.onboard(this.form);
        this.$router.push("/");
      } finally {
        this.nextStepDisabled = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.onboarding-page {
  max-width: 800px;
  margin: auto;

  &::v-deep {
    .text-field {
      width: 100%;
    }
  }
}

.onboarding-content {
  padding: 0;

  &::v-deep {
    .form-panels {
      border: none;
      margin: 0;
    }

    .onboarding {
      &__form {
        &-footer {
          padding: 20px 40px;
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .v-expansion-panel-header {
      border-width: 1px !important;
    }

    .v-expansion-panel-content {
      border-width: 1px !important;
    }
  }
}
</style>
