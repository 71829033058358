<template>
  <div>
    <Banner>
      <template v-slot:title> Reports </template>
      <template v-slot:description>
        This is the Global Reporting dashboard. It displays a variety of metrics related to the community.
      </template>
      <template v-slot:image>
        <img src="@/assets/banners/dashboard.svg" alt="Reports" />
      </template>
    </Banner>

    <perfect-scrollbar>
      <div class="reports__counter-groups" v-if="counters.length">
        <div
          v-for="(group, groupIdx) in counters"
          :key="groupIdx"
          class="reports__group"
        >
          <div class="page-title"> {{ group.title }} </div>
          <div class="reports__counters">
            <div
              v-for="(counter, idx) in group.items"
              :key="idx"
              class="reports__counter"
            >
              <div
                class="reports__counter-count"
                :style="{
                }"
              >
                <div
                  class="reports__counter-count__blur"
                  :style="{
                    background: counterGradients[idx % counterGradients.length],
                  }"
                />
                <div
                  class="reports__counter-count__bg"
                  :style="{
                    background: counterGradients[idx % counterGradients.length],
                  }"
                />
                <div class="reports__counter-count__value">
                  {{ counter.count ? counter.count.toLocaleString() : "0" }}
                </div>
              </div>
              <div v-html="counter.title" class="reports__counter-title"></div>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>

  </div>
</template>

<script>
export default {
  name: "ReportsPage",
  data() {
    return {
      counterGradients: [
        "linear-gradient(178.18deg, #FFE78F -13.56%, #E12D1B 91.17%)",
        "linear-gradient(178.18deg, #CABAE6 -13.56%, #E12D1B 91.17%)",
        "linear-gradient(178.18deg, #CCE8CE -13.56%, #E12D1B 91.17%)",
      ],
      counters: [],
    };
  },
  created() {
    const { redirect } = this.$route.query;
    if (redirect && redirect != "/") {
      return this.$router.push(redirect);
    }

    this.setCounters();
  },
  methods: {
    async setCounters() {
      const { data } = await this.$api.Reports.counters();
      this.counters = data;
    },
  },
};
</script>

<style scoped lang="scss">
.reports {
  &__counter-groups {
    margin: 30px 0 0;
  }
  &__counters {
    margin: 30px 0;
    display: flex;
    flex-wrap: wrap;
  }
  &__counters > &__counter:last-child {
    margin-right: 0;
  }
  &__counter {
    min-height: 165px;
    background: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    align-items: center;
    margin-right: 30px;
    margin-bottom: 30px;
    min-width: 330px;
    width: calc(33.33% - 30px);

    &-count {
      width: 100px;
      min-width: 100px;
      height: 100px;
      min-height: 100px;
      color: white;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 36px;
      line-height: 45px;
      border-radius: 50%;
      position: relative;
      margin: 0 40px;

      &__value {
        position: relative;
      }

      &__blur {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 50%;
        filter: blur(10px);
      }

      &__bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 50%;
      }
    }

    &-title {
      font-weight: 900;
      font-size: 20px;
      line-height: 24px;
      white-space: pre-wrap;
    }
  }
}
</style>
