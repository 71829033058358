<template>
  <div>
    <Banner theme="purple">
      <template v-slot:title> Timelines </template>
      <template v-slot:description>
        An Timelines is a list of significant events with dates for this opportunity.
      </template>
      <template v-slot:image>
        <img
          src="@/assets/banners/widgets/countdowns.svg"
          alt="Timelines"
        />
      </template>
    </Banner>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="New Timeline"
      btn-to="/widgets/timeline-lists/new"
      :sort-by.sync="sortBy"
      search-placeholder="Search Timelines"
      :search-text.sync="searchText"
    />

    <div v-if="list">
      <TimelineListsDataTable
        v-if="computedList.length"
        :list="computedList"
        @destroy="destroy"
        @duplicate="duplicate"
      />
      <div v-else class="no-entries">No Timelines</div>
    </div>
    <Spinner v-else relative />
  </div>
</template>

<script>
import IndexPage from '../index-page';
import TimelineListsDataTable from '@/components/dataview/timeline-lists.datatable';

export default {
  name: 'WidgetsTimelineListsPage',
  components: { TimelineListsDataTable },
  extends: IndexPage,
  methods: {
    async fetchList() {
      const { data } = await this.$api.TimelineList.get({
        sort_by: this.sortBy,
      });
      this.list = data;
    },
    async destroy(item) {
      const confirmed = await this.$confirm({ title: 'Are you sure?' });
      if (!confirmed) return;
      await this.$api.TimelineList.destroy({ id: item.id });
      this.list = this.list.filter((t) => t.id != item.id);
    },
    async duplicate(item) {
      await this.$api.TimelineList.duplicate({ id: item.id });
      this.fetchList();
    },
  },
};
</script>

<style scoped lang="scss"></style>
