<template>
  <div>
    <div class="page-title">Schedules</div>
    <Banner theme="purple">
      <template v-slot:title> Schedules </template>
      <template v-slot:description>
        Schedules are a high-level visualization of the events you want your
        attendees to know about. They can be tied to “events” or function as
        more general guides.
      </template>
      <template v-slot:image>
        <img src="@/assets/banners/widgets/schedules.svg" alt="Schedules" >
      </template>
    </Banner>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="New Schedule"
      btn-to="/widgets/schedules/new"
      :sort-by.sync="sortBy"
      search-placeholder="Search Schedules"
      :search-text.sync="searchText"
    />
    <ListPanels :loaded="!!list">
      <div v-if="computedList && computedList.length">
        <ListPanel
          v-for="section in computedList"
          :key="section.id"
          :action-path="`/widgets/schedules/${section.id}`"
          :title="section.title"
          add-btn-text="Add item"
          :add-btn-link="`/widgets/schedules/${section.id}/items/new`"
          @destroy="destroySchedule(section)"
          @duplicate="duplicateSchedule(section)"
        >
          <div v-if="section.items.length">
            <SchedulesDataGrid :entity="section" />
          </div>
          <div v-else class="no-entries">No Schedule Items</div>
        </ListPanel>
      </div>
      <div v-else class="no-entries">No Schedules</div>
    </ListPanels>
  </div>
</template>

<script>
import IndexPage from '../index-page';
import SchedulesDataGrid from '@/components/dataview/schedules.datagrid';

export default {
  name: 'WidgetSchedulesPage',
  components: { SchedulesDataGrid },
  extends: IndexPage,
  methods: {
    async fetchList() {
      const { data } = await this.$api.Schedule.get({
        sort_by: this.sortBy,
      });
      this.list = data;
    },
    async destroySchedule(schedule) {
      const confirmed = await this.$confirm({ title: 'Are you sure?' });
      if (!confirmed) return;
      await this.$api.Schedule.destroy({ id: schedule.id });
      this.list = this.list.filter((t) => t.id != schedule.id);
    },
    async duplicateSchedule(schedule) {
      await this.$api.Schedule.duplicate({ id: schedule.id });
      this.fetchList();
    },
  },
};
</script>

<style scoped lang="scss"></style>
