<template>
  <div class="submissions-table">
    <div v-if="list">
      <div v-if="list.length">
        <div class="bulk" :class="{ 'bulk-hidden': !selected.length }">
          <v-btn style="margin-right: 10px" color="primary" @click="publish"
            >Bulk Publish</v-btn
          >
          <v-btn color="primary" @click="unpublish" outlined
            >Bulk Unpublish</v-btn
          >
        </div>
        <v-data-table
          :headers="headers"
          :items="list"
          item-key="id"
          show-expand
          class="elevation-0 data-table"
          disable-pagination
          :items-per-page="-1"
          :hide-default-footer="true"
          show-select
          v-model="selected"
        >
          <template v-slot:item="{ item, isSelected, select }">
            <tr class="data-table__row">
              <td @click.stop>
                <v-checkbox
                  @change="select($event)"
                  :value="isSelected"
                ></v-checkbox>
              </td>
              <td v-for="(td, idx) in headers" :key="idx">
                <div v-if="td.type == 'created_at'">
                  {{
                    item.created_at
                      | moment(
                        "timezone",
                        currentUser.active_project.timezone,
                        "MM/DD/YY h:mm A zz"
                      )
                  }}
                </div>
                <div v-else-if="td.type == 'view'">
                  <v-btn target="_blank" x-small :href="submissionUrl(item)"
                    >View Submission</v-btn
                  >
                </div>
                <div v-else-if="td.type == 'rating'">
                  <b>{{ item.avg_rating }}</b>
                </div>
                <div v-else-if="td.type == 'profile_link'">
                  <a
                    target="_blank"
                    :href="`https://profile.${
                      currentUser.active_project_env == 'staging'
                        ? 'staging-'
                        : ''
                    }community.ideasunited.com/profile/${item.user_id}`"
                  >
                    <v-icon color="primary">$link</v-icon>
                  </a>
                </div>
                <div v-else-if="td.generic">
                  <div v-if="td.type == 'Radio'">
                    <div v-if="item.indexed_formatted_data[td.uuid]">
                      {{ getRadio(td, item) }}
                    </div>
                  </div>
                  <div v-else-if="td.type == 'MediaField'">
                    <div v-if="item.indexed_formatted_data[td.uuid]">
                      <a
                        v-if="
                          item.indexed_formatted_data[td.uuid].value && item.indexed_formatted_data[td.uuid].value.vimeo_id
                        "
                        target="_blank"
                        :href="`https://player.vimeo.com/video/${
                          item.indexed_formatted_data[td.uuid].value.vimeo_id
                        }?h=${
                          item.indexed_formatted_data[td.uuid].value.vimeo_h
                        }`"
                      >
                        <v-icon color="primary">$vimeo</v-icon>
                      </a>
                      <a
                        v-else-if="item.indexed_formatted_data[td.uuid].value && item.indexed_formatted_data[td.uuid].value.url"
                        target="_blank"
                        :href="item.indexed_formatted_data[td.uuid].value.url"
                      >
                        <span>{{
                          getName(
                            item.indexed_formatted_data[td.uuid].value.url
                          )
                        }}</span>
                      </a>
                    </div>
                    <span v-else>N/A</span>
                  </div>
                  <div v-else-if="td.type == 'LinkField'">
                    <a
                      v-if="item.indexed_formatted_data[td.uuid]"
                      target="_blank"
                      :href="item.indexed_formatted_data[td.uuid].value"
                    >
                      <v-icon color="primary">$link</v-icon>
                    </a>
                    <span v-else>N/A</span>
                  </div>
                  <div v-else>
                    {{
                      item.indexed_formatted_data[td.uuid]
                        ? truncate(item.indexed_formatted_data[td.uuid].value)
                        : "N/A"
                    }}
                  </div>
                </div>

                <div v-else-if="td.type == 'link'">
                  <a target="_blank" :href="item[td.value]">
                    <v-icon color="primary">$link</v-icon>
                  </a>
                </div>

                <div style="display: flex" v-else-if="td.type == 'actions'">
                  <v-btn
                    icon
                    color="primary"
                    :to="`/widgets/submission-forms/${entity.id}/submissions/${item.id}/edit`"
                  >
                    <v-icon>$pencil</v-icon>
                  </v-btn>
                  <v-btn @click="destroy(item)" icon color="primary">
                    <v-icon>$delete</v-icon>
                  </v-btn>
                </div>

                <div v-else-if="td.value">{{ truncate(item[td.value]) }}</div>

                <div v-else>N/A</div>
              </td>
            </tr>
          </template>
        </v-data-table>
        <div class="download-bar">
          <form
            method="post"
            :action="`/api/v1/admin_side/submission_forms/${entity.id}/download.csv`"
            target="_blank"
          >
            <input
              type="hidden"
              name="token"
              :value="$store.getters['auth/token']"
            />
            <v-btn type="submit" small outlined color="primary">
              Download CSV
            </v-btn>
          </form>
        </div>
      </div>
      <div v-else class="no-entries">No Submissions</div>
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
import _ from "lodash";
import { profileAppUrl } from "@/lib/domains";

export default {
  props: {
    entity: Object,
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      list: null,
      selected: [],
    };
  },
  created() {
    console.log("set list");
    this.setList();
  },
  computed: {
    selectedIds() {
      return this.selected.map((t) => t.id);
    },
    headers() {
      let result = [
        {
          text: "Profile",
          align: "start",
          type: "profile_link",
        },
        {
          text: "First Name",
          align: "start",
          value: "first_name",
        },
        {
          text: "Last Name",
          align: "start",
          value: "last_name",
        },
        {
          text: "Phone",
          align: "start",
          value: "phone",
        },
        {
          text: "Email",
          align: "start",
          value: "email",
        },
      ];

      let submission_fields = (this.list || []).map((t) => {
        return t.formatted_data.map((t) => ({
          text:
            (this.currentUser.active_project.slug == "referral"
              ? "Referral "
              : "") + (t.short_name || t.label),
          align: "start",
          uuid: t.uuid,
          type: t.type,
          generic: true,
        }));
      });

      submission_fields = _.sortBy(
        _.uniqBy(_.flatten(submission_fields), "uuid"),
        "short_name"
      );

      result = result.map((t) => {
        t.text = `Referrer ${t.text}`;
        return t;
      });

      result = [...result, ...submission_fields];

      result = [
        {
          text: "Submitted date",
          align: "left",
          value: "created_at",
          type: "created_at",
        },
        {
          text: "View Submission",
          align: "left",
          type: "view",
          sortable: false,
        },
        {
          text: "Rating",
          align: "left",
          value: "avg_rating",
          type: "rating",
        },
        ...result,
        {
          text: "Admin",
          align: "center",
          type: "actions",
          sortable: false,
        },
      ];

      return result;
    },
  },
  methods: {
    truncate(text, full) {
      if (full) return text;
      const limit = (string, length, end = "...") => {
        return string.length < length
          ? string
          : string.substring(0, length) + end;
      };
      const result = limit(String(text || ""), 40);

      return result;
    },
    submissionUrl(item) {
      return `${profileAppUrl(
        this.currentUser.active_project.env
      )}/submission/${item.id}`;
    },
    getRadio(td, item) {
      const field = this.entity.submission_form.find(
        (t) => t["uuid"] == td["uuid"]
      );
      if (field && item.indexed_formatted_data[td.uuid]) {
        const val = field.options.find(
          (t) => t.uuid == item.indexed_formatted_data[td.uuid].value
        );
        if (val) return val.title;
      }
      return "";
    },
    getName(str) {
      const t = (str || "").split("/");
      return t[t.length - 1];
    },
    async publish() {
      await this.$api.Submission.bulkPublish({
        submission_ids: this.selectedIds,
      });
      this.setList();
    },
    async unpublish() {
      await this.$api.Submission.bulkUnpublish({
        submission_ids: this.selectedIds,
      });
      this.setList();
    },
    async destroy(submission) {
      const confirmed = await this.$confirm({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.Submission.destroy({ id: submission.id });

      this.list = this.list.filter((t) => t.id != submission.id);
    },
    async setList() {
      const { data } = await this.$api.Submission.lite({
        submission_form_id: this.entity.id,
        ...this.params,
      });
      this.list = data.map((t) => ({
        ...t,
        indexed_formatted_data: _.keyBy(t.formatted_data, "uuid"),
      }));
    },
    referralNotPublishedStatus(item) {
      return item.formatted_data.find((d) => d.short_name == "email")?.value
        ? "Email sent"
        : "No email provided";
    },
  },
};
</script>

<style scoped lang="scss">
.data-table a {
  text-decoration: none;
}

.download-bar {
  text-align: right;
  font-size: 12px;
  text-transform: uppercase;
  padding-right: 30px;
}

.submissions-table {
  &::v-deep th:first-child {
    display: none !important;
  }
}

.bulk-hidden {
  pointer-events: none;
  opacity: 0;
}

.bulk {
  margin-top: -20px;
}

.status {
  padding: 3px 10px;
  border-radius: 3px;
  white-space: nowrap;
}
.status--published {
  background: #099b09c4;
  color: white;
}
.status--not-published {
  background: gray;
  color: white;
}
</style>
