<template>
  <div class="reviews">
    <ListPanels :loaded="!!list">
      <div v-if="list && list.length">
        <ListPanel
          v-for="section in list"
          :key="section.id"
          :action-path="`/profile_reviews/${section.id}`"
          hide-duplicate
          @destroy="destroy(section.id)"
        >
          <template #title>
            <div class="review-title">
              <div>Reviewed by {{ section.reviewer_name }}</div>
              <div>Rating: {{ section.rating }}</div>
            </div>
          </template>
          <div class="review-notes">
            {{ section.notes }}
          </div>
        </ListPanel>
      </div>
      <div v-else class="no-entries">No Reviews</div>
    </ListPanels>
  </div>
</template>

<script>
export default {
  props: {
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      list: null,
    };
  },
  created() {
    this.fetchList();
  },
  methods: {
    async fetchList() {
      const { data } = await this.$api.ProfileReview.get(this.params);

      this.list = data;
    },
    async destroy(id) {
      const confirmed = await this.$confirm({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.ProfileReview.destroy({ id });
      this.fetchList();
    },
  },
};
</script>

<style scoped lang="scss">
.review-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.reviews::v-deep .list-panel-title {
  width: 100%;
}
</style>

<style>
.env-production .review-notes {
  color: white;
}
</style>
