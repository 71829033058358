<template>
  <div class="env-selector__wrapper">
    <div class="env-selector">
      <div class="env-selector__title">
        {{
          ["super_admin", "admin"].includes(currentUser.active_team_role)
            ? "Environment"
            : "Website URL"
        }}
      </div>
      <div
        class="env-selector__content"
        v-if="['super_admin', 'admin'].includes(currentUser.active_team_role)"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="env-selector__current-env"
              outlined
            >
              <span>
                {{ title }}
              </span>
              <v-icon>$chevron_down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="setEnv(item.value)"
              v-for="(item, idx) in envs"
              :key="idx"
              >{{ item.text }}</v-list-item
            >
          </v-list>
        </v-menu>

        <v-btn
          vif="value == 'staging'"
          outlined
          @click="dialog = true"
          class="elevation-0 env-selector__deploy"
          color="primary"
          >Releases</v-btn
        >
      </div>
      <!-- <div class="env-selector__title">{{ title }}</div>
      <div class="env-selector__deploy"></div> -->
      <a target="_blank" class="env-selector__domain" :href="domain | url">{{
        domain | url
      }}</a>
    </div>
    <v-dialog max-width="800" v-model="dialog" v-if="dialog">
      <EnvSelectorDialog />
    </v-dialog>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  data() {
    return {
      envs: [
        { value: "staging", text: "Staging" },
        { value: "production", text: "Production" },
      ],
      value: "staging",
      dialog: false,
      contextChangeId: null,
    };
  },
  created() {
    this.value = this.currentUser.active_project_env;

    const data = {
      token: this.authToken
    };
    this.$cable.subscribe({
      channel: "ContextChannel",
      data
    });

  },
  mounted() {
    this.$root.$on("updateEnv", async env => {
      this.setEnv(env);
    });
    this.$root.$on("fetchEnv", async () => {
      this.fetchEnv();
    });
  },
  beforeDestroy() {
    this.$cable.unsubscribe({
      channel: "ContextChannel",
    });
  },
  channels: {
    ContextChannel: {
      connected() {
        this.fetchEnv();
      },
      received({ payload, type }) {
        switch (type) {
          case "update_context":
            if(payload.context_change_id != this.contextChangeId) {
              this.fetchEnv();
            }
            break;
        }
      },
    },
  },
  methods: {
    async setEnv(env) {
      this.contextChangeId = uuidv4();
      await this.$api.Profile.setEnv({ env, context_change_id: this.contextChangeId });
      await this.$store.dispatch("user/fetch");
      this.value = this.currentUser.active_project_env;
    },
    async fetchEnv() {
      await this.$store.dispatch("user/fetch");
      this.value = this.currentUser.active_project_env;
    }
  },
  computed: {
    title() {
      return this.envs.find((t) => t.value == this.value).text;
    },
    domain() {
      const { currentUser } = this;
      const env = currentUser.active_project_env;
      const { domains } = currentUser.active_project;
      const path = currentUser.active_project[`${env}_root_path`] || "";
      const domain = domains[env] + `/${path}`;
      return domain;
    },
  },
};
</script>

<style scoped lang="scss">
.env-selector {
  padding: 20px;
  border-top: 2px solid var(--v-primary-base);
  background: #f4f4f4;
  width: 100%;
  padding-left: 35px;

  &__title {
    font-size: 12px;
    font-weight: bold;
    color: #56565a;
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -33px;
    margin-top: 30px;
  }

  &__deploy {
    margin-top: -1px;
    margin-left: 10px;
    letter-spacing: 1px;
    padding: 0 15px !important;
  }

  &__domain {
    font-size: 11px;
  }

  &__current-env {
    border: 1px solid #a0a0a087 !important;
    font-size: 14px;
    font-weight: bold;
    background: white;
    letter-spacing: 0 !important;
    color: #56565a;
    padding: 0 10px 0 15px !important;
    width: 121px;

    &::v-deep {
      .v-icon {
        position: relative;
        top: 2px;
        left: 2px;
      }

      .v-btn__content {
        justify-content: space-between;
      }
    }
  }
}
</style>
