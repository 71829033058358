<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Project Form Info">
        <FormRow required label="Title">
          <TextField
            placeholder="Title"
            :rules="[validation.required]"
            v-model="form.title"
          ></TextField>
        </FormRow>
        <FormRow label="Project Code" required>
          <TextField
            placeholder="Project Code"
            :rules="[validation.required]"
            v-model="form.code"
          ></TextField>
        </FormRow>
        <FormRow label="Description">
          <TextArea
            placeholder="Description"
            v-model="form.description"
          ></TextArea>
        </FormRow>
        <FormRow label="Quickbooks Project">
          <template #tooltip
            >This is the customer ID for this <i>project</i> in Quickbooks. This is manually entered for now.</template
          >
          <QbProjectSelector
            placeholder="Quickbooks Project"
            v-model="form.qb_project_id"
            @input="form.qb_project_id = $event"
          />
        </FormRow>
        <FormRow label="Project Start Date">
          <template #tooltip
            >This is the time the project will "go live".</template
          >
          <DateTimePicker
            placeholder="Select Date/Time"
            v-model="form.start_date"
          ></DateTimePicker>
        </FormRow>
        <FormRow label="Project End Date">
          <template #tooltip>
            This is the time the project will "go offine".
          </template>
          <DateTimePicker
            placeholder="Select Date/Time"
            v-model="form.end_date"
          ></DateTimePicker>
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../_extends/form.extends.js";
import QbProjectSelector from "@/components/selects/QbProjectSelector.vue";

export default {
  extends: mixin,
  components:  {
    QbProjectSelector
  },
};
</script>

<style></style>
