<template>
  <div>
    <div class="presentation" v-if="presentation">
      <div class="presentation__main-slide" v-if="currentSlide">
        <PresentationImage
          v-if="currentSlide.slide_type == 'image'"
          :slide="currentSlide"
        />
        <PresentationYoutube
          v-if="currentSlide.slide_type == 'youtube'"
          :slide="currentSlide"
          :started="currentSlideSettings.started"
          @setTime="setTime"
          @setStarted="setStarted"
          v-model="player"
        />
        <PresentationVimeo
          v-if="currentSlide.slide_type == 'vimeo'"
          :slide="currentSlide"
          :started="currentSlideSettings.started"
          @setTime="setTime"
          @setStarted="setStarted"
          v-model="player"
        />

        <div class="presentation__main-slide-publish">
          <div v-if="!presentation.broadcasting">
            <v-btn @click="broadcast(true)" rounded color="primary"
              >Broadcast</v-btn
            >
          </div>
          <div v-else>
            <v-btn @click="broadcast(false)" rounded color="primary"
              >Stop Broadcast</v-btn
            >

            <v-btn
              style="margin-left: 20px"
              @click="syncVideo"
              rounded
              outlined
              color="primary"
              v-if="currentSlideSettings.started"
              >Sync Users Videos</v-btn
            >
            <v-btn
              v-if="currentSlideSettings.state == 'play'"
              small
              fab
              style="margin-left: 20px"
              @click="pause"
            >
              <v-icon>$pause</v-icon>
            </v-btn>

            <v-btn
              v-if="currentSlideSettings.state == 'pause'"
              small
              fab
              style="margin-left: 20px"
              @click="play"
            >
              <v-icon>$play</v-icon>
            </v-btn>
          </div>
        </div>
      </div>

      <PresentationRotator
        v-model="currentSlide"
        @setSlide="setSlide"
        :slides="presentation.slides"
        :presentation="presentation"
      />
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
export default {
  data() {
    return {
      presentation: null,
      currentSlide: null,
      currentTime: null,
      player: null,
    };
  },
  created() {
    this.fetchPresentation();
  },
  computed: {
    currentSlideSettings() {
      return this.presentation.settings[this.currentSlide.id] || {};
    },
  },
  methods: {
    extendSettings(obj) {
      return {
        ...this.presentation,
        settings: {
          ...this.presentation.settings,
          [this.currentSlide.id]: {
            ...(this.presentation.settings[this.currentSlide.id] || {}),
            ...obj,
          },
        },
      };
    },
    async play() {
      const { data } = await this.$api.Presentation.update(
        this.extendSettings({
          state: "play",
          time: await this.player.getCurrentTime(),
        })
      );
      this.presentation = data;
      this.player.playVideo();
    },
    async pause() {
      const { data } = await this.$api.Presentation.update(
        this.extendSettings({
          state: "pause",
          time: await this.player.getCurrentTime(),
        })
      );
      this.player.pauseVideo();
      this.presentation = data;
    },
    async setStarted(val) {
      const { data } = await this.$api.Presentation.update(
        this.extendSettings({ started: val, state: "play" })
      );

      this.presentation = data;
    },
    async syncVideo() {
      const { data } = await this.$api.Presentation.update(
        this.extendSettings({ time: await this.player.getCurrentTime() })
      );

      this.presentation = data;
    },
    async setTime(time) {
      this.currentTime = time;
    },
    async broadcast(broadcasting) {
      const { data } = await this.$api.Presentation.update({
        ...this.presentation,
        broadcasting,
        active_slide_id: this.currentSlide.id,
      });

      this.presentation = data;
    },
    async setSlide(slide) {
      if (!this.presentation.broadcasting) return;
      const { data } = await this.$api.Presentation.update({
        ...this.presentation,
        active_slide_id: slide.id,
      });
      this.presentation = data;
    },
    async fetchPresentation() {
      const { data } = await this.$api.Presentation.get({
        id: this.$route.params.id,
      });
      this.presentation = data;
      this.currentSlide =
        this.presentation.slides.find(
          (t) => t.id == this.presentation.active_slide_id
        ) || this.presentation.slides[0];
    },
  },
};
</script>

<style scoped lang="scss">
.presentation {
  position: absolute;
  background: #f7f7f7;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  &__main-slide {
    height: calc(100% - 140px);
    width: 100%;
    padding: 20px 0;
    position: relative;
    &-publish {
      text-align: center;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      z-index: 9;
    }

    &-title {
      font-weight: bold;
      font-size: 20px;
      padding: 15px;
      text-align: center;
      position: absolute;
      width: 100%;
      background: rgba(255, 255, 255, 0.5);
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      margin: 1px;
      top: 0;
    }
  }
}
</style>
