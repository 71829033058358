<template>
  <div
    class="year-month-selector flex flex-direction-column"
    :class="{'dark': dark}"
  >
    <div class="flex justify-content-right year-month-selector__close-wrapper">
      <CustomButton
        :color="dark ? '#757575' : '#424242'"
        :dark="dark"
        with-border
        @click="$emit('back')"
        class="year-month-selector__close"
      >
        <span class="fs-16">
          ✕
        </span>
      </CustomButton>
    </div>
    <div class="flex-1 flex flex-wrap justify-content-between year-month-selector__items">
      <CustomButton
        v-for="(m, index) in months"
        :key="index"
        :color="color"
        :selected="currentMonth === index"
        :dark="dark"
        class="month-button"
        with-border
        @click="selectMonth(index)"
      >
        {{ m }}
      </CustomButton>
      <CustomButton
        v-for="year in years"
        :key="year"
        :color="color"
        :dark="dark"
        :selected="currentYear === year"
        with-border
        @click="selectYear(year)"
      >
        {{ year }}
      </CustomButton>
    </div>

    <div class="flex justify-content-space-between year-month-selector__year-nav" v-if="!isMonthMode">
      <CustomButton
        :color="dark ? '#757575' : '#424242'"
        :dark="dark"
        with-border
        @click="browseYearsBack()"
        class="year-month-selector__close"
      >
        <span>
          <v-icon>$arrow_left</v-icon>
        </span>
      </CustomButton>
      <CustomButton
        :color="dark ? '#757575' : '#424242'"
        :dark="dark"
        with-border
        @click="browseYearsForward()"
        class="year-month-selector__close"
      >
        <span>
          <v-icon>$arrow_right</v-icon>
        </span>
      </CustomButton>
    </div>
  </div>
</template>

<script>
  import { getMonthsShort } from '../../../../../modules/month'
  import CustomButton from '../../../../../_subs/CustomButton'

  const ArrayRange = (start, end) => {
    return Array(end - start + 1).fill().map((_, idx) => {
      const n = start + idx
      return n
    })
  }

  export default {
    name: 'YearMonthSelector',
    components: {
      CustomButton
    },
    props: {
      locale: { type: String, default: null },
      dark: { type: Boolean, default: null },
      color: { type: String, default: null },
      mode: { type: String, default: null },
      month: { type: Object, default: null },
      startYear: Number,
      endYear: Number,
    },
    data () {
      return {
        months: null,
        years: null,
        startYearChanged: null,
        endYearChanged: null,
      }
    },
    computed: {
      currentMonth () {
        return this.month.month
      },
      currentYear () {
        return this.month.year
      },
      isMonthMode () {
        return this.mode === 'month'
      }
    },
    mounted () {
      if (this.isMonthMode) {
        this.getMonths()
      } else {
        this.getYears()
      }
    },
    methods: {
      getMonths () {
        this.years = null
        this.months = getMonthsShort(this.locale)
      },
      getYears () {
        this.months = null
        if(!this.startYearChanged) {
          this.startYearChanged = this.startYear || this.month.year - 17;
          this.endYearChanged = this.endYear || this.month.year + 17;
        }
        this.years = ArrayRange(this.startYearChanged, this.endYearChanged)
      },
      browseYearsBack() {
        this.startYearChanged = this.startYearChanged - 10;
        this.endYearChanged = this.endYearChanged - 10;
        this.getYears();
      },
      browseYearsForward() {
        this.startYearChanged = this.startYearChanged + 10;
        this.endYearChanged = this.endYearChanged + 10;
        this.getYears();
      },
      selectMonth (monthNumber) {
        this.$emit('input', { month: monthNumber, year: this.currentYear })
      },
      selectYear (year) {
        this.$emit('input', { month: this.currentMonth, year: year });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .year-month-selector{
    position: absolute;
    background-color: white;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #424242;
    padding: 10px;
    &.dark {
      color: white;
      background-color: #424242;
    }
    .month-button {
      text-transform: capitalize;
    }
    &__close, &__close-wrapper {
      flex-grow: 0;
    }
    &__items {
      align-content: center;
      overflow: scroll;
      height: 100%;
      flex-basis: 100%;
    }
    &__year-nav {
      justify-content: space-between;
    }
  }
</style>
