<template>
  <div>
    <div class="page-title">Edit Countdown Clock</div>
    <Form
      v-if="form"
      v-model="form"
      :submit="update"
      btnText="Update Countdown Clock"
    />
    <Spinner v-else />
  </div>
</template>

<script>
import Form from "./_form.countdowns.page";
export default {
  components: { Form },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async setForm() {
      const { data } = await this.$api.Countdown.get({
        id: this.$route.params.id,
      });
      this.form = data;
    },
    async update() {
      await this.$api.Countdown.update(this.form);
      this.$router.push("/widgets/countdowns");
    },
  },
};
</script>

<style></style>
