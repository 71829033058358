<template>
  <div class="content-blocks">
    <div v-for="(block, index) in list"
class="content-block" :key="block.id">
      <router-link
        :to="`/widgets/content-sections/${entity.id}/blocks/${block.id}/edit`"
        class="content-block__preview"
        :style="{ backgroundImage: `url(${block.preview.thumb.url})` }"
      />
      <div class="content-block__title">
        {{ block.title }}
      </div>
      <div class="content-block__subtitle">
        {{ block.subtitle }}
      </div>
      <Actions
        :path="`/widgets/content-sections/${entity.id}/blocks/${block.id}`"
        @destroy="$emit('destroy', block)"
        @duplicate="$emit('duplicate', block)"
      >
        <template #prepend>
          <div style="display: contents;" v-if="entity.sort_by == 'position'">
            <v-btn icon @click="$emit('moveUp', index)" :disabled="index == 0">
              <v-icon>$arrow_left</v-icon>
            </v-btn>
            <v-btn icon @click="$emit('moveDown', index)" :disabled="index == list.length - 1">
              <v-icon>$arrow_right</v-icon>
            </v-btn>
          </div>
        </template>
      </Actions>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    entity: Object,
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
.content-blocks {
  display: flex;
  margin: -17px;
  flex-wrap: wrap;
}

.content-block {
  font-size: 12px;
  line-height: 16px;
  margin: 17px;

  &__title {
    font-weight: bold;
    margin-top: 5px;
  }
  &__subtitle {
  }
  &__preview {
    height: 120px;
    width: 210px;
    position: relative;
    background: var(--v-border-lighten1);
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    display: block;

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
