var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.title)?_c('div',{staticClass:"sidebar-title"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_vm._l((_vm.items),function(item,idx){return _c('div',{key:idx},[(item.children)?_c('v-list-group',{staticClass:"sidebar-group",attrs:{"group":item.to,"value":item.opened},scopedSlots:_vm._u([{key:"appendIcon",fn:function(){return [_c('v-icon',{staticClass:"sidebar-group__toggle-icon"},[_vm._v(" $chevron_down ")])]},proxy:true},{key:"activator",fn:function(){return [_c('v-tooltip',{attrs:{"open-delay":"500","disabled":!item.tooltip,"max-width":"300","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"sidebar-item",on:{"click":function($event){item.createOpp ? _vm.openNewOppDialog() : false}}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("$"+_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tooltip))])])]},proxy:true}],null,true)},[_c('div',_vm._l((item.children),function(subItem,sidx){return _c('v-tooltip',{key:sidx,attrs:{"open-delay":"500","disabled":!subItem.tooltip,"max-width":"300","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"sidebar-subitem",attrs:{"link":"","to":subItem.to,"active-class":"active"},on:{"click":function($event){subItem.createOpp ? _vm.openNewOppDialog() : false}}},'v-list-item',attrs,false),on),[_c('v-list-item-title',[_vm._v(_vm._s(subItem.text))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(subItem.tooltip))])])}),1)]):_c('v-tooltip',{attrs:{"open-delay":"500","disabled":!item.tooltip,"max-width":"300","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"sidebar-item",attrs:{"to":item.to,"active-class":"active"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("$"+_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tooltip))])])],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }