<template>
  <div>
    <Spinner v-if="!form.project" />

    <div v-else>
      <ProjectForm ref="form" v-model="form.project" />
      <div class="onboarding__form-footer">
        <OnboardingBackBtn @submit="$emit('backStep')" />
        <OnboardingNextBtn :disabled="nextStepDisabled" @submit="nextStep"
          >Finish</OnboardingNextBtn
        >
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../_extends/form.extends.js";
import ProjectForm from "../projects/_form.projects.page";

export default {
  extends: mixin,
  components: { ProjectForm },
  props: {
    nextStepDisabled: Boolean,
  },
  created() {
    this.initProject();
  },
  methods: {
    async initProject() {
      if (!this.form.project) {
        const { data } = await this.$api.Project.new();
        this.form = { ...this.form, project: data };
      }
    },

    nextStep() {
      const { form } = this.$refs.form.$refs;
      if (form.validate()) {
        this.$emit("nextStep");
      } else {
        this.scrollToInvalidInput(form.inputs);
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
