<template>
  <div class="event-datacard">
    <div
      class="event-datacard__container"
      :class="{ 'event-datacard__container--dark': event.preview.thumb.url }"
      :style="{
        backgroundImage: event.preview.thumb.url
          ? `url(${event.preview.thumb.url})`
          : '',
      }"
    >
      <div class="event-datacard__body">
        <div class="event-datacard__title">
          {{ event.title }}
        </div>
        <div class="event-datacard__description">
          <div>
            {{
              event.start_at
                | moment(
                  'timezone',
                  currentUser.active_project.timezone,
                  'MM/DD/YY h:mm A'
                )
            }}
          </div>
        </div>

        <span v-for="page in event.pages" :key="page.id" class="mr-3">
          <router-link :to="`/pages/${page.id}/edit`">{{
            page.title
          }}</router-link>
        </span>
      </div>

      <Actions
        class="event-datacard__actions"
        :path="`/events/${event.id}`"
        :hide-destroy="hideDestroy"
        :hide-duplicate="hideDuplicate"
        @destroy="$emit('destroy', event)"
        @duplicate="$emit('duplicate', event)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideDestroy: Boolean,
    hideDuplicate: Boolean,
    event: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped lang="scss">
.event-datacard {
  min-height: 165px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  a:hover {
    text-decoration: none;
  }

  &::before {
    content: '';
    background: var(--v-border-base);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.5;
  }

  &__container {
    position: relative;
    padding: 20px 20px 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-size: cover;
    background-position: center;

    &--dark:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.405);
    }
  }
  &__container--dark &__title,
  &__container--dark &__body,
  &__container--dark a {
    color: white;
  }

  &__container--dark::v-deep .v-icon__svg {
    fill: white;
  }

  &__body {
    position: relative;
  }

  &__title {
    font-weight: 900;
    font-size: 20px;
    line-height: 1.1;
  }

  &__description {
    font-size: 12px;
  }

  &__actions {
    margin-left: -5px;
  }
}
</style>
