<template>
  <div class="reference-examples">
    <div
      v-for="(item, idx) in localValue"
      :key="`refex_${idx}`"
      class="reference-example"
      :class="{ deleted: item._destroy }"
    >
      <div class="reference-example__inputs">
        <Select
          v-model="item.content_type"
          :items="referenceExamplesTypes"
          class="reference-example__inputs__type"
        ></Select>
        <FileField
          placeholder="Upload"
          v-model="item.file"
          v-if="item.content_type == 'file'"
          class="reference-example__inputs__file"
        />
        <TextField
          placeholder="URL"
          v-model="item.link"
          v-if="item.content_type == 'link'"
          class="reference-example__inputs__link"
          :rules="[validation.url]"
        />
        <v-icon
          class="reference-example__inputs__delete"
          @mousedown="removeRefEx(idx)"
          >$delete</v-icon
        >
      </div>
      <div
        v-if="item.content_type == 'file' && item.file && item.file.url"
        class="reference-example__download"
      >
        <a
          :href="item.file.url"
          target="_blank"
        ><v-icon color="primary" small>$download</v-icon> {{ filenameFromUrl(item.file.url) }}</a>
      </div>
    </div>
    <v-btn color="primary" elevation="0" rounded small @click="addRefEx">
      {{ localValue.length ? 'Add Another Reference' : 'Add a Reference' }}
    </v-btn>
  </div>
</template>


<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [{ slides: [] }],
    },
  },
  data() {
    return {
      localValue: [...this.value],
      referenceExamplesTypes: [
        { name: "File Upload", value: "file" },
        { name: "Link", value: "link" },
      ],
    }
  },
  methods: {
    filenameFromUrl(url) {
      const arr = url.split('/');
      return arr[arr.length-1];
    },
    addRefEx() {
      this.localValue.push({ content_type: 'file' });
    },
    removeRefEx(idx) {
      if (this.localValue[idx].id) {
        this.localValue[idx]._destroy = true;
      } else {
        this.localValue.splice(idx, 1);
      }
      this.$emit("input", [...this.localValue]);
    },
  },
  watch: {
    localValue: {
      deep: true,
      handler() {
        if (JSON.stringify(this.localValue) != JSON.stringify(this.value)) {
          this.$emit("input", [...this.localValue]);
        }
      },
    },
    value: {
      deep: true,
      handler() {
        this.localValue = [...this.value];
      },
    },
  },
}
</script>

<style scoped lang="scss">
.reference-example {
  margin-bottom: 1em;

  &.deleted {
    display: none;
  }

  &__inputs {
    display: flex;
    &__type {
      max-width: 130px;
      margin-right: 1em;
    }
    &__file,
    &__link {
      flex-basis: 100%;
      margin-right: 1em;
    }
  }
  &__download,
  &__link {
    font-size: 0.8em;
  }
}
</style>