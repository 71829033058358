<template>
  <div>
    <div class="page-title">New Notification</div>
    <Form btnText="Add Notification" v-model="form" :submit="create" />
  </div>
</template>

<script>
import Form from "./_form.profile_notification.page";
export default {
  components: { Form },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setNotification();
  },
  methods: {
    async setNotification() {
      const { data } = await this.$api.ProfileNotification.new();
      this.form = data;
    },
    async create() {
      await this.$api.ProfileNotification.create(this.form);
      this.$router.push("/profile_notifications");
    },
  },
};
</script>

<style></style>
