<template>
  <div class="presentation-slides">
    <div class="presentation-slide" v-for="slide in list" :key="slide.id">
      <router-link
        :to="`/dev-tools/presentations/${slide.presentation_id}`"
        class="presentation-slide__preview"
        :style="{
          backgroundImage: `url(${
            slide.preview ? slide.preview.thumb.url : ''
          })`,
        }"
      ></router-link>
      <div class="presentation-slide__title">{{ slide.title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    entity: Object,
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
.presentation-slides {
  display: flex;
  margin: -17px;
  flex-wrap: wrap;
}

.presentation-slide {
  font-size: 12px;
  line-height: 16px;
  margin: 17px;

  &__title {
    font-weight: bold;
    margin-top: 5px;
  }

  &__preview {
    height: 120px;
    width: 210px;
    position: relative;
    background: var(--v-border-lighten1);
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    display: block;

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
