<template>
  <v-autocomplete
    class="location-selector"
    v-model="place"
    :items="places"
    :search-input.sync="query"
    outlined
    rounded
    clearable
    hide-details="auto"
    :dense="dense"
    :placeholder="placeholder"
    :label="label"
    append-icon=""
    hide-no-data
    :rules="rules"
  ></v-autocomplete>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    value: String,
    placeholder: String,
    label: String,
    type: String,
    dense: Boolean,
    source: {
      type: String,
      default: "text",
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const placeType = {
      city: "place",
      state: "region",
      country: "country",
    }[this.type];

    const places = this.value ? [this.value] : [];

    return {
      place: this.value,
      places,
      query: "",
      trigger: false,
      placeType,
    };
  },
  methods: {
    fetchPlaces: _.debounce(async function () {
      const { data } = await this.$api.Geo.search({
        query: this.query,
        place_type: this.placeType,
        source: this.source,
      });
      this.places = data;
    }, 300),
  },
  watch: {
    query: {
      handler() {
        if (!this.trigger && this.query != this.place) {
          this.trigger = true;
        }
        if (this.query && this.trigger) {
          this.fetchPlaces();
        }
        if (this.query == null) {
          this.places = this.place ? [this.place] : [];
        }
      },
    },
    place: {
      handler() {
        this.$emit("input", this.place || "");
      },
    },
    value: {
      handler() {
        this.place = this.value;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.location-selector {
  display: flex;
  max-width: 270px;

  &__item {
    width: 100%;
  }

  &:not(.v-input--has-state)::v-deep fieldset {
    border-color: var(--v-border-base);
  }
}
</style>
