<template>
  <div>
    <div v-if="list">
      <v-data-table
        v-if="list.length"
        :headers="headers"
        :items="list"
        item-key="id"
        show-expand
        class="elevation-0 data-table"
      >
        <template v-slot:body="{ items }">
          <tbody v-for="item in items" :key="item.id">
            <tr class="data-table__row">
              <td />
              <td>
                <router-link
                  :to="`/widgets/lists/${entity.id}/entries/${item.id}/edit`"
                >
                  {{ item.title }}
                </router-link>
              </td>
              <td>
                {{ item.subtitle }}
              </td>
              <td class="text-center nowrap">
                <Actions
                  hide-duplicate
                  :path="`/widgets/lists/${entity.id}/entries/${item.id}`"
                  @destroy="destroy(item)"
                />
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <div v-else class="no-entries">
        No Entries
      </div>
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
export default {
  props: {
    entity: Object,
  },
  data() {
    return {
      list: null,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: 'Title',
          align: 'start',
          value: 'notification_type',
        },
        {
          text: 'Subtitle',
          align: 'start',
          value: 'title',
        },
        {
          align: 'center',
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.setList();
  },
  methods: {
    async destroy(entry) {
      const confirmed = await this.$confirm({ title: 'Are you sure?' });
      if (!confirmed) return;
      await this.$api.ListEntry.destroy({ id: entry.id });

      this.list = this.list.filter(t => t.id != entry.id);
    },
    async setList() {
      const { data } = await this.$api.ListEntry.lite({
        list_id: this.entity.id,
      });
      this.list = data;
    },
  },
};
</script>

<style></style>
