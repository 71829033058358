<template>
  <v-form ref="form" @submit.prevent="submitForm">
    <v-card-text>
      <TextField
        :rules="[validation.required]"
        style="width: 100%"
        v-model="form.title"
        label="Theme Name"
      />
      <slot></slot>
      <Checkbox v-model="form.active">Set as Default Theme</Checkbox>
    </v-card-text>
    <v-card-actions style="padding: 15px">
      <v-btn
        v-if="tempForm.id && !tempForm.active"
        outlined
        @click="$emit('destroy')"
      >
        Delete
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn style="margin-right: 15px" text @click="$emit('close')">
        Cancel
      </v-btn>

      <v-btn type="submit" color="primary">
        Save
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import mixin from "../_extends/form.extends.js";

export default {
  extends: mixin,
  props: {
    tempForm: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    submitForm() {
      if (!this.$refs.form.validate()) return;
      this.submit();
    },
  },
};
</script>

<style></style>
