<template>
  <div class="actions">
    <slot name="prepend" />
    <v-btn
      v-if="!hideEdit"
      :dark="dark"
      :to="`${path}/edit`"
      @click.stop=""
      icon
    >
      <v-icon>$pencil</v-icon>
    </v-btn>
    <v-btn
      v-if="!hideDuplicate"
      :dark="dark"
      icon
      @click.stop="$emit('duplicate')"
    >
      <v-icon>$content_copy</v-icon>
    </v-btn>
    <!-- <v-btn v-if="!hideShow" :dark="dark" :to="`${path}`" icon>
      <v-icon>$eye</v-icon>
    </v-btn> -->
    <v-btn v-if="!hideDestroy" :dark="dark" icon @click.stop="$emit('destroy')">
      <v-icon>$delete</v-icon>
    </v-btn>
    <slot name="append" />
  </div>
</template>

<script>
export default {
  props: {
    path: String,
    hideDestroy: Boolean,
    hideEdit: Boolean,
    hideShow: Boolean,
    hideDuplicate: Boolean,
    dark: Boolean,
  },
};
</script>

<style></style>
