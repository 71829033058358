<template>
  <div>
    <div class="page-header">
      <div class="page-title">Tools & Authentication</div>
      <ContextSearch v-model="searchText" placeholder="Search Tools" />
    </div>
    <div class="addons" v-if="groups">
      <div
        class="addons-group"
        v-for="(items, group) in filteredGroups"
        :key="group"
      >
        <div class="addons-group__title">{{ group }}</div>

        <Addon v-for="addon in items" :key="addon.id" :addon="addon" />
      </div>
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
import _ from "lodash";

export default {
  data() {
    return {
      groups: null,
      searchText: "",
    };
  },
  created() {
    this.setList();
  },
  methods: {
    async setList() {
      const { data } = await this.$api.Addon.get();
      this.groups = _.groupBy(data, (t) => t.addon.group);
    },
  },
  computed: {
    filteredGroups() {
      const searchText = this.searchText || "";
      const groups = {};

      for (const key in this.groups) {
        const values = this.groups[key].filter((t) =>
          t.addon.title.toLowerCase().includes(searchText.toLowerCase())
        );

        if (values.length) {
          groups[key] = values;
        }
      }
      return groups;
    },
  },
};
</script>

<style scoped lang="scss">
.addons {
  margin-top: 40px;

  &-group {
    &__title {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 10px;
      margin-top: 30px;
    }
  }
}
</style>
