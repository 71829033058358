<template>
  <div>
    <div class="page-title">
      New Content Block
    </div>
    <Form
      v-if="form"
      v-model="form"
      :submit="create"
      btn-text="Add Content Block"
    />
  </div>
</template>

<script>
import Form from './_form.content-blocks.page';

export default {
  components: { Form },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async setForm() {
      const { data } = await this.$api.ContentBlock.new({
        content_section_id: this.$route.params.id,
      });
      this.form = data;
    },
    async create() {
      await this.$api.ContentBlock.create(this.form);
      this.$router.push('/widgets/content-sections');
    },
  },
};
</script>

<style></style>
