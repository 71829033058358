<template>
  <v-dialog
    @keydown.enter="confirm"
    @click:outside="cancel"
    max-width="1000"
    v-model="dialog"
  >
    <v-card>
      <v-btn class="dialog-close" icon @click="cancel">
        <v-icon>$close</v-icon>
      </v-btn>
      <v-card-title class="dialog-title">
        Duplicate this opportunity?
      </v-card-title>

      <v-card-text :key="`duplicate-form-${formKey}`">
        <p>
          This will create a new opportunity based on <b>{{ source_title }}</b
          >, including duplicates of all pages, content and widgets. Please set
          the basic settings for your new site.
        </p>
        <FormRow
          tooltip="This is the name your opportunity will be referenced as on the Admin portal."
          required
          label="Opportunity Name"
        >
          <TextField
            v-model="form.title"
            placeholder="Opportunity Name"
            :rules="[validation.required]"
          />
        </FormRow>
        <FormRow
          tooltip="This is the title shown on submission thank you messages, and the browser tab if no other SEO title is set."
          label="Opportunity Headline"
        >
          <TextField
            v-model="form.headline"
            placeholder="Opportunity Headline"
          />
        </FormRow>
        <FormRow
          required
          label="Production Root Path"
          tooltip="This will be the production root path for your Opportunity."
        >
          <v-layout align-center>
            <div class="mr-3">
              <div v-if="form.production_domain">
                https://{{ form.production_domain }}/
              </div>
            </div>
            <div>
              <TextField
                v-model="form.production_root_path"
                placeholder="example"
                :rules="[validation.project.rootPath]"
              />
            </div>
          </v-layout>
        </FormRow>
        <div v-if="form.production_domain">
          <FormRow label="Redirect to another Website">
            <RadioGroup
              v-model="form.production_redirect_enabled"
              :items="[
                { title: 'No', value: false },
                { title: 'Yes', value: true },
              ]"
            />
          </FormRow>
          <FormRow
            v-if="form.production_redirect_enabled"
            required
            label="Redirect URL"
          >
            <TextField
              v-model="form.production_redirect_url"
              placeholder="Redirect URL"
              :rules="[validation.required, validation.url]"
            />
          </FormRow>
        </div>
        <FormRow label="Password Protect Live Site">
          <template #tooltip>
            Select "Yes" if you want to have password access to the URL above.
            Note, this is not user authentication, purely domain access.
          </template>
          <RadioGroup
            v-model="form.restrict_production_access"
            :items="[
              { title: 'No', value: false },
              { title: 'Yes', value: true },
            ]"
          />
        </FormRow>
        <div v-if="form.restrict_production_access">
          <FormRow
            required
            label="Live Site Access Username"
            tooltip="The username to access the Live Site URL"
          >
            <TextField
              v-model="form.production_login"
              placeholder="Live Site Access Username"
              :rules="[validation.required]"
            />
          </FormRow>
          <FormRow
            required
            label="Live Site Access Password"
            tooltip="The password to access the Live Site URL"
          >
            <TextField
              v-model="form.production_password"
              placeholder="Live Site Access Password"
              :rules="[validation.required]"
              type="password"
            />
          </FormRow>
        </div>
        <FormRow
          required
          label="Staging Root Path"
          tooltip="This will be the staging root path for your opportunity."
        >
          <v-layout align-center>
            <div class="mr-3">
              <div v-if="form.staging_domain">
                https://staging-{{ rootDomain }}/
              </div>
            </div>
            <div>
              <TextField
                v-model="form.staging_root_path"
                placeholder="example"
                :rules="[validation.project.rootPath]"
              />
            </div>
          </v-layout>
        </FormRow>
        <FormRow label="Password Protect Staging Site">
          <template #tooltip>
            Select "Yes" if you want to have password access to the URL above.
            Note, this is not user authentication, purely domain access.
          </template>
          <RadioGroup
            v-model="form.restrict_staging_access"
            :items="[
              { title: 'No', value: false },
              { title: 'Yes', value: true },
            ]"
          />
        </FormRow>
        <div v-if="form.restrict_staging_access">
          <FormRow required label="Staging Site Access Username">
            <template #tooltip>
              The username to access the Staging Site URL
            </template>
            <TextField
              v-model="form.staging_login"
              placeholder="Staging Site Access Username"
              :rules="[validation.required]"
            />
          </FormRow>
          <FormRow required label="Staging Site Access Password">
            <template #tooltip>
              The password to access the Staging Site URL
            </template>
            <TextField
              v-model="form.staging_password"
              placeholder="Staging Site Access Password"
              :rules="[validation.required]"
            />
          </FormRow>
        </div>


        <FormRow label="Publish opportunity">
            <template #tooltip>
              Select "Yes" if you want to make this opportunity visible on the
              frontend
            </template>
            <RadioGroup
              v-model="form.published"
              :items="[
                { title: 'No', value: false },
                { title: 'Yes', value: true },
              ]"
            />
          </FormRow>
        <slot></slot>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="margin-right: 15px" text @click="cancel"> Cancel </v-btn>

        <v-btn color="primary" @click="confirm(true)">
          Duplicate Opportunity
        </v-btn>
      </v-card-actions>
      <Spinner v-show="working" :relative="false">
        <v-card class="spinner-card">
          Working... This might take a while.
        </v-card>
      </Spinner>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ProjectDuplicateDialog",
  data() {
    return {
      data: {},
      form: {},
      source_title: null,
      dialog: null,
      rootDomain: process.env.VUE_APP_ROOT_DOMAIN,
      working: false,
      formKey: 1,
    };
  },
  mounted() {
    this.$root.$on("ProjectDuplicateDialog", async (data) => {
      this.dialog = true;
      this.data = data;
      this.form = data.project;
      this.source_title = data.project.title;
      this.$nextTick(() => { this.formKey++ });
    });
  },
  methods: {
    async confirm() {
      // send request to api
      this.working = true;
      try {
        const response = await this.$api.Project.duplicate(this.form);
        this.working = false;
        if (response.status == 200) {
          this.data.callback({
            confirmed: true,
            project_id: response.data.project,
          });
          this.dialog = false;
          this.data = {};
          this.form = {};
        }
      } catch (e) {
        this.working = false;
        // this.$alert("Something went wrong.", "error");
      }
    },
    cancel() {
      this.data.callback({
        confirmed: false,
      });
      this.dialog = false;
      this.data = {};
      this.form = {};
      this.$nextTick(() => { this.formKey++ });
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-close {
  position: absolute;
  right: 5px;
  top: 5px;
}

.dialog-title {
  padding-top: 25px !important;
}

.spinner {
  background: rgba(33, 33, 33, 0.25);
  &-card {
    padding: 1em;
    margin-bottom: 0.5em;
  }
}
</style>
