<template>
  <v-dialog
    max-width="1000"
    v-model="dialog"
    @click:outside="cancel"
  >
    <div class="new-opportunity-dialog">
      <div class="new-opportunity-dialog__title page-title">
        New Opportunity
      </div>
      <div class="new-opportunity-dialog__templates">
        <div class="new-opportunity-dialog__subtitle page-subtitle">
          Select a template to start:
        </div>
        <div class="new-opportunity-dialog__templates-list">
          <div
            class="template"
            v-for="template in templates"
            :key="template.id"
            @click="duplicateProject(template)"
          >
            <div
              class="template__preview"
              :style="{ backgroundImage: `url(${template.preview.thumb.url})` }"
            >
              <Avatar
                v-if="!template.preview.thumb.url"
                :id="template.id"
                :name="template.title"
              />
            </div>
            <div class="template__container">
              <div class="template__title">{{ template.title }}</div>
              <div class="template__description">
                by <u style="color: #8e8989">{{ template.team_name }}</u>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="new-opportunity-dialog__from-scratch">
        <div class="new-opportunity-dialog__subtitle page-subtitle">
          or:
        </div>
        <div class="new-opportunity-dialog__from-scratch-contents">
          <v-btn
            to="/projects/new"
            @click="cancel"
            elevation="0"
            outlined
            class="new-opportunity-dialog__from-scratch-btn"
            >Start From Scratch</v-btn
          >
        </div>
      </div>
    </div>
    <ProjectDuplicateDialog />
  </v-dialog>
</template>
<script>
import projectDuplicateMixin from "../mixins/project-duplicate.mixin";
import ProjectDuplicateDialog from "@/components/ProjectDuplicateDialog.vue";

export default {
  extends: projectDuplicateMixin,
  components: { ProjectDuplicateDialog },
  data() {
    return {
      dialog: null,
      templates: [],
    }
  },
  created(){
    this.fetchList();
  },
  mounted() {
    this.$root.$on("NewOpportunityDialog", () => {
      this.dialog = true;
    });
    this.$root.$on("projects/reload", () => {
      if(this.dialog) this.dialog = false;
    });
  },
  methods: {
    async fetchList() {
      const { data } = await this.$api.Project.templates();
      this.templates = data;
    },
    cancel() {
      this.dialog = false;
    },
  }
}
</script>
<style lang="scss" scoped>

.new-opportunity-dialog {
  background: var(--v-background-base);
  min-height: 300px;
  padding: 25px;
  &__title, &__subtitle {
    text-align: center;
  }
  &__templates-list, &__from-scratch-contents {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  &__templates, &__templates-list {
    margin: 20px 0 0;
  }
  &__from-scratch {
    margin-bottom: 20px;
  }

}

.template {
  cursor: pointer;
  margin: 40px 20px;
  background: white;
  width: 200px;
  min-height: 150px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  text-align: center;
  transition: 0.3s;
  position: relative;
  color: var(--v-text-base);
  text-decoration: none;

  &:hover {
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.05);
  }


  &__container {
    overflow: hidden;
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    bottom: 15px;
  }

  &__preview {
    position: relative;
    width: 80px;
    height: 80px;
    background: #e7e7e7;
    border-radius: 50%;
    border: 5px solid white;
    display: inline-block;
    margin-top: -40px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
    background-size: cover;
    background-position: center;

    &::v-deep {
      .avatar {
        position: absolute;
        left: 0;
        right: 0;
        width: 70px;
        height: 70px;
        font-size: 24px;
      }
    }
  }
  &__title {
    font-weight: bold;
    font-size: 14px;
    margin-top: 10px;
    line-height: 1.2;
    padding: 0 15px;
    height: 32px;
    overflow: hidden;
  }

  &__description {
    font-size: 12px;
    // text-align: left;
    padding: 15px;
    line-height: 1.2;
    color: #a0a0a0;
    transition: 0.3s;
    min-height: 58px;
    overflow: hidden;
  }
}
</style>