<template>
  <div>
    <div v-if="log">
      <h2 class="title">{{ log.who.full_name }}: {{ log.what }}</h2>
      <div class="subtitle">
        <div>
          {{
            log.created_at
              | moment(
                "timezone",
                currentUser.active_project.timezone,
                "MM/DD/YY h:mm A"
              )
          }}
        </div>
      </div>
      <div>
        <b>Message:</b> <u>{{ log.message }}</u>
      </div>
      <div>
        <b>Endpoint:</b> <u>{{ log.url }}</u>
      </div>
      <div v-if="log.resource">
        <b>{{ log.resource.class_name.replace("Widget::", "") }}:&nbsp;</b>
        <router-link :to="to">{{ linkTitle }}</router-link>
      </div>
      <br />
      <br />
      <!-- <div class="log-description"></div> -->
      <ListPanels loaded>
        <ListPanel title="Changes [from, to]">
          <JsonViewer v-model="log.current_changes" />
        </ListPanel>
        <ListPanel title="Params">
          <JsonViewer v-model="log.params" />
        </ListPanel>
      </ListPanels>
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
export default {
  data() {
    return {
      log: null,
    };
  },
  created() {
    this.setLog();
  },
  methods: {
    async setLog() {
      const { data } = await this.$api.Logs.get({ id: this.$route.params.id });
      this.log = data;
    },
  },
  computed: {
    to() {
      let scope = {
        Theme: "themes",
        Page: "pages",
        Event: "events",
        Project: "projects",
        "Widget::ContentSection": "widgets/content-sections",
        "Widget::ContentBlock": "widgets/content-sections/uuid/content-blocks",
        "Widget::TextBlock": "widgets/text-blocks",
        "Widget::List": "widgets/lists",
        "Widget::SubmissionContent": "widgets/submission-contents",
        "Widget::Submission": "widgets/submission-forms/uuid/submissions",
        "Widget::SubmissionForm": "widgets/submission-forms",
        "Widget::Schedule": "widgets/schedules",
        "Widget::ScheduleItem": "widgets/schedules/uuid/items",
        "Widget::Countdown": "widgets/countdowns",
        "Widget::Notification": "widgets/notifications",
        "Widget::HtmlBlock": "widgets/html-blocks",
        User: "users/participants",
        Role: "users/participants/roles",
        Chat: "chats",
        SupportForm: "support",
        SupportTicket: "support/uuid/tickets",
      }[this.log.resource.class_name];
      return `/${scope}/${this.log?.resource?.id}/edit`;
    },
    linkTitle() {
      if (["User", "Admin"].includes(this.log.resource.class_name)) {
        return `${this.log.first_name} ${this.log.last_name}`;
      }
      return this.log.resource.title || this.log.resource.name;
    },
  },
};
</script>

<style scoped lang="scss">
.log-description {
  padding: 25px;
  background: white;
  margin: 35px 0;
  border: 1px solid #e7e7e7;
}
</style>
