<template>
  <div>
    <div class="page-title">New Schedule Item</div>
    <Form
      v-if="form"
      v-model="form"
      :submit="create"
      btnText="Add Schedule Item"
    />
    <Spinner v-else />
  </div>
</template>

<script>
import Form from "./_form.schedule-items.page";
export default {
  components: { Form },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async setForm() {
      const { data } = await this.$api.ScheduleItem.new({
        schedule_id: this.$route.params.scheduleId,
      });
      this.form = data;
    },
    async create() {
      await this.$api.ScheduleItem.create(this.form);
      this.$router.push("/widgets/schedules");
    },
  },
};
</script>

<style></style>
