<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="list"
      item-key="id"
      show-expand
      class="elevation-0 data-table"
    >
      <template v-slot:body="{ items }">
        <tbody v-for="item in items" :key="item.id">
          <tr class="data-table__row">
            <td />

            <td>
              {{ item.badge_type }}
            </td>
            <td>
              {{ item.title }}
            </td>
            <td>
              {{ item.description }}
            </td>

            <td class="text-center nowrap">
              <Actions
                hide-duplicate
                :path="`/badges/${item.id}`"
                @destroy="$emit('destroy', item)"
              >
              </Actions>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Type",
          align: "start",
          value: "email",
        },
        {
          text: "Title",
          align: "start",
          value: "name",
        },
        {
          text: "Description",
          align: "start",
          value: "name",
          sortable: false,
        },
        {
          text: "Actions",
          align: "center",
          sortable: false,
        },
      ];
    },
  },
};
</script>

<style></style>
