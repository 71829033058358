import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import auth from "./modules/auth";
import user from "./modules/user";
import application from "./modules/application";
import datasources from "./modules/datasources";

export default new Vuex.Store({
  modules: {
    application,
    auth,
    user,
    datasources,
  },
});
