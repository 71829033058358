<template>
  <div class="export-sort">
    <div class="page-title" v-if="form">{{ form.title }}</div>
    <div class="page-subtitle">Submission Export Sort</div>
    <div>
      <p>
        By default, submissions csv exports include a timestamp, submitter
        details, published status, and then the submission fields in
        alphabetical order by label. Use this page to customize the field
        columns.
      </p>
    </div>
    <div v-if="form && form.submission_form">
      <ListPanel
        class="export-sort__item"
        :class="{ 'export-sort__item--hidden': item.export_hide }"
        v-for="(item, idx) in computedList"
        :key="item.uuid"
        hideDestroy
        hideShow
        hideEdit
        hideDuplicate
        hideExpand
      >
        <template #title>
          <span class="export-sort__type-label">{{ item.type }}</span>
          <v-text-field
            :placeholder="item.short_name || item.label"
            v-model="item.short_name"
            class="export-sort__name"
            hide-details
            single-line
          />
        </template>
        <template #addAction>
          <v-btn icon @click.stop="hideToggle(idx)">
            <v-icon size="24">
              {{ item.export_hide ? "$eye_off" : "$eye" }}
            </v-icon>
          </v-btn>
          <v-btn icon @click.stop="moveUp(idx)" :disabled="idx == 0">
            <v-icon size="24"> $arrow_up </v-icon>
          </v-btn>
          <v-btn
            icon
            @click.stop="moveDown(idx)"
            :disabled="idx == computedList.length - 1"
          >
            <v-icon size="24"> $arrow_down </v-icon>
          </v-btn>
        </template>
      </ListPanel>

      <SubmitFooter :refs="$refs" :submit="update">Save Order</SubmitFooter>
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
import _ from "lodash";

export default {
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async setForm() {
      const { data } = await this.$api.SubmissionForm.get({
        id: this.$route.params.id,
      });
      data.submission_form
        .filter((item) => {
          return !["CustomComponent", "HtmlBlock"].includes(item.type);
        })
        .forEach((item, idx) => {
          if (!("export_position" in item)) item.export_position = idx;
          if (!("export_hide" in item)) item.export_hide = false;
        });
      this.form = data;
    },
    hideToggle(idx) {
      this.computedList[idx].export_hide = !this.computedList[idx].export_hide;
    },
    moveUp(idx) {
      const thisItem = this.computedList[idx];
      const otherItem = this.form.submission_form.find(
        (item) => item.export_position == idx - 1
      );
      thisItem.export_position = idx - 1;
      otherItem.export_position = idx;
    },
    moveDown(idx) {
      const thisItem = this.computedList[idx];
      const otherItem = this.form.submission_form.find(
        (item) => item.export_position == idx + 1
      );
      thisItem.export_position = idx + 1;
      otherItem.export_position = idx;
    },
    async update() {
      await this.$api.SubmissionForm.update(this.form);
      this.$router.push("/widgets/submission-forms");
    },
  },
  computed: {
    computedList() {
      return this.form.submission_form
        ? _.sortBy(
            this.form.submission_form.filter((item) => {
              return !["CustomComponent", "HtmlBlock"].includes(item.type);
            }),
            "export_position"
          )
        : null;
    },
  },
};
</script>

<style lang="scss">
.export-sort {
  &__item--hidden {
    background: transparent !important;
  }
  &__name {
    padding-top: 0;
    margin-top: 0;
  }
  .submit-footer {
    background: none !important;
    border: none !important;
    padding: 20px 0 !important;
  }
  &__option {
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-bottom: 20px;
    .form-inputs {
      width: unset !important;
      padding: 0 !important;
    }
  }
  .list-panel-header__start {
    flex-grow: 1 !important;
  }
  .list-panel-title {
    width: 100%;
  }
  &__type-label {
    font-size: 70%;
  }
}
</style>
