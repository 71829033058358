<template>
  <TextArea placeholder="Label" v-model="localValue.label" :rows="1" />
</template>

<script>
import mixin from "./form-builder.mixin.js";
export default {
  extends: mixin,
};
</script>

<style></style>
