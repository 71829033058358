<template>
  <div class="forgot-password-page">
    <v-form @submit.prevent="submit" class="forgot-password-page__form">
      <div class="forgot-password-page__title">New Password</div>
      <TextField label="Password" v-model="form.password" type="password" />
      <TextField
        label="Password Confirmation"
        v-model="form.password_confirmation"
        type="password"
      />
      <v-btn type="submit" block color="primary" elevation="0" rounded large>
        Submit
      </v-btn>
    </v-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      token: this.$route.params.token,
    };
  },
  created() {
    this.checkPasswordToken();
  },
  methods: {
    async checkPasswordToken() {
      const { data } = await this.$api.Auth.checkPasswordToken({
        token: this.token,
      });
      if (!data) {
        this.$alert("Token not found", "error");
        this.$router.push("/");
      }
    },
    async submit() {
      const { data } = await this.$api.Auth.setPassword({
        ...this.form,
        token: this.token,
      });
      this.$store.commit("auth/setToken", data.token);
      this.$store.dispatch("user/fetch");
      this.$router.push("/").catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
.forgot-password-page {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;

  &__title {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    padding-bottom: 15px;
  }

  &__form {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    background: white;
    padding: 30px;
    max-width: 400px;
    width: 100%;

    &::v-deep {
      .text-field {
        margin-bottom: 20px;
        width: 100%;
      }
    }
  }
}
</style>
