<template>
  <div>
    <Banner theme="green">
      <template v-slot:title> People Lists </template>
      <template v-slot:description>
        Lists allow you to create and edit running catalogs based on the data
        input. Think people lists, sponsor lists, etc.
      </template>
      <template v-slot:image>
        <img src="@/assets/banners/widgets/lists.svg" alt="People Lists" >
      </template>
    </Banner>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="New List"
      btn-to="/widgets/lists/new"
      :sort-by.sync="sortBy"
      search-placeholder="Search Lists"
      :search-text.sync="searchText"
    />

    <ListPanels :loaded="!!list">
      <div v-if="computedList && computedList.length">
        <ListPanel
          v-for="section in computedList"
          :key="section.id"
          :action-path="`/widgets/lists/${section.id}`"
          :title="section.title"
          add-btn-text="Add entry"
          :add-btn-link="`/widgets/lists/${section.id}/entries/new`"
          @destroy="destroyList(section)"
          @duplicate="duplicateList(section)"
        >
          <ListsItemsDataTable :entity="section" />
        </ListPanel>
      </div>
      <div v-else class="no-entries">No Lists</div>
    </ListPanels>
  </div>
</template>

<script>
import IndexPage from '../index-page';
import ListsItemsDataTable from '@/components/dataview/lists-entries.datatable';

export default {
  name: 'WidgetsListsPage',
  components: { ListsItemsDataTable },
  extends: IndexPage,
  methods: {
    async fetchList() {
      const { data } = await this.$api.List.get({
        sort_by: this.sortBy,
      });
      this.list = data;
    },
    async destroyList(section) {
      const confirmed = await this.$confirm({ title: 'Are you sure?' });
      if (!confirmed) return;
      await this.$api.List.destroy({ id: section.id });
      this.list = this.list.filter((t) => t.id != section.id);
    },
    async duplicateList(section) {
      await this.$api.List.duplicate(section);
      this.fetchList();
    },
  },
};
</script>

<style scoped lang="scss"></style>
