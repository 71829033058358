<template>
  <v-navigation-drawer
    class="sidebar"
    floating
    width="290"
    clipped
    :value="value"
    app
    :permanent="$size == 'xl' || $size == 'lg'"
    @input="$emit('input', $event)"
  >
    <v-list dense>
      <SidebarItems title="Global" :items="generalItems" />
      <ProjectSelector />
      <SidebarItems class="contextual-menu" :items="frontendItems" />
      <SidebarItems
        title="Developer"
        class="contextual-menu"
        :items="techItems"
      />
    </v-list>
    <EnvSelector />
  </v-navigation-drawer>
</template>

<script>
import SidebarItems from "./SidebarItems";

export default {
  name: "SideBar",
  components: { SidebarItems },
  props: {
    value: Boolean,
  },
  data() {
    return {
      activeGroup: 0,
    };
  },
  computed: {
    generalItems() {
      const items = [
        {
          icon: "view_module",
          text: "Projects",
          to: "/internal_projects",
          opened: true,
          tooltip: "This takes you to the list of Projects.",
        },
        {
          icon: "view_module",
          text: "Opportunities",
          to: "/projects",
          opened: true,
          tooltip: "This takes you to the list of Opportunities.",
        },
        {
          icon: "view_module",
          text: "Reports",
          to: "/reports",
          opened: true,
          tooltip: "This takes you to the list of reports.",
        },
        {
          icon: "account",
          text: "Creators",
          to: "/users/participants",
          tooltip: "Creators are all your participants on the live site.",
        },
        {
          icon: "label",
          text: "Badges",
          to: "/badges",
          tooltip:
            "Badges are labels to mark creators with achievement, roles, etc.",
        },
        {
          icon: "specialties",
          text: "Specialties",
          to: "/specialties",
          tooltip: "Specialties",
        },
        {
          icon: "school",
          text: "Schools",
          to: "/schools",
          tooltip:
            "Schools are used to offer autocomplete suggestions to a creator filling out their profile.",
        },
      ];

      if (
        this.currentUser?.active_team_role == "super_admin" &&
        this.currentUser?.active_project?.is_root
      ) {
        items.push({
          icon: "list_bulleted",
          text: "Registration Form",
          to: "/widgets/registration-forms",
          tooltip:
            "The global registration form is managed here. Registration forms are custom forms for user sign ups, where you can add custom input fields beyond email and password.",
        });
      }
      items.push({
        icon: "help_circle",
        text: "Site Support",
        to: "/support",
        tooltip:
          "Here you can create and view Site Support that guests use to ask questions or get in touch with a Community admin.",
      });
      items.push({
        icon: "view_module",
        text: "Notifications",
        to: "/profile_notifications",
      });
      return items;
    },
    frontendItems() {
      const items = [
        {
          icon: "chart_histogram",
          text: "Dashboard",
          to: "/",
          tooltip: `This is your home base. It displays an overview of opportunity submissions, unique users, site views, and more. It also shows a preview of your main “timeline.”`,
        },
        {
          icon: "cog",
          text: "Settings",
          to: `/projects/${this.currentUser.active_project.id}/edit`,
          tooltip: `General site and info details for your Opportunity.`,
        },
      ];

      if (!this.currentUser?.active_project?.is_profile) {
        items.push({
          icon: "pencil",
          text: "Theme",
          to: "/themes",
          tooltip: `Themes allow you to change the appearance of your Opportunity Page(s). Colors, fonts, default images, and more can be customized here.`,
        });
      }

      items.push({
        icon: "file",
        text: "Pages",
        to: "/pages",
        tooltip: `Every Opportunity needs at least one Page. Pages are where any content lives for a given Opportunity, and it’s where you should start when building your Opportunity. For multi-page opportunities, the navigation resides in the opportunity's Sidebar.`,
      });

      items.push({
        icon: "list_bulleted",
        text: this.currentUser?.active_project?.is_profile
          ? "Retrospective Survey"
          : "Submission Forms",
        to: "/widgets/submission-forms",
        tooltip: this.currentUser?.active_project?.is_profile
          ? "This is where you'll find the Retrospective Survey that a creator fills out after completing a hire request."
          : "Submission Forms are the core tool used to collect Submission Data from Community members. They allow users to submit text, images, links, and more. You can then curate, display, and accept submissions as you see fit.",
      });

      items.push({
        icon: "eye",
        text: "FAQs",
        to: "/widgets/faqs",
        tooltip: "FAQs are lists of frequently-asked questions.",
      });

      if (!this.currentUser?.active_project?.is_profile) {
        items.push({
          icon: "calendar",
          text: "Timelines",
          to: "/widgets/timeline-lists",
          tooltip:
            "Timelines are lists of significant events with dates for this opportunity.",
        });
        items.push({
          icon: "grid",
          text: "Text Blocks",
          to: "/widgets/text-blocks",
          tooltip:
            "Text Blocks are big, graphic buttons. Each has a headline and description, but can also be paired with a unique background image. These can be links. One layout supports 4 small images, for partner logos or similar uses.",
        });
      }

      items.push({
        icon: "xml",
        text: "HTML Blocks",
        to: "/widgets/html-blocks",
        tooltip:
          "HTML Blocks allow you to create custom HTML-based widgets for display on the site.",
      });

      if (
        this.currentUser?.active_team_role == "super_admin" &&
        this.currentUser?.active_project?.is_root
      ) {
        items.push({
          icon: "file",
          text: "Content Sections",
          to: "/widgets/content-sections",
          tooltip:
            "Home page only: Content Sections are rows of thumbnails (content blocks) that can be used to house pre-recorded video content, downloads, popups w/ imagery & copy, or external links.",
        });
      }

      return items;
    },
    techItems() {
      const items = [
        {
          icon: "math_log",
          text: "Admin Logs",
          to: "/logs",
          tooltip:
            "With Admin Logs you can watch on admin's interactions in current project.",
        },
        {
          icon: "dev_to",
          text: "Developer Tools",
          to: "/dev-tools",
          children: [
            {
              text: "Custom Components",
              to: "/dev-tools/custom-components",
            },
            {
              text: "Image Library",
              to: "/dev-tools/image-library",
              tooltip: `The Image Library is where you can upload images that can be referenced when writing code for custom components, CSS styles, and HTML blocks.`,
            },
          ],
        },
      ];

      return items;
    },
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  border-right: 2px solid #dcdce0;

  &::v-deep {
    &.v-navigation-drawer {
      top: 83px !important;
      @media screen and (max-width: 1263px) {
        top: 0 !important;
      }
    }
    .v-navigation-drawer__content {
      padding: 20px 0 0 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .v-list {
    padding: 0 !important;
  }

  .contextual-menu {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 1263px) {
  .sidebar {
    &::v-deep .v-navigation-drawer__content {
      padding-top: 20px;
    }
  }
}

.v-navigation-drawer {
  top: env(safe-area-inset-top) !important;
  left: env(safe-area-inset-left) !important;
}
</style>
