<template>
  <div>
    <div class="page-title">New Specialty Form</div>
    <Form btnText="Add Specialty" v-model="form" :submit="create" />
  </div>
</template>

<script>
import Form from "./_form.specialties.page";
export default {
  components: { Form },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async setForm() {
      const { data } = await this.$api.Specialty.new();
      this.form = data;
    },
    async create() {
      await this.$api.Specialty.create(this.form);
      this.$router.push("/specialties");
    },
  },
};
</script>

<style></style>
