<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Support Form Info">
        <FormRow
          required
          label="Title"
          tooltip="This is the title of the Support Form as it will be displayed on the Admin side of your site."
        >
          <TextField placeholder="Title" v-model="form.title"></TextField>
        </FormRow>
        <FormRow
          label="Headline"
          tooltip="This is the headline text as it will be displayed on the front-end of your site."
        >
          <TextField placeholder="Headline" v-model="form.headline"></TextField>
        </FormRow>
        <FormRow label="Description">
          <template #tooltip>
            Add any finer detail to be displayed on the front-end of your site
            under the "Headline."
          </template>
          <TextArea
            placeholder="Description"
            v-model="form.description"
          ></TextArea>
        </FormRow>
        <FormRow label="Submission Button Text">
          <template #tooltip>
            This is the text for the submit button. The default is "Submit".
          </template>
          <TextField v-model="form.btn_text"></TextField>
        </FormRow>
        <FormRow label="Populate From User">
          <template #tooltip>
            Check this to pre-populate certain fields (first name, last name, email) with a logged-in user’s info, if available.
          </template>
          <Checkbox v-model="form.populate_from_user"></Checkbox>
        </FormRow>
      </FormPanel>
      <FormPanel required label="Support Form Fields">
        <FormBuilder v-model="form.support_form" />
      </FormPanel>
      <FormPanel label="Support Form Details">
        <FormRow
          label="Support Email Fwd'ing"
          tooltip="Enter an email address for Site Support emails to to be sent."
        >
          <TextField
            placeholder="Support Email Fwd'ing"
            v-model="form.email_to"
          ></TextField>
        </FormRow>
        <FormRow
          label="Display Page(s)"
          tooltip="Select the page or pages on which you'd like this Schedule to appear."
        >
          <PagesSelector multiple v-model="form.page_ids"></PagesSelector>
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../_extends/form.extends.js";

export default {
  extends: mixin,
};
</script>

<style></style>
