<template>
  <div class="page-datacard">
    <div class="page-datacard__container">
      <div class="page-datacard__body">
        <div class="page-datacard__title">
          {{ page.title }}
        </div>
      </div>

      <Actions
        hide-duplicate
        :hide-destroy="hideDestroy"
        class="page-datacard__actions"
        :path="`/pages/${page.id}`"
        @destroy="$emit('destroy', page)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideDestroy: Boolean,
    page: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped lang="scss">
.page-datacard {
  min-height: 165px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  a:hover {
    text-decoration: none;
  }

  &::before {
    content: '';
    background: var(--v-border-base);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.5;
  }

  &__container {
    position: relative;
    padding: 20px 20px 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-size: cover;
    background-position: center;

    &--dark:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.405);
    }
  }
  &__container--dark &__title,
  &__container--dark &__body,
  &__container--dark a {
    color: white;
  }

  &__container--dark::v-deep .v-icon__svg {
    fill: white;
  }

  &__body {
    position: relative;
  }

  &__title {
    font-weight: 900;
    font-size: 20px;
  }

  &__description {
    font-size: 12px;
  }

  &__actions {
    margin-left: -5px;
  }
}
</style>
