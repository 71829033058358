<template>
  <div>
    <div class="page-title">Notifications</div>
    <Banner theme="green">
      <template v-slot:title> About this feature </template>
      <template v-slot:description>
        Here you can create notifications for all creators, targetted groups, or individuals. These notifications appear in their Dashboard's Notification Center.
      </template>
      <template v-slot:image>
        <img
          src="@/assets/banners/widgets/notifications.svg"
          alt="Notifications"
        />
      </template>
    </Banner>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="New Notification"
      btn-to="/profile_notifications/new"
      :sort-by.sync="sortBy"
      search-placeholder="Search Notifications"
      :search-text.sync="searchText"
    />

    <div class="page-subtitle">Notifications</div>
    <div v-if="list">
      <div v-if="computedList.length">
        <ProfileNotificationDataTable :list="computedList" @destroy="destroy" />
      </div>
      <div v-else class="no-entries">No Notifications</div>
    </div>
    <Spinner v-else relative />

  </div>
</template>

<script>
import IndexPage from "./index-page";
import ProfileNotificationDataTable from "@/components/dataview/profile_notification.datatable";

export default {
  name: "ProfileNotificationsPage",
  components: {
    ProfileNotificationDataTable,
  },
  extends: IndexPage,
  methods: {
    async fetchList() {
      const { data } = await this.$api.ProfileNotification.get({
        sort_by: this.sortBy,
      });
      this.list = data;
    },
    async destroy({ id }) {
      const confirmed = await this.$confirm({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.ProfileNotification.destroy({ id });
      this.list = this.list.filter((t) => t.id != id);
    },
  },
};
</script>
