<template>
  <div>
    <Spinner v-if="!form.team" />
    <div v-else>
      <TeamForm ref="form" v-model="form.team" />
      <div class="onboarding__form-footer">
        <OnboardingBackBtn @submit="$emit('backStep')" />
        <OnboardingNextBtn @submit="nextStep" />
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../_extends/form.extends.js";
import TeamForm from "../teams/_form.teams.page";

export default {
  extends: mixin,
  components: { TeamForm },
  created() {
    this.initTeam();
  },
  methods: {
    nextStep() {
      const { form } = this.$refs.form.$refs;
      if (form.validate()) {
        this.$emit("nextStep");
      } else {
        this.scrollToInvalidInput(form.inputs);
      }
    },
    async initTeam() {
      if (!this.form.team) {
        const { data } = await this.$api.Team.new();
        this.form = { ...this.form, team: data };
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
