<template>
  <div class="form-builder">
    <div class="form-builder__container">
      <div
        class="form-builder__row"
        :key="row.uuid"
        v-for="(row, idx) in localValue"
      >
        <div class="form-builder__component">
          <component
            class="form-builder__component-input"
            v-model="localValue[idx]"
            :is="`FormBuilder${row.type}`"
          />
          <div
            class="form-builder__details"
            v-if="
              reviewOptions &&
              !['HtmlBlock', 'PasswordField', 'HiddenField'].includes(row.type)
            "
          >
            <v-text-field
              label="Short Label"
              v-model="localValue[idx].short_label"
              style="max-width: 250px"
            />
            <Checkbox
              v-if="currentUser.active_team_role == 'super_admin'"
              v-model="localValue[idx].review_preview"
              label="Review Column"
              style="white-space: pre; margin-top: 0"
            />
            <Checkbox
              v-if="currentUser.active_team_role == 'super_admin'"
              v-model="localValue[idx].judging_column"
              label="Judging Column"
              style="white-space: pre; margin-top: 0"
            />
          </div>
          <Checkbox
            v-if="formBuilderTypesObject[row.type].allow_required"
            class="form-builder__required"
            v-model="localValue[idx].required"
            label="Required"
            :disabled="localValue[idx].conditional"
          />

          <Checkbox
            class="form-builder__conditional"
            v-model="localValue[idx].conditional"
            label="Conditional"
            v-if="allowConditionals"
            :disabled="localValue[idx].required"
          />

          <Checkbox
            class="form-builder__conditional"
            v-model="localValue[idx].default"
            label="Default Value"
          />

          <div class="form-builder__conditional-details">
            <Select
              v-if="localValue[idx].conditional"
              placeholder="Conditional Field"
              :items="localValue"
              v-model="localValue[idx].conditional_uuid"
              item-value="uuid"
              item-text="label"
              hide-details
            />
            <TextField
              v-if="showConditionalTextField(idx)"
              v-model="localValue[idx].conditional_value"
              placeholder="Conditional Value(s)"
            />
            <Checkbox
              v-if="showConditionalCheckbox(idx)"
              v-model="localValue[idx].conditional_value"
              label="Conditional Value"
            />
            <ConditionalOptionsSelector
              v-if="showConditionalOptions(idx)"
              multiple
              :items="conditionalOptions(idx)"
              v-model="localValue[idx].conditional_value"
            />
            <TextField
              v-if="localValue[idx].default"
              v-model="localValue[idx].default_value"
              placeholder="Default Value(s)"
            />
          </div>
        </div>
        <div class="form-builder__col">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="form-builder__type">
                {{ formBuilderTypesObject[row.type].title }}
                <v-icon>$chevron_down</v-icon>
              </div>
            </template>
            <v-list>
              <v-list-item
                @click="row.type = item.type"
                v-for="(item, idx) in formBuilderTypes"
                :key="idx"
                >{{ item.title }}</v-list-item
              >
            </v-list>
          </v-menu>
          <br />
          <v-text-field
            v-if="currentUser.active_team_role == 'super_admin'"
            label="Dev Name"
            v-model="localValue[idx].short_name"
          />
        </div>
        <div class="form-builder__actions">
          <v-btn icon @click="moveUp(idx)" :disabled="idx == 0">
            <v-icon>$arrow_up</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="moveDown(idx)"
            :disabled="idx == localValue.length - 1"
          >
            <v-icon>$arrow_down</v-icon>
          </v-btn>
          <v-btn icon @click="deleteField(row.uuid)">
            <v-icon>$delete</v-icon>
          </v-btn>
          <!-- <v-btn icon>
            <v-icon>$dots_horizontal</v-icon>
          </v-btn> -->
        </div>
      </div>
    </div>
    <v-btn @click="addField" color="primary" elevation="0" rounded large>
      Add Another Field
    </v-btn>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { keyBy } from "lodash";
import Select from "./selects/Select.vue";
import ConditionalOptionsSelector from "./selects/ConditionalOptionsSelector.vue";

export default {
  components: { Select, ConditionalOptionsSelector },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    allowConditionals: Boolean,
    reviewOptions: Boolean,
  },
  data() {
    const formBuilderTypes = [
      { title: "Long Text", type: "TextArea", allow_required: true },
      { title: "Short Text", type: "TextField", allow_required: true },
      { title: "Email", type: "EmailField", allow_required: true },
      { title: "Link", type: "LinkField", allow_required: true },
      { title: "Number", type: "NumberField", allow_required: true },
      { title: "Media", type: "MediaField", allow_required: true },
      { title: "Checkbox", type: "Checkbox", allow_required: true },
      { title: "Radio Button", type: "Radio", allow_required: true },
      { title: "Select", type: "Select", allow_required: true },
      { title: "MultiSelect", type: "MultiSelect", allow_required: true },
      { title: "Phone Number", type: "PhoneField", allow_required: true },
      { title: "HTML", type: "HtmlBlock" },
      { title: "Password", type: "PasswordField", allow_required: true },
      { title: "Date", type: "DateField", allow_required: true },
      { title: "Hidden", type: "HiddenField" },
    ];
    const formBuilderTypesObject = keyBy(formBuilderTypes, "type");

    return {
      formBuilderTypes,
      formBuilderTypesObject,
      localValue: [...this.value],
    };
  },
  methods: {
    addField() {
      this.localValue.push({
        type: "TextField",
        label: "New Field",
        uuid: uuidv4(),
      });
    },
    moveUp(idx) {
      if (idx < 1) return;
      const element = { ...this.localValue[idx] };
      this.localValue.splice(idx, 1);
      this.localValue.splice(idx - 1, 0, element);
    },
    moveDown(idx) {
      if (idx >= this.localValue.length - 1) return;
      const element = { ...this.localValue[idx] };
      this.localValue.splice(idx, 1);
      this.localValue.splice(idx + 1, 0, element);
    },
    deleteField(uuid) {
      this.localValue = this.localValue.filter((t) => t.uuid != uuid);
    },
    conditionalField(idx) {
      if (!this.localValue[idx].conditional) return false;

      const conditionalUuid = this.localValue[idx].conditional_uuid;
      if (!conditionalUuid) return false;

      return this.localValue.find((f) => f.uuid == conditionalUuid);
    },
    showConditionalTextField(idx) {
      const conditionalField = this.conditionalField(idx);
      return (
        conditionalField &&
        !["Radio", "Checkbox"].includes(conditionalField.type)
      );
    },
    showConditionalOptions(idx) {
      const conditionalField = this.conditionalField(idx);
      return conditionalField && conditionalField.type == "Radio";
    },
    showConditionalCheckbox(idx) {
      const conditionalField = this.conditionalField(idx);
      return conditionalField && conditionalField.type == "Checkbox";
    },
    conditionalOptions(idx) {
      return this.conditionalField(idx).options;
    },
  },
  watch: {
    localValue: {
      deep: true,
      handler() {
        this.$emit("input", this.localValue);
      },
    },
    value: {
      deep: true,
      handler() {
        if (JSON.stringify(this.localValue) != JSON.stringify(this.value)) {
          this.localValue = [...this.value];
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.form-builder {
  &__row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
    border-bottom: 2px solid var(--v-border-base);
  }
  &__container {
  }
  &__required,
  &__conditional {
    margin-top: -1px;
    margin-left: 20px;
  }
  &__conditional-details {
    padding-bottom: 15px;
    margin-left: 50px;
    > div {
      margin-bottom: 8px;
    }
  }
  &__component {
    width: 100%;
    padding-right: 20px;
    max-width: calc(100% - 286px);
    &-input {
      width: 100%;
    }
  }
  &__col {
    padding: 10px 20px;
    min-width: 160px;
  }
  &__type {
    background: var(--v-border-base);
    border-radius: 100px;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    height: 34px;
    padding: 6px 15px;
    white-space: nowrap;

    &:hover,
    &[aria-expanded="true"] {
      filter: brightness(105%);
    }
  }
  &__actions {
    display: flex;
    padding: 10px 0 0 20px;
  }

  &__details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 24px;
    > div:not(:last-child) {
      margin-right: 1.25em;
    }
  }
}
</style>
