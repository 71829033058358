<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Chat Box Info">
        <FormRow
          required
          label="Title"
          tooltip="This is the title for your Chat Box as it will be displayed on the front-end of your site."
        >
          <TextField placeholder="Title" v-model="form.title"></TextField>
        </FormRow>
        <FormRow label="Chat Box Type" required>
          <RadioGroup :items="chatBoxTypes" v-model="form.chat_type" />
        </FormRow>
        <div v-if="form.chat_type == 'zoom'">
          <FormRow required label="Zoom Meeting ID">
            <TextField placeholder="Zoom Meeting ID" v-model="form.zoom_id" />
          </FormRow>
          <FormRow label="Zoom Meeting Password">
            <TextField
              placeholder="Zoom Meeting Password"
              v-model="form.zoom_password"
            />
          </FormRow>
        </div>
        <FormRow required label="Locked">
          <template #tooltip>
            Checking this box will "lock" the chat box and prevent any new chat
            posts.
          </template>
          <Checkbox v-model="form.locked" />
        </FormRow>
      </FormPanel>

      <FormPanel label="Chat Box Timing">
        <div>
          Here you can create windows in which your chat box will be "open" to
          guests.
        </div>
        <br />
        <div
          :class="{ deleted: timeframe._destroy }"
          class="timeframe"
          v-for="(timeframe, idx) in form.timeframes"
          :key="idx"
        >
          <FormRow label="Chat Box Start Time">
            <DateTimePicker
              placeholder="Select Date/Time"
              v-model="timeframe.start_at"
            ></DateTimePicker>
          </FormRow>
          <FormRow label="Chat Box End Time">
            <DateTimePicker
              placeholder="Select Date/Time"
              v-model="timeframe.end_at"
            ></DateTimePicker>
          </FormRow>
          <v-btn class="delete-timeframe" @click="deleteTimeframe(idx)" outlined
            >Delete</v-btn
          >
          <br />
          <br />
          <v-divider></v-divider>

          <div v-if="timeframe._destroy" class="timeframe-destroy-overlay">
            <div class="timeframe-deleted">
              <div>Deleted</div>
              <v-btn
                class="restore-timeframe"
                outlined
                rounded
                @click="restore(idx)"
                >Restore</v-btn
              >
            </div>
          </div>
        </div>
        <br />
        <v-btn
          outlined
          @click="addTimeframe"
          class="elevation-0"
          color="primary"
        >
          <v-icon>$plus</v-icon>
          Add "Open" Window
        </v-btn>
      </FormPanel>
      <FormPanel label="Chat Box Details">
        <FormRow label="Moderators">
          <template #tooltip>
            Here you can assign your pre-exisitng "users" as moderators on your
            site.
          </template>
          <UsersSelector multiple v-model="form.moderator_ids"></UsersSelector>
        </FormRow>
        <FormRow label="Display Page(s)">
          <template #tooltip
            >Select the page or pages on which you'd like this Schedule to
            appear.</template
          >
          <PagesSelector multiple v-model="form.page_ids"></PagesSelector>
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../_extends/form.extends.js";
export default {
  extends: mixin,
  data() {
    return {
      chatBoxTypes: [
        { value: "text", title: "Text Chat" },
        { value: "zoom", title: "Zoom Chat" },
      ],
    };
  },
  methods: {
    addTimeframe() {
      this.form.timeframes.push({});
    },
    deleteTimeframe(idx) {
      if (this.form.timeframes[idx].id) {
        this.form.timeframes[idx]._destroy = true;
      } else {
        this.form.timeframes.splice(idx, 1);
      }
      this.form = { ...this.form };
    },
    restore(idx) {
      this.form.timeframes[idx]._destroy = false;
      this.form = { ...this.form };
    },
  },
};
</script>

<style scoped lang="scss">
.timeframe {
  position: relative;

  &-destroy-overlay {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background: rgba(255, 255, 255, 0.8);
    align-items: center;
  }

  &.deleted {
    .timeframe-destroy-overlay {
      display: flex;
    }

    .delete-timeframe {
      display: none;
    }
  }
  &-deleted {
    position: relative;
    left: 200px;
    text-align: center;
    line-height: 2;
    font-size: 13px;
  }
}
</style>
