<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Notification Info">
        <FormRow
          required
          label="Title"
          tooltip="This is the header text of the Notification."
        >
          <TextField placeholder="Title" v-model="form.title"></TextField>
        </FormRow>
        <FormRow
          required
          label="Small Text"
          tooltip="This is the small text that appears above the header of the Notification."
        >
          <TextField placeholder="Small Text" v-model="form.small_text"></TextField>
        </FormRow>
        <FormRow
          label="Description"
          tooltip="This is the body text of the Notification."
        >
          <TextArea
            placeholder="Description"
            v-model="form.description"
          ></TextArea>
        </FormRow>

        <FormRow>
          <template #label>Preview Image</template>
          <Uploader v-model="form.preview" :ratio="1" enableCropper />
        </FormRow>

      </FormPanel>
      <FormPanel label="Audience">
        <FormRow
          tooltip="Check to have this notification appear for all creators."
          label="Show to all"
        >
          <Checkbox v-model="form.show_to_all" />
        </FormRow>
        <FormRow
          label="Creators"
          tooltip="Select the creator(s) you would like to recieve this notification."
          v-show="!form.show_to_all"
        >
          <UsersSelector multiple v-model="form.user_ids"></UsersSelector>
        </FormRow>
        <FormRow
          label="Badges"
          tooltip="Select badge(s) to send this notification to all creators with those badges."
          v-show="!form.show_to_all"
        >
          <BadgeSelector multiple v-model="form.badge_ids"></BadgeSelector>
        </FormRow>
        <FormRow
          label="Active"
          tooltip="Check to enable this notification."
        >
          <Checkbox v-model="form.active" />
        </FormRow>
        <FormRow
          label="Position"
          tooltip="This will determine the order that this notification appears. (Lowest first)"
        >
          <TextField
            v-model="form.position"
            :rules="[validation.integer]"
          />
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../_extends/form.extends.js";

export default {
  extends: mixin,
};
</script>

<style></style>
