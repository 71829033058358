<template>
  <div class="skills-selector">
    <div class="skills">
      <div
        class="skill"
        v-for="userSkill in visibleSkills"
        :key="userSkill.id || userSkill.uuid"
      >
        <div class="skill-title">
          <Select
            style="width: 250px"
            :label="label"
            :items="datasources.skills"
            itemText="title"
            itemValue="id"
            v-model="userSkill.skill_id"
          ></Select>
        </div>
        <div class="skill-validated">
          <v-checkbox v-model="userSkill.validated" label="iU Approved">
          </v-checkbox>
        </div>
        <div class="skill-delete">
          <v-btn
            @click="deleteSkill(userSkill)"
            outlined
            fab
            x-small
            color="primary"
          >
            <v-icon>$delete</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <v-btn
      style="width: 250px"
      color="primary"
      rounded
      outlined
      class="elevation-0 add-skill"
      large
      v-if="visibleSkills.length < 3"
      @click="addSkill"
    >
      + Add Skill
    </v-btn>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    value: [String, Number, Array],
    label: String,
  },
  data() {
    return {
      localValue: [...this.value],
    };
  },
  methods: {
    addSkill() {
      this.localValue.push({
        uuid: uuidv4(),
        skill_id: null,
        validated: false,
      });
    },
    deleteSkill(userSkill) {
      if (userSkill.id) {
        const localValue = this.localValue.map((t) => {
          if (t.id == userSkill.id) {
            t._destroy = true;
          }
          return t;
        });

        this.localValue = [...localValue];
      } else {
        this.localValue = [
          ...this.localValue.filter((t) => t.uuid != userSkill.uuid),
        ];
      }
    },
  },
  computed: {
    visibleSkills() {
      return this.localValue.filter((t) => !t._destroy);
    },
  },
  watch: {
    localValue: {
      deep: true,
      handler() {
        if (JSON.stringify(this.localValue) != JSON.stringify(this.value)) {
          this.$emit("input", [...this.localValue]);
        }
      },
    },
    value: {
      deep: true,
      handler() {
        if (JSON.stringify(this.localValue) != JSON.stringify(this.value)) {
          this.localValue = [...this.value];
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.skill {
  display: flex;
  align-items: center;

  &-title {
    margin-right: 10px;
  }

  &-delete {
    margin-left: 25px;
  }
}

.add-skill {
  margin-top: 10px;
}
</style>
