<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Specialty Form Info">
        <FormRow required label="Title">
          <TextField placeholder="Title" v-model="form.title"></TextField>
        </FormRow>
        <FormRow
          label="Unavailable for Payments"
          tooltip="For Finance. Check if this specialty should not be available for gig assignments and invoice line items."
        >
          <Checkbox v-model="form.unavailable_for_payments" />
        </FormRow>
        <FormRow
          label="Unavailable for Profiles"
          tooltip="For the People Team. Check if this specialty should not be available for primary or secondary specialties on a creator's profile. (in progress)"
        >
          <Checkbox v-model="form.unavailable_for_profiles" />
        </FormRow>
        <FormRow
          label="Report Category"
          tooltip="How should creators who chose this specialty be categorized on the reports page in admin?"
        >
          <Select v-model="form.report_category" :items="reportCategories" />
        </FormRow>
        <FormRow
          label="Team/Department Category"
          tooltip="This is the default team that a skill will be associated with on hire requests and invoices."
        >
          <QbClassSelector
            :value="form.skills_qb_classes[0]"
            placeholder="Team/Department"
            @input="updateQbClass($event)"
          />
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../_extends/form.extends.js";
import QbClassSelector from "@/components/selects/QbClassSelector";

export default {
  extends: mixin,
  components: { QbClassSelector },
  data() {
    return {
      reportCategories: [
        { name: "Creative", value: "creative" },
        { name: "Design", value: "design" },
        { name: "Events", value: "events" },
        { name: "Partners", value: "partners" },
        { name: "People", value: "people" },
        { name: "Post", value: "post" },
        { name: "Production", value: "production" },
        { name: "Strategy", value: "strategy" },
        { name: "Software Engineering", value: "software_engineering" },
        { name: "Writing", value: "writing" },
      ],
    };
  },
  methods: {
    updateQbClass(event) {
      if (!event && this.form.skills_qb_classes[0]) {
        this.form.skills_qb_classes[0]._destroy = true;
        return;
      }

      if (this.form.skills_qb_classes[0]) {
        delete this.form.skills_qb_classes[0]._destroy;
        this.form.skills_qb_classes[0].qb_class_id = event;
      } else {
        this.form.skills_qb_classes[0] = {
          qb_class_id: event,
        };
      }
    },
  },
};
</script>

<style></style>
