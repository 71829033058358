<template>
  <div>
    <Banner theme="yellow" text-color="#56565A">
      <template v-slot:title> Submission Forms </template>
      <template v-slot:description>
        Submission Forms are a tool you can use to request User Generated
        Content (UGC) and allow creators to submit photos, video, and/or
        messages. You can then curate, display, and unpublish the content as you
        see fit.
      </template>
      <template v-slot:image>
        <img
          src="@/assets/banners/widgets/user-generated-content.svg"
          alt="Submission Forms"
        />
      </template>
    </Banner>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="New Submission Form"
      btn-to="/widgets/submission-forms/new"
      :sort-by.sync="sortBy"
      search-placeholder="Search Submission Form"
      :search-text.sync="searchText"
      :only-superadmins-can-create="true"
    />

    <div class="page-subtitle">Visualization</div>
    <div v-if="submissionContentsList">
      <Stories
        :click-path="(item) => `/widgets/submission-contents/${item.id}/edit`"
        create-path="/widgets/submission-contents/new"
        :list="submissionContentsList"
        add-btn-text="New Visualizaton"
      />
    </div>

    <div class="page-subtitle">Submission Forms</div>
    <div class="submission-forms-list">
      <ListPanels :loaded="!!list">
        <div v-if="computedList && computedList.length">
          <ListPanel
            v-for="section in computedList"
            :key="section.id"
            :action-path="`/widgets/submission-forms/${section.id}`"
            :title="section.title"
            @destroy="destroySubmissionForm(section)"
            @duplicate="duplicateSubmissionForm(section)"
            :hide-destroy="currentUser.active_team_role != 'super_admin'"
            :hide-duplicate="currentUser.active_team_role != 'super_admin'"
          >
            <template
              #bottom
              v-if="currentUser.active_project.slug != 'referral'"
            >
              <SubmissionStage
                :value="section.state"
                :stagingState="section.staging_state"
                v-if="section.slug != 'retrospective_survey'"
              />
            </template>
            <template
              #addAction
              v-if="currentUser.active_project.slug != 'referral'"
            >
              <v-btn
                :to="`/widgets/submission-forms/${section.id}/submissions/new`"
                color="primary"
                elevation="0"
                rounded
                class="content-blocks__new-block-btn"
              >
                <slot name="btn">
                  <v-icon>$plus</v-icon>
                  <span class="content-blocks__new-block-btn-text">
                    Add Submission
                  </span>
                </slot>
              </v-btn>
            </template>
            <template #prependActions>
              <v-btn
                icon
                :to="`/widgets/submission-forms/${section.id}/export_sort`"
              >
                <v-icon size="24"> $table </v-icon>
              </v-btn>
            </template>

            <SubmissionsDataTable :entity="section" />
          </ListPanel>
        </div>
        <div v-else class="no-entries">No Submission Forms</div>
      </ListPanels>
    </div>
  </div>
</template>

<script>
import IndexPage from "../index-page";
import SubmissionsDataTable from "@/components/dataview/submissions.datatable";

export default {
  name: "WidgetUserSubmittedContentPage",
  components: {
    SubmissionsDataTable,
  },
  extends: IndexPage,
  data() {
    return {
      submissionContentsList: [],
    };
  },
  created() {
    this.fetchContentList();
  },
  methods: {
    async fetchContentList() {
      const { data } = await this.$api.SubmissionContent.get();
      this.submissionContentsList = data;
    },
    async fetchList() {
      const { data } = await this.$api.SubmissionForm.get({
        sort_by: this.sortBy,
      });
      this.list = data;
    },
    async destroySubmissionForm(submissionForm) {
      const confirmed = await this.$confirm({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.SubmissionForm.destroy({ id: submissionForm.id });
      this.list = this.list.filter((t) => t.id != submissionForm.id);
    },
    async duplicateSubmissionForm(submissionForm) {
      await this.$api.SubmissionForm.duplicate({ id: submissionForm.id });
      this.fetchList();
      this.fetchContentList();
    },
  },
};
</script>

<style scoped lang="scss">
.submission-forms-list {
  &::v-deep {
    .list-panel-header.v-expansion-panel-header--active {
      background: #ececec;
      border-bottom: 1px solid #dcdce0;
    }
  }
}

@media screen and (max-width: 960px) {
  .submission-forms-list {
    &::v-deep {
      .list-panel-header {
        flex-direction: column;
      }
      .list-panel-header__start {
        min-width: 100%;
      }
      .submission-stage {
        margin-bottom: 40px;
      }
      .panel-header__end {
        justify-content: space-between;
        min-width: 100%;
      }
    }
  }
}
</style>
