<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Timeline Info">
        <FormRow required label="Timeline Name">
          <TextField
            :rules="[validation.required]"
            placeholder="Timeline Name"
            v-model="form.title"
            :rows="3"
          />
        </FormRow>
        <FormRow
          label="Dot Color"
          tooltip="The color of the dots on the timeline will be the color entered here. If this field is empty, the dots will be the same color as the rest of the text in this section."
        >
          <ColorPicker
            v-model="form.settings.dot_color"
          />
        </FormRow>
        <FormRow required label="Timeline Events">
          <div
            class="timeline-list__item"
            v-for="(item, idx) in form.settings.items"
            :key="item.uuid"
          >
            <div class="timeline-list__controls">
              <v-btn
                class="timeline-list__close"
                icon
                @click="destroyTimelineListItem(idx)"
              >
                <v-icon>$close</v-icon>
              </v-btn>
              <v-btn
                class="timeline-list__moveup"
                icon
                @click="moveUp(idx)"
              >
                <v-icon>$arrow_up</v-icon>
              </v-btn>
              <v-btn
                class="timeline-list__movedown"
                icon
                @click="moveDown(idx)"
              >
                <v-icon>$arrow_down</v-icon>
              </v-btn>
            </div>
            <div class="timeline-list__content">
              <TextArea
                :rules="[validation.required]"
                placeholder="Date(s)"
                v-model="item.dates"
                :rows="3"
              />
              <TextArea
                :rules="[validation.required]"
                placeholder="Event Description"
                v-model="item.description"
                :rows="3"
              />
            </div>
          </div>
          <v-btn
            color="primary"
            elevation="0"
            rounded
            large
            @click="addTimelineListItem"
          >
            <slot name="btn">
              <v-icon>$plus</v-icon>
              Add Timeline Event
            </slot>
          </v-btn>
        </FormRow>

      </FormPanel>
      <FormPanel label="Timeline Details">
        <FormRow
          label="Display Page(s)"
          tooltip="Select the page or pages on which you'd like this Timeline to appear."
        >
          <PagesSelector multiple v-model="form.page_ids"></PagesSelector>
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../../_extends/form.extends.js";
import { v4 as uuidv4 } from "uuid";

export default {
  extends: mixin,
  mounted() {
    if(!this.form.settings.dot_color) {
      this.$set(this.form.settings, "dot_color", "");
    }
  },
  methods: {
    addTimelineListItem() {
      if (!this.form.settings.items) this.$set(this.form.settings, "items", []);
      this.form.settings.items.push({
        dates: "",
        description: "",
        uuid: uuidv4(),
      });
    },
    destroyTimelineListItem(idx) {
      this.form.settings.items.splice(idx, 1);
    },
    moveUp(idx) {
      if (idx < 1) return;
      const element = { ...this.form.settings.items[idx] };
      this.form.settings.items.splice(idx, 1);
      this.form.settings.items.splice(idx - 1, 0, element);
    },
    moveDown(idx) {
      if (idx >= this.form.settings.items.length - 1) return;
      const element = { ...this.form.settings.items[idx] };
      this.form.settings.items.splice(idx, 1);
      this.form.settings.items.splice(idx + 1, 0, element);
    },
  }
};
</script>

<style lang="scss" scoped>
.timeline-list {
  &__item {
    border: thin solid rgba(0, 0, 0, 0.1);
    border-radius: 0.5em;
    margin-bottom: 1em;
    position: relative;
  }

  &__content {
    padding: 1em 2.5em 1em 1em;
    > div {
      margin-bottom: 0.5em;
    }
    .text-field {
      width: 100%;
    }
  }

  &__controls {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
  }
}
</style>