<template>
  <div>
    <div v-if="ticket">
      <div class="page-title" v-if="ticket">
        {{ ticket.title || `Ticket #${ticket.id}` }}
      </div>
      <div class="panel-box">
        <div class="panel-box__dl">
          <div class="panel-box__dr" v-for="(val, key) in ticket" :key="key">
            <div
              class="panel-box__dt"
              v-if="
                key != 'formatted_data' &&
                ![
                  'user_agent',
                  'status',
                  'response',
                  'response_sent_at',
                ].includes(key)
              "
            >
              {{ key }}
            </div>
            <div class="panel-box__dd">
              <a target="_blank" :href="`mailto:${val}`" v-if="key == 'email'">
                {{ val }}
              </a>
              <span v-else-if="['created_at', 'updated_at'].includes(key)">{{
                val
                  | moment(
                    "timezone",
                    currentUser.active_project.timezone,
                    "MM/DD/YY h:mm A"
                  )
              }}</span>
              <div
                v-else-if="
                  ['status', 'response', 'response_sent_at'].includes(key)
                "
              ></div>
              <div v-else-if="key == 'formatted_data'">
                <div :key="index" v-for="(fd, index) in ticket.formatted_data">
                  <div class="panel-box__dt">
                    {{ fd.label }}
                  </div>
                  <a
                    v-if="previewUrl(fd)"
                    :href="previewUrl(fd)"
                    target="_blank"
                  >
                    <v-responsive
                      :aspect-ratio="4 / 3"
                      class="support-ticket-preview"
                      :style="{ backgroundImage: `url(${previewUrl(fd)})` }"
                    >
                    </v-responsive>
                  </a>
                  <a
                    :href="fd.value.url"
                    target="_blank"
                    v-else-if="fd.type == 'MediaField'"
                    >{{ fd.value.url }}</a
                  >
                  <span v-else v-html="$sanitize(fd.value)"></span>
                </div>
              </div>
              <div v-else-if="key == 'user_agent'">
                <div v-if="ticket.user_agent.os">
                  <div>
                    <div class="panel-box__dt">OS</div>
                    <span>{{
                      `${ticket.user_agent.os} ${
                        ticket.user_agent.os_version
                          ? ticket.user_agent.os_version
                          : " (See User agent for OS version)"
                      }`
                    }}</span>
                  </div>
                  <div>
                    <div class="panel-box__dt">Browser</div>
                    <span>{{
                      `${ticket.user_agent.vendor} ${ticket.user_agent.name} v${
                        ticket.user_agent.version
                          ? ticket.user_agent.version
                          : " (See User Agent for browser version)"
                      }`
                    }}</span>
                  </div>
                </div>
                <div v-if="ticket.user_agent.userAgent">
                  <div class="panel-box__dt">User Agent</div>
                  <span>{{ ticket.user_agent.userAgent }}</span>
                </div>
              </div>
              <span v-else>{{ val }}</span>
            </div>
          </div>
          <div class="support-ticket__response">
            <div class="support-ticket__status">
              <div class="panel-box__dt">Status</div>
              <Select
                :items="statuses"
                v-model="ticket.status"
                item-value="value"
                item-text="text"
              />
            </div>
            <div>
              <div class="panel-box__dt">Response</div>
              <TextArea
                placeholder="Response"
                v-model="ticket.response"
                :disabled="ticket.status == 'closed'"
              ></TextArea>
            </div>

            <div v-if="ticket.response_sent_at">
              <span class="panel-box__dt">Response sent at: </span>
              <span>{{
                ticket.response_sent_at
                  | moment(
                    "timezone",
                    currentUser.active_project.timezone,
                    "MM/DD/YY h:mm A"
                  )
              }}</span>
            </div>
            <div class="support-ticket__response-footer">
              <v-btn
                outlined
                color="primary"
                elevation="0"
                rounded
                large
                @click="update"
              >
                Update
              </v-btn>
              <v-btn
                color="primary"
                elevation="0"
                rounded
                large
                @click="updateAndSend"
                :disabled="ticket.status == 'closed'"
              >
                Send Response and Close
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="suggestContextChange">
      This ticket is from {{ otherContext }}, but you're currently working in
      {{ currentUser.active_project_env }}. Would you like to
      <a @click="switchContexts">move to {{ otherContext }}</a
      >?
    </div>
    <div v-else-if="getInContextError">
      {{ getInContextError }}
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
export default {
  data() {
    return {
      ticket: null,
      statuses: [
        { text: "New", value: "new" },
        { text: "In Progress", value: "in_progress" },
        { text: "Resolved", value: "closed" },
      ],
      suggestContextChange: false,
      getInContextError: null,
    };
  },
  created() {
    this.setTicket();
  },
  methods: {
    async setTicket() {
      const { data } = await this.$api.SupportTicket.getInContext({
        id: this.$route.params.id,
      });
      if (data.suggest_context) this.suggestContextChange = true;
      else if (data.error) this.getInContextError = data.error;
      else this.ticket = data;
    },
    async submit(params) {
      try {
        const response = await this.$api.SupportTicket.update(params);
        if (response.status == 200) {
          this.ticket = response.data;
          this.$alert("Support Ticket updated successfully");
        }
      } catch (e) {
        this.$alert("Something went wrong", "error");
      }
    },
    async update() {
      const params = {
        id: this.$route.params.id,
        status: this.ticket.status,
        response: this.ticket.response,
      };
      this.submit(params);
    },
    async updateAndSend() {
      this.ticket.status = "closed";
      const params = {
        id: this.$route.params.id,
        status: this.ticket.status,
        response: this.ticket.response,
        send_response_and_close: true,
      };
      this.submit(params);
    },
    previewUrl(fd) {
      return fd?.value?.preview?.large?.url;
    },
    async switchContexts() {
      this.$root.$emit("updateEnv", this.otherContext);
    },
  },
  computed: {
    otherContext() {
      return this.currentUser.active_project_env == "production"
        ? "staging"
        : "production";
    },
  },
};
</script>

<style scoped lang="scss">
.statuses-select::v-deep .dropdown-selected {
  width: 270px;
}
.support-ticket {
  &-preview {
    width: 100%;
    max-width: 500px;
    background-size: contain;
  }
  &__response {
    margin-top: 2em;
    padding-top: 2em;
    border-top: thin solid var(--v-border-base);
    & > div:not(:last-child) {
      margin-bottom: 1em;
    }
  }
  &__status {
    max-width: 250px;
  }

  &__response-footer {
    display: flex;
    justify-content: flex-end;
    > .v-btn:not(:last-child) {
      margin-right: 1em;
    }
  }
}
</style>
