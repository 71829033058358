<template>
  <div>
    <div class="page-title">New Html Block</div>
    <Form v-model="form" :submit="create" btnText="Add HTML Block" />
  </div>
</template>

<script>
import Form from "./_form.html-blocks.page";
export default {
  components: { Form },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async setForm() {
      const { data } = await this.$api.HtmlBlock.new();
      this.form = data;
    },
    async create() {
      await this.$api.HtmlBlock.create(this.form);
      this.$router.push("/widgets/html-blocks");
    },
  },
};
</script>

<style></style>
