<template>
  <div>
    <div v-if="['super_admin', 'admin'].includes(currentUser.active_team_role)">
      <div class="page-title">Edit Opportunity</div>
      <Form
        v-if="form"
        v-model="form"
        :submit="update"
        btnText="Update Opportunity"
      />
      <Spinner v-else />
    </div>
    <div v-else>
      <div class="page-title">Opportunity</div>
      <div>
        You don't have access to the project settings. Please, contact your
        admin to get more details.
      </div>
    </div>
    <v-dialog
      @click:outside="emailConfirmDialog = false"
      max-width="1000"
      v-model="emailConfirmDialog"
    >
      <v-card>
        <v-btn class="dialog-close" icon @click="emailConfirmDialog = false">
          <v-icon>$close</v-icon>
        </v-btn>
        <v-card-title class="dialog-title">
          {{ confirmEmailHeading }}
        </v-card-title>
        <v-card-text>
          <p
            v-for="(msg, idx) in confirmEmailMessages"
            :key="idx"
            v-html="msg"
          ></p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn style="margin-right: 15px" text @click="emailConfirmDialog = false"> Cancel </v-btn>

          <v-btn color="primary" @click="confirmEmail">
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Form from "./_form.projects.page";
export default {
  components: { Form },
  data() {
    return {
      form: null,
      emailConfirmDialog: false,
      confirmEmailHeading: "",
      confirmEmailMessages: "",
    };
  },
  created() {
    this.checkActiveProject();
    this.setForm();
  },
  methods: {
    async checkActiveProject() {
      if(this.$route.params.id != this.currentUser?.active_project?.id) {
        await this.$api.Project.makeActive({ id: this.$route.params.id });
        this.$root.$emit("fetchEnv");
      }
    },
    async confirmEmail() {
      this.form.confirm_send_emails = true;
      this.emailConfirmDialog = false;
      this.update({});
    },
    async update({ onUploadProgress }) {
      const params = { ...this.form };
      params.admin_environment = this.currentUser.active_project_env;
      const res = await this.$api.Project.update(params, { onUploadProgress });
      const { status, data } = res;
      if(data.need_email_confirm) {
        this.confirmEmailHeading = data.heading;
        this.confirmEmailMessages = data.messages;
        this.emailConfirmDialog = true;
      } else if(status == 200) {
        this.$alert("Opportunity updated successfully.");
        this.setForm();
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    },
    async setForm() {
      const { data } = await this.$api.Project.get({
        id: this.$route.params.id,
      });
      this.form = data;
    },
  },
};
</script>

<style>
.dialog-close {
  position: absolute;
  right: 5px;
  top: 5px;
}
</style>
