<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Submission Content Info">
        <FormRow label="Title" required>
          <TextField
            :rules="[validation.required]"
            placeholder="Title"
            v-model="form.title"
          ></TextField>
        </FormRow>
        <FormRow label="Submission Form" required>
          <SubmissionFormSelector
            :rules="[validation.required]"
            v-model="form.widget_submission_form_id"
          />
        </FormRow>
      </FormPanel>
      <FormPanel
        label="Submission Preview Layout"
        v-if="form.layout[form.widget_submission_form_id]"
      >
        <SubmissionLayoutBuilder
          :mediaFields="mediaFields"
          :nonMediaFields="nonMediaFields"
          class="submission-content__preview-layout"
          v-model="form.preview_layout[form.widget_submission_form_id]"
        />
      </FormPanel>
      <FormPanel
        label="Submission Modal Layout"
        v-if="form.layout[form.widget_submission_form_id]"
      >
        <SubmissionLayoutBuilder
          :mediaFields="mediaFields"
          :nonMediaFields="nonMediaFields"
          class="submission-content__layout"
          v-model="form.layout[form.widget_submission_form_id]"
        />
      </FormPanel>

      <FormPanel label="Submission Content Details">
        <FormRow label="Display on Pages">
          <PagesSelector multiple v-model="form.page_ids"></PagesSelector>
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">
      {{ btnText }}
      <template v-if="action == 'edit'" #actions>
        <div>
          <v-btn
            @click="$emit('destroy')"
            outlined
            color="primary"
            elevation="0"
            rounded
            large
          >
            Delete Content
          </v-btn>
        </div>
      </template>
    </SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../../_extends/form.extends.js";

export default {
  extends: mixin,
  props: {
    action: String,
  },
  data() {
    return {
      submissionForm: null,
    };
  },
  methods: {
    async setLayout() {
      const { data } = await this.$api.SubmissionForm.get({
        id: this.form.widget_submission_form_id,
      });
      this.submissionForm = data;
      this.form.layout[data.id] = this.form.layout[data.id] || {};

      this.form.preview_layout[data.id] =
        this.form.preview_layout[data.id] || {};

      if (!this.form.layout[data.id].content) {
        this.form.layout[data.id].content = [];
      }

      if (!this.form.preview_layout[data.id].content) {
        this.form.preview_layout[data.id].content = [];
      }
      this.form = { ...this.form };
    },
  },
  computed: {
    mediaFields() {
      if (!this.submissionForm) return [];
      return this.submissionForm.submission_form.filter(
        (t) => t.type == "MediaField"
      );
    },
    nonMediaFields() {
      if (!this.submissionForm) return [];
      return this.submissionForm.submission_form.filter(
        (t) =>
          !["MediaField", "Checkbox", "Radio", "HtmlBlock"].includes(t.type)
      );
    },
  },
  watch: {
    "form.widget_submission_form_id": {
      immediate: true,
      handler(val) {
        if (val) {
          this.setLayout();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.submission-content {
  &__preview-layout {
    display: block !important;
    max-width: 500px;
    margin: auto;

    &::v-deep {
      .submission-content__layout-preview {
        max-width: 100%;
        min-height: 300px;
      }

      .submission-content__layout-content {
        max-width: 100%;
      }
    }
  }
}
</style>
