<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Basic Info">
        <FormRow
          required
          label="Title"
          tooltip="This is the title of the Submission Form as it will be displayed on the front-end of your site."
        >
          <TextField placeholder="Title" v-model="form.title"></TextField>
        </FormRow>
        <FormRow label="Description">
          <template #tooltip>
            Add any finer detail to be displayed on the front-end of your site
            under the "title."
          </template>
          <TextArea
            placeholder="Description"
            v-model="form.description"
          ></TextArea>
        </FormRow>
        <FormRow label="Submission Button Text">
          <template #tooltip>
            This is the text for the submit button, once the user has completed
            the form and is ready to register.
          </template>
          <TextField v-model="form.btn_text"></TextField>
        </FormRow>
        <FormRow required label="Mark Required">
          <template #tooltip>
            Checking this box will add an asterisk (*) to the end of the labels
            of required fields.
          </template>
          <Checkbox v-model="form.mark_required" />
        </FormRow>
        <FormRow required label="Show Login Form">
          <template #tooltip>
            Checking this box will initially show a login form, with an option
            to view the registration form.
          </template>
          <Checkbox v-model="form.login_form" />
        </FormRow>
        <div v-if="form.login_form">
          <FormRow required label="Sign Up CTA">
            <template #tooltip>
              This text will be shown below the login form, and above the sign
              up button.
            </template>
            <TextField v-model="form.not_registered_cta" />
          </FormRow>
          <FormRow required label="Sign Up Button Text">
            <template #tooltip>
              This is the text for the sign up button.
            </template>
            <TextField v-model="form.sign_up_btn_text" />
          </FormRow>
        </div>
      </FormPanel>

      <FormPanel required label="Custom Fields">
        <FormBuilder
          v-model="form.registration_form"
          :allow-conditionals="true"
        />
      </FormPanel>
      <FormPanel
        label="Account Claiming"
      >
        <FormRow label="Claim Account Success Message">
          <template #tooltip>
            When a creator successfully claims their account, this message will appear under the "Thank You!" header. <br/>HTML links are supported. <br/>If this is empty, a default message will appear.
          </template>
          <TextArea
            placeholder="Welcome to the iU Community! Let's get to know..."
            v-model="form.claim_success_msg"
          ></TextArea>
        </FormRow>
      </FormPanel>
      <FormPanel
        label="Form Details"
        tooltip="Select the page or pages on which you'd like this Registration Form to appear."
      >
        <FormRow label="Display Page(s)">
          <PagesSelector multiple v-model="form.page_ids"></PagesSelector>
        </FormRow>
        <FormRow required label="Destination Page">
          <PagesSelector v-model="form.destination_page_id" />
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../../_extends/form.extends.js";
export default {
  extends: mixin,
};
</script>

<style></style>
