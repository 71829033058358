<template>
  <div class="participants-show">
    <div v-if="user">
      <div class="page-title">
        {{ user.full_name }}
      </div>

      <v-tabs
        :dark="currentUser.active_project_env == 'production'"
        v-model="activeTabNum"
      >
        <v-tab v-for="tab in tabs" :key="tab.value" value="work">{{
          tab.title
        }}</v-tab>
      </v-tabs>
      <div class="tab-content">
        <EditUser v-if="activeTab == 'profile'" />
        <ParticipantsWork v-if="activeTab == 'work'" />
        <ParticipantsSubmissions v-else-if="activeTab == 'submissions'" />
        <ParticipantsReviews v-else-if="activeTab == 'reviews'" />
      </div>
    </div>

    <Spinner v-else />
  </div>
</template>

<script>
import ParticipantsWork from "./tabs/work.participants.page.vue";
import ParticipantsSubmissions from "./tabs/submissions.participants.page.vue";
import ParticipantsReviews from "./tabs/reviews.participants.page.vue";
import EditUser from "./edit.participants.page.vue";

export default {
  components: {
    ParticipantsWork,
    ParticipantsSubmissions,
    ParticipantsReviews,
    EditUser,
  },
  data() {
    return {
      activeTabNum: 0,
      tabs: [
        { value: "profile", title: "Profile" },
        { value: "work", title: "Work History" },
        { value: "submissions", title: "Submissions" },
        { value: "reviews", title: "Reviews" },
      ],
      user: null,
      referrals: null,
    };
  },
  created() {
    this.setUser();
    this.setReferrals();
  },
  mounted() {
    const { tab } = this.$route.query;
    if (tab) {
      this.activeTabNum = parseInt(tab);
    }
  },
  computed: {
    activeTab() {
      return this.tabs[this.activeTabNum].value;
    },
  },
  methods: {
    async setUser() {
      const { data } = await this.$api.User.get({ id: this.$route.params.id });
      this.user = data;
    },
    async setReferrals() {
      const { data } = await this.$api.Referral.get({
        id: this.$route.params.id,
      });
      this.referrals = data;
    },
  },
};
</script>

<style scoped lang="scss">
.tab-content {
  padding: 20px 0;
}
</style>

<style lang="scss">
.env-production {
  .participants-show {
    .tab-content {
      color: white;
    }

    .v-tabs-bar {
      background: transparent !important;
    }
  }
}
</style>
