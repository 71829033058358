<template>
  <div class="rating-categories-editor">
    <table class="rating-categories">
      <thead>
        <tr>
          <th>Title</th>
          <th>Points</th>
          <th style="white-space: nowrap; padding-right: 1em">Allow zero?</th>
          <th class="rating-categories__th--percent">%</th>
          <th class="rating-categories__th--controls"></th>
        </tr>
      </thead>
      <tbody v-if="localValue && localValue.length">
        <tr
          class="rating-category"
          v-for="(item, idx) in localValue"
          :key="item.uuid"
        >
          <td colspan="1" class="rating-category__field">
            <TextField
              placeholder="Title"
              v-model="item.title"
              :rules="[validation.required]"
            ></TextField>
          </td>
          <td class="rating-category__field">
            <TextField
              placeholder="Points"
              v-model="item.points"
              type="number"
              :rules="[validation.required]"
            ></TextField>
          </td>
          <td class="rating-category__field--centered">
            <Checkbox v-model="item.allow_zero" />
          </td>
          <td class="rating-category__field rating-category__field--percent">
            <div>{{ percentage(idx) }}</div>
          </td>
          <td class="rating-category__controls">
            <v-btn
              class="rating-category__moveup"
              icon
              @click="moveUp(idx)"
              :disabled="value.length <= 1"
            >
              <v-icon>$arrow_up</v-icon>
            </v-btn>
            <v-btn
              class="rating-category__movedown"
              icon
              @click="moveDown(idx)"
              :disabled="value.length <= 1"
            >
              <v-icon>$arrow_down</v-icon>
            </v-btn>
            <v-btn class="rating-category__close" icon @click="destroy(idx)">
              <v-icon>$close</v-icon>
            </v-btn>
          </td>
        </tr>
        <tr>
          <td></td>
          <td class="rating-categories__total">Total: {{ pointsSum }}</td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>

    <v-btn color="primary" elevation="0" rounded large @click="addCategory">
      <slot name="btn">
        <v-icon>$plus</v-icon>
        Add category
      </slot>
    </v-btn>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localValue: [...this.value],
    };
  },
  watch: {
    localValue: {
      deep: true,
      handler() {
        if (JSON.stringify(this.localValue) != JSON.stringify(this.value)) {
          this.$emit("input", [...this.localValue]);
        }
      },
    },
    value: {
      deep: true,
      handler() {
        this.localValue = [...this.value];
      },
    },
  },
  methods: {
    addCategory() {
      if (!this.localValue) this.localValue = [];
      this.localValue.push({
        title: "",
        points: 5,
        uuid: uuidv4(),
      });
      this.localValue = [...this.localValue];
    },
    moveUp(idx) {
      if (idx < 1) return;
      const element = { ...this.localValue[idx] };
      this.localValue.splice(idx, 1);
      this.localValue.splice(idx - 1, 0, element);
    },
    moveDown(idx) {
      if (idx >= this.localValue.length - 1) return;
      const element = { ...this.localValue[idx] };
      this.localValue.splice(idx, 1);
      this.localValue.splice(idx + 1, 0, element);
    },
    destroy(idx) {
      if (this.localValue[idx].id) {
        this.localValue[idx]._destroy = true;
      } else {
        this.localValue.splice(idx, 1);
      }
      this.localValue = [...this.localValue];
    },
    percentage(idx) {
      return `${(
        (100.0 *
          (this.localValue[idx].points
            ? parseFloat(this.localValue[idx].points)
            : 1.0)) /
        this.pointsSum
      )
        .toFixed(2)
        .replace(/[.,]00$/, "")}%`;
    },
  },
  computed: {
    pointsSum() {
      return _.sumBy(this.localValue, (item) => parseFloat(item.points) || 1.0);
    },
  },
};
</script>

<style lang="scss" scoped>
.rating-categories {
  width: 100%;
  th {
    text-align: left;
  }
  &__th--controls {
    width: 100%;
  }
  &__th--percent {
    color: rgba(0, 0, 0, 0.38);
    text-align: center !important;
  }
  &__total {
    font-weight: bold;
  }
}
.rating-category {
  border: thin solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5em;
  margin-bottom: 1em;
  position: relative;
  tr {
    padding: 1em 0;
  }
  td {
    padding: 0 1em 1em 0;
  }
  &__controls {
    display: flex;
    justify-content: flex-end;
  }
  &__field {
    &--percent {
      color: rgba(0, 0, 0, 0.38);
      padding: 0 1em 1em 1em !important;
    }
    &--centered {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
