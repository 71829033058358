<template>
  <div>
    <v-icon @click.stop="opened = true" style="margin-left: 10px" size="16"
      >$cog</v-icon
    >
    <v-dialog max-width="450" v-model="opened">
      <v-card>
        <v-card-title>Edit Theme</v-card-title>
        <Form
          @destroy="destroy"
          @close="opened = false"
          v-model="form"
          :submit="update"
          :tempForm="tempForm"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Form from "./_form.themes.page";

export default {
  components: { Form },
  props: {
    value: Object,
  },
  data() {
    return {
      opened: false,
      form: { ...this.value },
      tempForm: { ...this.value },
    };
  },
  methods: {
    async update() {
      const { data } = await this.$api.Theme.update(this.form);
      this.$emit("submit", data.id);
      this.form = {};
      this.opened = false;
    },
    async destroy() {
      const confirmed = await this.$confirm({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.Theme.destroy(this.form);
      if (this.$route.params.id == this.form.id) {
        this.$router.push("/themes");
      }
      this.opened = false;
      this.$emit("destroy", this.form);
    },
  },
};
</script>

<style scoped lang="scss">
.new-theme {
  margin-right: 15px;
}
</style>
