<template>
  <div>
    <div class="page-title">New Page</div>
    <Form v-if="form" v-model="form" :submit="create" btnText="Add Page" />
    <Spinner v-else />
  </div>
</template>

<script>
import Form from "./_form.pages";
export default {
  components: { Form },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async setForm() {
      const { data } = await this.$api.Page.new();
      this.form = data;
    },
    async create() {
      await this.$api.Page.create(this.form);
      this.$router.push("/pages");
    },
  },
};
</script>

<style></style>
