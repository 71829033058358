<template>
  <v-app-bar
    class="elevation-0 app-bar"
    :height="['sm', 'xs'].includes($size) ? 70 : 83"
    color="primary"
    app
    clipped-left
  >
    <div class="menu-toggle">
      <v-btn @click="$emit('toggleMenu')" icon color="white">
        <v-icon size="30">$menu</v-icon>
      </v-btn>
    </div>
    <v-toolbar-title class="toolbar-title">
      <router-link to="/" class="logo-link">
        <img src="@/assets/logo.svg" width="300" alt="Logo" />
      </router-link>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <Search
      v-if="currentUser.active_team"
      min-width="400px"
      placeholder="Search"
      class="menu-search"
    />
    <v-spacer></v-spacer>
    <v-btn
      v-if="
        currentUser.active_team && currentUser.active_team_role == 'super_admin'
      "
      color="white"
      class="elevation-0 app-bar__item-create"
      @click="openNewOppDialog"
      >Create Opportunity</v-btn
    >
    <!-- <v-toolbar-items> -->
    <v-menu
      v-if="currentUser.onboarding_finished"
      offset-y
      content-class="app-bar__item-menu"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
          color="primary"
          class="elevation-0 app-bar__item app-bar__item-profile"
        >
          <div v-if="!currentUser.avatar.url" class="account-item-icon">
            <v-icon color="text">$account</v-icon>
          </div>
          <div v-else>
            <div
              class="account-item-preview"
              :style="{
                backgroundImage: `url(${currentUser.avatar.thumb.url})`,
              }"
            ></div>
          </div>
          <div>
            <div class="app-bar__full-name">{{ currentUser.full_name }}</div>
            <div class="app-bar__team" v-if="currentUser.active_team">
              {{ currentUser.active_team.title }}
            </div>
          </div>
          <v-icon color="white" size="16" class="app-bar__item-chevron_down"
            >$chevron_down</v-icon
          >
        </div>
      </template>
      <v-list>
        <div v-if="currentUser.teams.length">
          <div class="app-bar__item-menu-label">Teams</div>
          <div
            @click="setTeam(team)"
            :key="team.id"
            v-for="team in currentUser.teams"
            dense
            class="app-bar__item-menu-item"
          >
            <div class="app-bar__team-title">
              {{ team.title }}
            </div>
          </div>
          <router-link
            to="/teams/new"
            dense
            class="app-bar__item-menu-item"
            v-if="!hiddenTodos"
          >
            <div class="app-bar__item-menu__new-team">+ New Team</div>
          </router-link>
          <v-divider></v-divider>
        </div>
        <router-link to="/settings" dense class="app-bar__item-menu-item">
          Settings
        </router-link>
        <div @click="logout" dense class="app-bar__item-menu-item">Logout</div>
      </v-list>
    </v-menu>

    <div v-if="!currentUser.onboarding_finished">
      <v-btn
        color="white"
        class="elevation-0 app-bar__item app-bar__item-create"
        @click="logout"
        >Sign out</v-btn
      >
    </div>
    <!-- </v-toolbar-items> -->
    <NewOpportunityDialog />
  </v-app-bar>
</template>

<script>
import Search from "./Search";
import NewOpportunityDialog from "@/components/NewOpportunityDialog.vue";

export default {
  name: "AppBar",
  components: {
    Search,
    NewOpportunityDialog,
  },
  props: {
    drawer: Boolean,
  },
  methods: {
    async logout() {
      await this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    async setTeam(team) {
      await this.$api.Team.makeActive(team);
      await this.$store.dispatch("user/fetch");
      this.$root.$emit("projects/reload");
      const path = `/team/${this.currentUser.active_team.id}/projects`;
      if (this.$route.path == path) return;
      this.$router.push(path);
    },
    openNewOppDialog() {
      this.$root.$emit("NewOpportunityDialog");
    },
  },
};
</script>

<style scoped lang="scss">
.toolbar-title {
  padding: 0 25px;
}

.app-bar {
  &::v-deep .v-toolbar__content {
    padding: 0 20px 0 0 !important;
  }
  &__full-name {
    line-height: 1.1;
    margin-bottom: -2px;
    font-size: 13px;
    font-weight: bold;
    text-transform: capitalize;
  }
  &__team {
    line-height: 1.1;
    font-size: 10px;
    font-weight: bold;
    background: white;
    color: var(--v-primary-base);
    padding: 2px 10px;
    display: inline-block;
  }
  &__item {
    padding: 0 30px !important;
    display: flex;
    align-items: center;
    color: white;

    &-menu {
      margin-top: 5px;

      &-item {
        padding: 7px 15px;
        line-height: 1.1;
        cursor: pointer;
        display: block;
        text-decoration: none;
        color: var(--v-text-base);

        &:hover {
          opacity: 0.8;
        }
      }

      .v-divider {
        margin: 8px 0 !important;
      }

      &-label {
        font-size: 13px;
        font-weight: bold;
        padding: 0 15px;
        margin-top: 10px;
      }

      &__new-team {
        color: var(--v-primary-base);
      }
    }

    &-create {
      height: 40px !important;
      letter-spacing: 1px;
      font-weight: normal;
      padding: 0 20px !important;
      margin-right: 20px;
      color: #333 !important;
    }

    &-profile {
      cursor: pointer;
    }

    &-chevron_down {
      margin-top: 5px;
      margin-left: 5px;
    }
  }
}

.account-item {
  &-icon {
    background: white;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 11px;
  }

  &-preview {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    margin-right: 10px;
  }
}

.menu-toggle {
  display: none;
}

.logo-link {
  &,
  img {
    display: block;
  }
}

@media screen and (max-width: 960px) {
  .app-bar-item__logout {
    display: none;
  }

  .logo-link {
    display: none;
  }

  .menu-search {
    display: none;
  }

  .toolbar-title {
    display: none;
  }

  .app-bar__team {
    display: none;
  }
  
  .app-bar .search {
    display: none;
  }

}

@media screen and (max-width: 1263px) {
  .menu-toggle {
    display: block;
    margin-left: 20px;
  }

  .toolbar-title {
    padding: 30px 10px 30px 10px;
  }
}

</style>
