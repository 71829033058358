<template>
  <div>
    <div class="page-title">Chat Box Messages</div>
    <div class="panel-box">
      <div v-if="chat !== null">
        <ChatsDataTable
          v-if="chat.messages.length"
          :list="chat.messages"
          :entity="chat"
        />
        <div class="no-entries" v-else>No messages</div>
      </div>
      <Spinner v-else />
    </div>
  </div>
</template>

<script>
import ChatsDataTable from "@/components/dataview/chat.datatable";
export default {
  components: { ChatsDataTable },
  data() {
    return {
      chat: null,
    };
  },
  created() {
    this.setChat();
  },
  methods: {
    async setChat() {
      const { data } = await this.$api.Chat.get({
        full: true,
        id: this.$route.params.id,
      });
      this.chat = data;
    },
  },
};
</script>
