<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="HTML Block Info">
        <FormRow label="HTML Block Title" required>
          <TextField
            placeholder="HTML Block Title"
            v-model="form.title"
          ></TextField>
        </FormRow>
        <FormRow label="HTML Content" required>
          <CodeEditor lang="xml" v-model="form.html" />
        </FormRow>
      </FormPanel>

      <FormPanel label="HTML Block Details">
        <FormRow label="Display on Pages">
          <PagesSelector multiple v-model="form.page_ids"></PagesSelector>
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../../_extends/form.extends.js";

export default {
  extends: mixin,
};
</script>

<style></style>
