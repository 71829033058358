<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Theme Details">
        <FormRow
          required
          label="Theme Name"
          tooltip="Give a name to your custom theme."
        >
          <TextField v-model="form.title" />
        </FormRow>
        <FormRow
          required
          label="Background Color"
          tooltip="The Background color dictates the color of your Opportunity site’s background."
        >
          <ColorPicker v-model="form.body_background" />
        </FormRow>
        <FormRow
          required
          label="Highlight Color"
          tooltip="The Highlight color dictates the button, link, borders, some graphic elements, and large headline color."
        >
          <template #label>Highlight Color</template>
          <template #additional>Buttons, inputs, etc</template>

          <ColorPicker v-model="form.primary_color" />
        </FormRow>
        <FormRow
          required
          label="Text Color"
          tooltip="The Text color dictates the color of any text that’s not in your Header or Footer."
        >
          <ColorPicker v-model="form.body_color" />
        </FormRow>

        <FormRow
          required
          label="Title Font Details (H1)"
          tooltip="The Title Font allows you to choose the font and it’s accompanying parameters that will appear as headlines on your Opportunity site."
        >
          <FontSelector v-model="form.h1_font" />
        </FormRow>
        <FormRow
          required
          label="Subtitle Font Details (H2)"
          tooltip="The Subtitle Font allows you to choose the font and it’s accompanying parameters that will appear as subtitles on your Opportunity site."
        >
          <FontSelector v-model="form.h2_font" />
        </FormRow>

        <FormRow
          required
          label="Body Text Font"
          tooltip="Body Text Font allows you to choose the font and it’s accompanying parameters that will appear as body text on your Opportunity site."
        >
          <FontSelector v-model="form.paragraph_font" />
        </FormRow>
      </FormPanel>
      <FormPanel label="Sidebar Details">
        <!--<FormRow
          tooltip="The Wide Logo is your brand, company, or partner’s logo. It will appear on the Sidebar on the upper left in a desktop browser. This will default to an iU logo if nothing is uploaded."
        >
          <template #label>Logo (for Desktop)<br />(238x238)</template>
          <Uploader
            size="contain"
            :ratio="119 / 119"
            v-model="form.header_logo"
            enableCropper
          />
        </FormRow>
        <FormRow
        >
          <template #label>Logo (for Mobile)<br />(426x68)</template>
          <Uploader
            size="contain"
            :ratio="213 / 34"
            v-model="form.header_logo_mobile"
            enableCropper
          />
        </FormRow>-->
        <FormRow
          required
          label="Sidebar Background Color"
          tooltip="The Sidebar Background color dictates the color of your Opportunity site’s sidebar."
        >
          <ColorPicker v-model="form.header_background" />
        </FormRow>
        <FormRow
          required
          label="Sidebar Text Color"
          tooltip="The Sidebar Text Color dictates the color of any text that’s in your sidebar."
        >
          <ColorPicker v-model="form.header_color" />
        </FormRow>
        <FormRow
          required
          label="Sidebar Text Font"
          tooltip="The Sidebar Text Font allows you to choose the font and it’s accompanying parameters that will appear as sidebar text on your Opportunity site."
        >
          <FontSelector v-model="form.header_font" />
        </FormRow>

        <!-- <FormRow required>
          <template #label>Sidebar Dropdown Menu</template>
          <template #additional>Default Text</template>

          <TextField v-model="form.header_dropdown_placeholder" />
        </FormRow> -->
      </FormPanel>

      <!--<FormPanel label="Default Images">
        <FormRow
          tooltip="The Favicon is the icon or logo that appears next to your Commons site title in a browser or bookmark list."
        >
          <template #label>Favicon<br />(120x120)</template>
          <Uploader :ratio="1" v-model="form.favicon"/>
        </FormRow>
        <FormRow>
          <template #label>Splash Screen Image</template>
          <Uploader :ratio="320 / 180" contain v-model="form.splash_screen" enableCropper />
        </FormRow>
        <FormRow
          tooltip="The default image that will appear when you do not upload a specific image for an Event."
        >
          <template #label
            >Default "Event" Background Image<br />(1200x600)</template
          >
          <Uploader :ratio="1200 / 600" v-model="form.event_background" enableCropper />
        </FormRow>
        <FormRow
          tooltip="The default image that will appear in the background of a Timeline event's thumbnail."
        >
          <template #label
            >Default "Timeline" Thumbnail<br />(580x320)</template
          >
          <Uploader :ratio="580 / 320" v-model="form.event_preview" enableCropper />
        </FormRow>
        <FormRow
          tooltip="The default image that will appear when you do not upload a specific image for a Content Block."
        >
          <template #label
            >Default "Content Block" Thumbnail<br />(580x320)</template
          >
          <Uploader :ratio="580 / 320" v-model="form.content_block_preview" enableCropper />
        </FormRow>
        <FormRow
          tooltip="The default image that will appear when you do not upload a specific image for a Schedule item."
        >
          <template #label
            >Default "Schedule" Backdrop<br />(1280x300)</template
          >
          <Uploader :ratio="1280 / 300" v-model="form.schedule_preview" enableCropper />
        </FormRow>
      </FormPanel> -->
      <FormPanel label="Custom Styles (CSS)">
        <small>
          <b>{{ form.title || "theme" }}.css</b>
        </small>
        <CodeEditor v-model="form.css" />
      </FormPanel>
    </FormPanels>
    <SubmitFooter :submit="submit" :refs="$refs">
      Update Theme
      <template #actions>
        <v-btn @click="showTemplateModal = true" rounded outlined
          >Create Template From The Theme</v-btn
        >
      </template>
    </SubmitFooter>
    <v-dialog max-width="420" v-model="showTemplateModal">
      <v-card>
        <ThemeTemplate :theme="form" />
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
export default {
  props: {
    value: Object,
    submit: Function,
  },
  data() {
    return {
      form: { ...this.value },
      showTemplateModal: false,
    };
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.form = { ...this.value };
      },
    },
    form: {
      deep: true,
      handler() {
        if (JSON.stringify(this.form) != JSON.stringify(this.value)) {
          this.$emit("input", { ...this.form });
        }
      },
    },
  },
};
</script>

<style></style>
