<template>
  <div>
    <div class="page-title">Edit Event</div>
    <Form v-if="form" v-model="form" :submit="update" btnText="Update Event" />
    <Spinner v-else />
  </div>
</template>

<script>
import Form from "./_form.events.page";

export default {
  components: { Form },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async update() {
      await this.$api.Event.update(this.form);

      this.$router.push("/events");
    },
    async setForm() {
      const { data } = await this.$api.Event.get({ id: this.$route.params.id });
      this.form = data;
    },
  },
};
</script>

<style></style>
