<template>
  <div class="bglede-inputs">
    <div class="bglede-inputs__top">
      <Uploader
        :ratio="3750 / 3040"
        v-model="value"
        class="bglede-inputs__uploader"
        @input="$emit('input', $event)"
      />
      <v-slider
        class="bglede-inputs__Y"
        hint="Background Vertical Center. Put this at the focal point of the image."
        min="0"
        max="100"
        v-model="backgroundPositionYInverted"
        vertical
      ></v-slider>
    </div>
    <v-slider
      class="bglede-inputs__X"
      hint="Background Horizontal Center. Put this at the focal point of the image."
      min="0"
      max="100"
      v-model="backgroundStyles.backgroundPositionX"
    ></v-slider>
  </div>
</template>

<script>
export default {
  name: "UploaderWithBgPosition",
  props: {
    value: [File, Object],
    ratio: {
      type: Number,
      default: 580 / 320,
    },
    backgroundStyles: Object,
  },
  data() {
    return {
      backgroundPositionYInverted: 50,
    };
  },
  mounted() {
    this.backgroundPositionYInverted =
      100 - this.backgroundStyles.backgroundPositionY;
  },
  watch: {
    backgroundPositionYInverted() {
      this.backgroundStyles.backgroundPositionY =
        100 - this.backgroundPositionYInverted;
    },
  },
}
</script>

<style lang="scss" scoped>
.bglede-inputs {
  &__top {
    display: flex;
  }
  &__uploader {
    width: 370px;
  }
  &__Y {
    max-width: 1.5em;
    &::v-deep {
      .v-input__control,
      .v-input__slot,
      .v-slider {
        height: 100%;
      }
    }
  }
  &__X {
    max-width: 375px;
  }
}
</style>
