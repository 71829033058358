<template>
  <div>
    <div class="page-title">Edit Message</div>
    <Form
      @submit="update"
      v-model="form"
      v-if="form"
      btnText="Update Message"
    />
    <Spinner v-else />
  </div>
</template>

<script>
import Form from "./_form.chat-messages.page";
export default {
  components: { Form },
  data() {
    return {
      form: {},
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async setForm() {
      this.form = {
        user: { name: "Matt Gilbert" },
      };
    },
    async update() {
      this.$router.push("/chats");
    },
  },
};
</script>

<style></style>
