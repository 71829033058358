<template>
  <TagSelector
    :multiple="multiple"
    :items="roles"
    :value="value"
    @input="$emit('input', $event)"
    itemText="name"
  />
</template>

<script>
export default {
  props: {
    value: [String, Number, Array],
    multiple: Boolean,
  },
  data() {
    return {
      roles: [],
    };
  },
  created() {
    this.setRoles();
  },
  methods: {
    async setRoles() {
      const { data } = await this.$api.Role.lite();
      this.roles = data;
    },
  },
};
</script>

<style></style>
