<template>
  <div>
    <div class="page-title">Edit Specialty Form</div>
    <Form
      v-if="form"
      btnText="Update Specialty"
      :submit="update"
      v-model="form"
    />
    <Spinner v-else />
  </div>
</template>

<script>
import Form from "./_form.specialties.page";
export default {
  components: { Form },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async update() {
      this.form.skills_qb_classes = this.form.skills_qb_classes.filter(
        (sqc) => sqc.qb_class_id
      );
      await this.$api.Specialty.update(this.form);
      this.$router.push("/specialties");
    },
    async setForm() {
      const { data } = await this.$api.Specialty.get({
        id: this.$route.params.id,
      });
      this.form = data;
      if (this.form.skills_qb_classes.length == 0) {
        this.form.skills_qb_classes.push({});
      }
    },
  },
};
</script>

<style></style>
