<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="list"
      item-key="id"
      show-expand
      class="elevation-0 data-table"
    >
      <template v-slot:body="{ items }">
        <tbody v-for="item in items" :key="item.id">
          <tr class="data-table__row">
            <td />
            <td>
              {{ item.notification_type }}
            </td>
            <td>
              <router-link :to="`/widgets/notifications/${item.id}/edit`">
                {{ item.title }}
              </router-link>
            </td>
            <td>
              {{
                item.send_at
                  | moment(
                    'timezone',
                    currentUser.active_project.timezone,
                    'MM/DD/YY h:mm A'
                  )
              }}
            </td>
            <td class="text-center nowrap w0">
              <div class="notification-actions">
                <v-tooltip v-if="!sentList.includes(item.id)" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      icon
                      @click="sendNotification(item)"
                      v-on="on"
                    >
                      <v-icon color="primary">
                        $send
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Send Now</span>
                </v-tooltip>
                <Actions
                  :path="`/widgets/notifications/${item.id}`"
                  @destroy="$emit('destroy', item)"
                  @duplicate="$emit('duplicate', item)"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sentList: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: 'Type',
          align: 'start',
          value: 'notification_type',
        },
        {
          text: 'Title',
          align: 'start',
          value: 'title',
        },
        {
          text: 'Send at',
          align: 'start',
          value: 'send_at',
        },
        {
          align: 'center',
          sortable: false,
        },
      ];
    },
  },
  methods: {
    async sendNotification(notification) {
      await this.$api.Notification.sendNotification(notification);
      // this.sentList.push(notification.id);
    },
  },
};
</script>

<style scoped lang="scss">
.notification-actions {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
</style>
