import Vue from "vue";
import VueSanitize from "vue-sanitize";

const defaultOptions = {
  allowedTags: ["a", "b"],
  allowedAttributes: {
    a: ["href"],
  },
};
Vue.use(VueSanitize, defaultOptions);
