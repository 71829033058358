<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Event Info">
        <FormRow
          required
          label="Title"
          tooltip="The Title is what you’d like to call your Event. It is displayed as such."
        >
          <TextField placeholder="Title" v-model="form.title" />
        </FormRow>

        <FormRow
          label="Short Title"
          tooltip="In case if you’d like to override the title on the event preview"
        >
          <TextField placeholder="Title" v-model="form.short_title" />
        </FormRow>

        <FormRow
          label="Description"
          tooltip="The Description allows you to further elaborate on your Event, giving more detail. This will only display when your event is live."
        >
          <TextArea
            placeholder="Description"
            v-model="form.description"
          ></TextArea>
        </FormRow>

        <FormRow>
          <template #tooltip
            >This is the background image appears under your event content on
            the "Stage."</template
          >
          <template #label>Background Image<br />(1200x600)</template>
          <Uploader :ratio="1200 / 600" v-model="form.background" />
        </FormRow>

        <FormRow
          tooltip="This is the image that is displayed behind the title & time on the Event Thumbnail in the Timeline"
        >
          <template #label>"Timeline" Thumbnail<br />(320x180)</template>
          <Uploader :ratio="320 / 180" v-model="form.preview" />
        </FormRow>

        <FormRow
          label="Event Embed Type"
          required
          tooltip="Select whether your event is a video (Vimeo or YouTube), slideshow, or Zoom meeting."
        >
          <RadioGroup :items="eventTypeItems" v-model="form.event_type" />
        </FormRow>

        <div v-if="form.event_type == 'slideshow'">
          <EventSlideForm
            v-for="(slide, idx) in calcSlides"
            :key="slide.id || slide.uuid"
            :value="calcSlides[idx]"
            @input="updateSlide(slide, $event)"
            @delete="deleteSlide(slide, idx)"
            @up="upSlide(slide, idx)"
            @down="downSlide(slide, idx)"
          />
          <v-btn rounded elevation="0" color="primary" @click="addSlide"
            >Add Another Event Slide</v-btn
          >
        </div>
        <div v-if="form.event_type == 'zoom'">
          <FormRow required label="Zoom Player Type">
            <RadioGroup :items="zoomPlayers" v-model="form.zoom_player_type" />
          </FormRow>
          <div v-if="form.zoom_player_type == 'browser'">
            <FormRow required label="Zoom Meeting ID">
              <TextField placeholder="Zoom Meeting ID" v-model="form.zoom_id" />
            </FormRow>
            <FormRow label="Zoom Meeting Password">
              <TextField
                placeholder="Zoom Meeting Password"
                v-model="form.zoom_password"
              />
            </FormRow>
          </div>
          <div v-else-if="form.zoom_player_type == 'desktop'">
            <FormRow required label="Zoom Meeting URL">
              <TextField
                placeholder="Zoom Meeting URL"
                v-model="form.zoom_url"
              />
            </FormRow>
          </div>
        </div>
        <div v-if="form.event_type == 'youtube'">
          <FormRow required label="YouTube ID">
            <TextField placeholder="YouTube ID" v-model="form.youtube_id" />
          </FormRow>
        </div>

        <div v-if="form.event_type == 'vimeo'">
          <FormRow required label="Vimeo ID">
            <TextField placeholder="Vimeo ID" v-model="form.vimeo_id" />
          </FormRow>

          <FormRow label="Vimeo Chapter">
            <TextField
              placeholder="Vimeo Chapter"
              v-model="form.vimeo_chapter"
            />
          </FormRow>
        </div>

        <div v-if="form.event_type == 'youtube' || form.event_type == 'vimeo'">
          <FormRow
            label="Video follows user on scroll"
            tooltip="Check the box if you want the video player to stay visible in a small player to the upper left when the user scrolls down the page."
          >
            <Checkbox v-model="form.sticky_video"></Checkbox>
          </FormRow>
        </div>
      </FormPanel>
      <FormPanel label="Event Timing">
        <FormRow label="Event Start Time" required>
          <template #tooltip
            >This is the time the event will "go live" and be accessible from
            the timeline.</template
          >
          <DateTimePicker
            placeholder="Select Date/Time"
            v-model="form.start_at"
          ></DateTimePicker>
        </FormRow>
        <FormRow label="Event Display Time">
          <template #tooltip>
            This is the time that will appear in all areas of Commons where this
            event is displayed. If blank, the Start Time will be displayed.
          </template>
          <DateTimePicker
            placeholder="Select Date/Time"
            v-model="form.display_time"
          ></DateTimePicker>
        </FormRow>

        <FormRow label="Event Start Notification">
          <template #tooltip>
            This setting allows you to create a pop-up notification (or not) to
            alert users of this even going "live."
          </template>
          <RadioGroup :items="notifications" v-model="form.notify" />
        </FormRow>
        <FormRow label="Event End Time">
          <template #template>
            This is the time your Event will "end" and guide the user to the
            next event in their timeline.
          </template>
          <DateTimePicker
            placeholder="Select Date/Time"
            v-model="form.end_at"
          ></DateTimePicker>
        </FormRow>
        <FormRow label="Event Hide Time">
          <template #template>
            This is the time your Event will be removed from the timeline.
          </template>
          <DateTimePicker
            placeholder="Select Date/Time"
            v-model="form.hide_at"
          ></DateTimePicker>
        </FormRow>
      </FormPanel>

      <FormPanel label="Event Details">
        <FormRow
          label="Limit to Role(s)"
          tooltip="This setting allows you to limit access to the page based on selected roles."
        >
          <RolesSelector multiple v-model="form.role_ids"></RolesSelector>
        </FormRow>
        <FormRow
          label="Display Page(s)"
          tooltip="Select the page or pages you'd like this event to live."
        >
          <PagesSelector multiple v-model="form.page_ids"></PagesSelector>
        </FormRow>

        <FormRow
          label="Hide from Timeline"
          tooltip="Choose whether you would like your event hidden from the timeline here."
        >
          <Checkbox v-model="form.hidden_from_timeline"></Checkbox>
        </FormRow>

        <FormRow
          tooltip="Select this checkbox if you want to require site authentication to access this event."
          label="Require Authentication"
        >
          <Checkbox v-model="form.require_auth"></Checkbox>
        </FormRow>

        <FormRow
          label="Add Calendar Link"
          tooltip="Add a link for guests to add this event to their calendar (gCal, iCal, etc)"
        >
          <TextField
            placeholder="Add Calendar Link"
            v-model="form.add_to_calendar_link"
          ></TextField>
        </FormRow>
      </FormPanel>
    </FormPanels>

    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import EventSlideForm from "@/components/EventSlideForm";
import mixin from "../_extends/form.extends.js";
import { v4 as uuidv4 } from "uuid";

export default {
  extends: mixin,
  components: { EventSlideForm },
  data() {
    return {
      panel: [0, 1, 2],
      pages: [],
      zoomPlayers: [
        { value: "desktop", title: "Zoom Desktop Player" },
        { value: "browser", title: "Browser Player" },
      ],
      eventTypeItems: [
        {
          value: "vimeo",
          title: "Vimeo",
          tooltip:
            "Clicking Vimeo will allow you to add a Vimeo ID and a Vimeo Chapter.",
        },
        {
          value: "youtube",
          title: "YouTube",
          tooltip: "Clicking YouTube will allow you to add a YouTube ID.",
        },
        {
          value: "slideshow",
          title: "Slideshow",
          tooltip:
            "Clicking Slideshow will allow you to add hyperlinked images to the stage.",
        },
        {
          value: "zoom",
          title: "Zoom",
          tooltip:
            "Clicking Zoom will allow you to add a Zoom Meeting ID and Zoom Passcode",
        },
      ],
      notifications: [
        {
          value: false,
          title: "No Notification",
          tooltip: "Don't notify users of this event.",
        },
        {
          value: true,
          title: "Clickable Notification",
          tooltip:
            "Notify users and make the pop-up clickable, taking them to this event.",
        },
      ],
    };
  },
  computed: {
    sortedSlides() {
      return [...this.form.slides].sort((a, b) => a.position - b.position);
    },
    calcSlides() {
      return this.sortedSlides.filter((t) => !t._destroy);
    },
  },
  methods: {
    updateSlide(slide, $event) {
      this.form.slides = this.form.slides.map((el) => {
        if (el.id == slide.id) return $event;
        return el;
      });
    },
    addSlide() {
      this.form.slides.push({
        uuid: uuidv4(),
        position: this.form.slides.length,
      });
    },
    deleteSlide(slide) {
      let slides = this.form.slides.map((t) => {
        if (t.id && slide.id == t.id) {
          return { ...t, _destroy: true };
        }
        return { ...t };
      });

      slides = slides.filter(
        (t) => !t.uuid || (t.uuid && t.uuid != slide.uuid)
      );

      this.form = {
        ...this.form,
        slides: [...slides],
      };
    },
    upSlide(slide) {
      const currentPosition = this.sortedSlides.findIndex(
        (t) => (t.id && t.id == slide.id) || (t.uuid && t.uuid == slide.uuid)
      );

      if (currentPosition === 0) return;

      const slides = [...this.sortedSlides];
      slides[currentPosition].position = currentPosition - 1;
      slides[currentPosition - 1].position = currentPosition;

      this.form = { ...this.form, slides: [...slides] };
    },
    downSlide(slide) {
      const currentPosition = this.sortedSlides.findIndex(
        (t) => (t.id && t.id == slide.id) || (t.uuid && t.uuid == slide.uuid)
      );

      if (currentPosition === this.sortedSlides.length - 1) return;

      const slides = [...this.sortedSlides];
      slides[currentPosition].position = currentPosition + 1;
      slides[currentPosition + 1].position = currentPosition;

      this.form = { ...this.form, slides: [...slides] };
    },
  },
};
</script>

<style></style>
