<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Content Block Info">
        <FormRow
          label="Title"
          required
          tooltip="This is the title of the Content Block as it will be displayed on the front-end of your site."
        >
          <TextField v-model="form.title" placeholder="Title" />
        </FormRow>
        <FormRow
          label="Subtitle"
          tooltip="You can add a subtitle for this Content Bloack here."
        >
          <TextField v-model="form.subtitle" placeholder="Subtitle" />
        </FormRow>
        <FormRow
          tooltip="This replaces the default Content Block thumbnail for your content."
        >
          <template #label> Thumbnail<br />(320x180) </template>
          <Uploader v-model="form.preview" :ratio="320 / 180" />
        </FormRow>
        <FormRow
          required
          label="Content Block Type"
          tooltip="Choose the type of content block: Video Player, Popup, or New Tab Link"
        >
          <RadioGroup v-model="form.content_type" :items="contentTypes" />
        </FormRow>

        <div v-if="['video_player', 'popup'].includes(form.content_type)">
          <FormRow
            label="Creator"
            tooltip="Choosing a creator will include details about that creator in the popup: specialty, location, and social links."
          >
            <UsersSelector v-model="form.creator_id" ></UsersSelector>
          </FormRow>
        </div>
        <div v-if="form.content_type == 'video_player'">
          <FormRow required label="Show in Video Player">
            <RadioGroup
              v-model="form.video_player_type"
              :items="videoPlayers"
            />
          </FormRow>

          <div v-if="form.video_player_type == 'vimeo'">
            <FormRow required label="Vimeo ID">
              <TextField v-model="form.vimeo_id" placeholder="Vimeo ID" />
            </FormRow>
            <FormRow
              required label="Vimeo H (for private videos)"
              tooltip="Private Vimeo videos uploaded after Sept 14, 2021 also require an 'h' parameter, which can be found in the embed code for that video."
            >
              <TextField v-model="form.vimeo_h" placeholder="Vimeo H" />
            </FormRow>

            <FormRow label="Vimeo Chapter">
              <TextField
                v-model="form.vimeo_chapter"
                placeholder="Vimeo Chapter"
              />
            </FormRow>
          </div>
          <div v-if="form.video_player_type == 'youtube'">
            <FormRow required label="YouTube ID">
              <TextField v-model="form.youtube_id" placeholder="YouTube ID" />
            </FormRow>
          </div>
          <div v-if="form.video_player_type == 'zoom'">
            <FormRow required label="Zoom URL">
              <TextField v-model="form.zoom_url" placeholder="Zoom URL" />
            </FormRow>
          </div>
          <div v-if="form.video_player_type == 'event'">
            <FormRow required label="Existing Event">
              <EventsSelector
                v-model="form.event_id"
                placeholder="Existing Event"
              />
            </FormRow>
          </div>
        </div>
        <div v-else-if="form.content_type == 'new_tab'">
          <FormRow required label="Link URL">
            <TextField
              v-model="form.link_url"
              placeholder="Link URL"
              :rules="[validation.url]"
            />
          </FormRow>
        </div>
        <div v-else-if="form.content_type == 'popup'">
          <Slides v-model="form.slides" title="Popup Slide" />
          <FormRow label="Link URL">
            <TextField
              v-model="form.link_url"
              placeholder="Link URL"
              :rules="[validation.url]"
            />
          </FormRow>
          <FormRow label="Link Copy">
            <TextField v-model="form.link_text" placeholder="Link Copy" />
          </FormRow>
        </div>
        <FormRow label="Description">
          <TextArea v-model="form.description" placeholder="Description" />
        </FormRow>
      </FormPanel>
      <FormPanel label="Content Block Timing">
        <FormRow
          label="Post Start Time"
          tooltip="The time at which this Content Block will go live on your site."
        >
          <DateTimePicker
            v-model="form.start_at"
            placeholder="Select Date/Time"
          />
        </FormRow>
        <FormRow
          label="Post End Time"
          tooltip="The time at which this Content Block will become un-clickable on your site."
        >
          <DateTimePicker
            v-model="form.end_at"
            placeholder="Select Date/Time"
          />
        </FormRow>
      </FormPanel>
      <FormPanel label="Content Block Details">
        <FormRow
          required
          label="Content Sections"
          tooltip="Select the Content Section(s) on which you'd like this Content Block to appear."
        >
          <ContentSectionsSelector v-model="form.section_ids" multiple />
        </FormRow>

        <FormRow
          label="Limit to Role(s)"
          tooltip="This setting allows you to limit access to the content section based on selected roles."
        >
          <RolesSelector multiple v-model="form.role_ids"></RolesSelector>
        </FormRow>

      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">
      {{ btnText }}
    </SubmitFooter>
  </v-form>
</template>

<script>
import mixin from '../../../_extends/form.extends.js';
export default {
  extends: mixin,
  data() {
    return {
      pages: [],
      contentSections: [],
      videoPlayers: [
        { title: 'Vimeo', value: 'vimeo' },
        { title: 'YouTube', value: 'youtube' },
        { title: 'Zoom', value: 'zoom' },
        { title: 'Existing "Event"', value: 'event' },
      ],
      contentTypes: [
        { title: 'Video Player', value: 'video_player' },
        { title: 'Popup', value: 'popup' },
        { title: 'New Window Link', value: 'new_tab' },
        { title: 'No Content', value: 'no_content' },
      ],
    };
  },
};
</script>

<style></style>
