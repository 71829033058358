<template>
  <div class="addon">
    <div class="addon-content">
      <div class="addon-title">{{ localAddon.addon.title }}</div>
      <div class="addon-description">{{ localAddon.addon.description }}</div>
    </div>
    <div class="addon-actions">
      <Switcher @change="toggle()" v-model="localAddon.enabled" />
      <v-btn v-if="localAddon.addon.with_settings" icon @click="dialog = true">
        <v-icon>$cog</v-icon>
      </v-btn>
    </div>
    <v-dialog
      max-width="500"
      v-if="dialog && localAddon.addon.with_settings"
      v-model="dialog"
    >
      <v-form @submit.prevent="update(true)" class="addon-dialog" ref="form">
        <component
          v-model="localAddon"
          :is="localAddon.addon.type.replace('::', '')"
        ></component>
        <v-btn type="submit" class="addon-dialog__submit" color="primary"
          >Update</v-btn
        >
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    addon: Object,
  },
  data() {
    return {
      dialog: false,
      localAddon: { ...this.addon },
    };
  },
  methods: {
    toggle() {
      const addon = this.localAddon;
      if (
        addon.enabled &&
        addon.addon.with_settings &&
        addon.addon.required_fields.length
      ) {
        this.dialog = true;
      } else {
        this.update(false);
      }
    },
    async update(validate) {
      const addon = this.localAddon;
      if (validate) {
        const invalidFields = addon.addon.required_fields.filter(
          (t) => !addon.data[t.name]
        );
        if (invalidFields.length) {
          return this.$alert(`${invalidFields[0].title} is required`, "error");
        }
      }
      try {
        await this.$api.Addon.update(addon);
        this.dialog = false;
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.addon {
  background: white;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 2px solid var(--v-border-base);
  display: flex;
  width: 100%;

  &-dialog {
    background: white;
    padding: 20px;

    &__submit {
      margin-top: 10px !important;
    }

    &::v-deep {
      .page-title {
        font-size: 24px;
      }

      .text-field {
        width: 100%;
        margin-bottom: 15px;
      }

      .callback-url input {
        font-size: 13px;
      }
    }
  }

  &-content {
    max-width: calc(100% - 150px);
    width: 100%;
  }
  &-actions {
    display: flex;
    width: 150px;
    justify-content: flex-end;
    align-items: center;
  }

  &-title {
    font-weight: bold;
    font-size: 18px;
  }

  &-description {
    font-size: 14px;
  }
}
</style>
