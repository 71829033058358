<template>
  <div>
    <v-form ref="form">
      <FormPanels>
        <FormPanel label="Opportunity Info">
          <FormRow label="Project Code">
            <ProjectCodeField v-model="form.project_code" />
          </FormRow>

          <FormRow
            tooltip="This is the type of opportunity."
            required
            label="Opportunity Type"
          >
            <Select
              placeholder="Opportunity Type"
              :items="projectTypes"
              v-model="form.project_type"
              :rules="[validation.required]"
              style="max-width: 270px"
            />
          </FormRow>
          <FormRow
            tooltip="This is the name your opportunity will be referenced as on the Admin portal."
            required
            label="Opportunity Name"
          >
            <TextField
              v-model="form.title"
              placeholder="Opportunity Name"
              :rules="[validation.required]"
            />
          </FormRow>
          <FormRow
            tooltip="This text will appear below the opportunity's name in opportunity lists."
            label="Opportunity Subheader"
          >
            <TextField
              v-model="form.subtitle"
              placeholder="Opportunity Subheader"
            />
          </FormRow>
          <FormRow
            tooltip="This will be used as the notification thumbnail, and on the Admin portal to help identify this opportunity."
          >
            <template #label>Opportunity Image</template>
            <Uploader v-model="form.preview" :ratio="1" enableCropper />
            <template #additional>
              <a @click="openGDriveFor('preview')">Choose from Image Library</a>
            </template>
          </FormRow>
          <FormRow label="Partner Name">
            <TextField v-model="form.partner_name" placeholder="Partner Name" />
          </FormRow>

          <FormRow
            tooltip="This is the image that will display in the upper left corner of the opportunity page."
          >
            <template #label>Partner Logo</template>
            <Uploader v-model="form.frontend_logo" :ratio="1" />
          </FormRow>
          <FormRow
            tooltip="This is the image that will display in the upper left corner of the opportunity page on mobile devices."
          >
            <template #label>Partner Logo (for Mobile) <br />(426x68)</template>
            <Uploader v-model="form.frontend_logo_mobile" :ratio="426 / 68" />
          </FormRow>
          <FormRow
            tooltip="Check to hide the partner logo from the upper left corner of the opportunity page."
            label="Hide Partner Logo"
          >
            <Checkbox v-model="form.hide_frontend_logo" />
          </FormRow>
          <FormRow
            tooltip="The Favicon is the icon or logo that appears next to your Commons site title in a browser or bookmark list."
          >
            <template #label>Favicon<br />(120x120)</template>
            <Uploader :ratio="1" v-model="form.favicon" />
          </FormRow>

          <FormRow
            tooltip="This will exclude this opportunity from ever being “featured”, even if no other active, upcoming opportunities are featured."
            label="Never Featured"
          >
            <Checkbox v-model="form.never_featured" />
          </FormRow>
          <FormRow
            tooltip="This is the title shown on submission thank you messages, and the browser tab if no other SEO title is set."
            label="Opportunity Headline"
          >
            <TextField
              v-model="form.headline"
              placeholder="Opportunity Headline"
            />
          </FormRow>
          <FormRow
            tooltip="Typically, opportunities are ordered by closing date. However, setting the order explicitly here will move the opportunity to this position, ahead of opportunities that don't have an order set."
            label="Order in lists"
          >
            <TextField
              placeholder="Order in lists"
              v-model="form.position"
              :rules="[validation.integer]"
            />
          </FormRow>
          <FormRow>
            <template #label>
              <div>
                <p>Opportunity Notes</p>
                <ul>
                  <li>Project Status</li>
                  <li>Opportunity Requirements</li>
                  <li>Compensation</li>
                </ul>
                <br />
              </div>
            </template>
            <TextArea v-model="form.notes" placeholder="Opportunity Notes" />
            <p v-if="form.user_creator">
              Submitted by {{ form.user_creator }} at&nbsp;
              {{
                form.created_at
                  | moment(
                    "timezone",
                    currentUser.active_project.timezone,
                    "MM/DD/YY h:mm A zz"
                  )
              }}
            </p>
          </FormRow>
          <FormRow
            tooltip="A quick overview of this site and its purpose."
            label="Notification Description"
          >
            <TextArea
              v-model="form.og_description"
              placeholder="Notification Description"
            />
          </FormRow>
          <!-- <FormRow
            label="Opportunity Meta Tags"
            tooltip="These will be the default meta tags for each page on your site, if no meta tags are specified on individual pages."
          >
            <MetaTagsEditor v-model="form.meta_tags" />
          </FormRow> -->
          <FormRow
            label="Copyright Text"
            tooltip="This will display text in the Footer of your site, commonly used for any copyright language."
          >
            <TextArea v-model="form.copyright" />
          </FormRow>
        </FormPanel>
        <FormPanel label="Opportunity Description Section/Gig Info">
          <FormRow
            label="Studio Department"
            light
            tooltip="This field determines what template is used when creating an opportunity, and can not be changed."
          >
            <div style="margin-top: 15px">
              {{ studioDepartmentName(form.studio_department) }}
            </div>
          </FormRow>
          <FormRow
            label="Hiring Managers/Reviewers"
            light
            tooltip="Select the person(s) who are responsible for reviewing submissions for this opportunity."
          >
            <UsersSelector
              class="create-opp-form__hms"
              placeholder="Hiring Managers/Reviewers"
              v-model="form.hiring_manager_ids"
              :roles="['reviewer']"
              light
              fromProduction
              multiple
            />
          </FormRow>
          <FormRow
            label="Hiring Managers/Reviewers (Staging)"
            light
            tooltip="For testing in staging: Select the person(s) who are responsible for reviewing submissions for this opportunity."
          >
            <template #label>
              <div>Hiring Managers/Reviewers <br />(Staging)</div>
            </template>

            <UsersSelector
              class="create-opp-form__hms"
              placeholder="Hiring Managers/Reviewers (Staging)"
              v-model="form.staging_hiring_manager_ids"
              :roles="['reviewer']"
              light
              fromStaging
              multiple
            />
          </FormRow>
          <FormRow label="Community Need Reasoning" light>
            <Select
              v-model="form.community_need_option"
              :items="communityNeedOptions"
              placeholder="Community Need Reasoning"
              light
            />
          </FormRow>
          <FormRow
            label="Community Need Explanation"
            light
            tooltip="Please explain why you are seeking to hire a Community creator for this role."
          >
            <TextArea
              v-model="form.community_need"
              placeholder="Community Need Reasoning"
            />
          </FormRow>
          <FormRow label="Project Tier" light>
            <Select
              placeholder="Project Tier"
              :items="projectTiers"
              v-model="form.project_tier"
              light
            ></Select>
          </FormRow>
          <FormRow
            tooltip="ex: “FL Event Photographer”. If a specific role is not associated with the opp (i.e. Director of Photography), then let us know the title of the opp (i.e. Golf Brainstorm)"
            label="Role Name"
          >
            <TextField v-model="form.role_name" placeholder="Role Name" />
          </FormRow>
          <FormRow
            tooltip="A short description (2-4 sentences) that summarizes the gig at a high-level. Try to use plain, colloquial language and add a bit of personality."
            label="Project Brief"
          >
            <TextArea v-model="form.description" placeholder="Project Brief" />
          </FormRow>
          <FormRow
            tooltip="What is the “finished product” of this role? #of videos edited, hours of footage recorded, footage style, design, multi-slide pitchdeck, etc."
            label="Project Deliverable/Scope"
          >
            <TextArea
              v-model="form.deliverable"
              placeholder="Project Deliverable/Scope"
            />
          </FormRow>
          <FormRow
            tooltip="Describe the projected start and end date of the actual gig."
            label="Project Timeline"
          >
            <TextArea v-model="form.timeline" placeholder="Project Timeline" />
          </FormRow>
          <FormRow
            tooltip="Does this role require any specific gear, software,additional personnel, etc.?"
            label="Technical Requirements"
          >
            <TextArea
              v-model="form.technical_requirements"
              placeholder="Technical Requirements"
            />
          </FormRow>
          <FormRow
            label="Reference Examples"
            light
            tooltip="If applicable, please provide any references. (Media file and/or link upload.)"
          >
            <ReferenceExamplesEditor v-model="form.reference_examples" />
          </FormRow>
        </FormPanel>
        <FormPanel label="Opportunity State & Timing">
          <FormRow label="Submissions Open">
            <DateTimePicker
              placeholder="Select Date/Time"
              v-model="form.opening_date"
            />
          </FormRow>
          <FormRow label="Submissions Close">
            <DateTimePicker
              placeholder="Select Date/Time"
              v-model="form.closing_date"
            />
          </FormRow>

          <FormRow label="Auto Change Submission State">
            <template #tooltip>
              This determines whether the opportunity closes automatically at
              the "Submissions closed" time. (An opportunity's state may change
              once saved, depending on this setting and the dates.)
            </template>
            <Checkbox v-model="form.auto_change_submission_state" />
          </FormRow>

          <FormRow
            label="Current State"
            v-if="
              currentUser.active_project &&
              currentUser.active_project.slug != 'referral'
            "
          >
            <Select
              style="max-width: 270px"
              v-model="form.submission_state"
              :items="datasources.submission_form_states[form.project_type]"
            />
          </FormRow>
          <FormRow
            label="Review Submissions Early"
            v-if="
              currentUser.active_project &&
              currentUser.active_project.slug != 'referral'
            "
            tooltip="This will display submissions for this opportunity in the Review page before the status is changed to 'Review'"
          >
            <Checkbox v-model="form.review_submissions_early" />
          </FormRow>
          <FormRow label="Different State or Times in Staging">
            <template #tooltip>
              For testing out different states of submission forms in staging.
            </template>
            <Checkbox
              v-model="form.different_staging_state_and_times"
              @input="prepopulateStagingTimes"
            />
          </FormRow>

          <div
            v-if="form.different_staging_state_and_times"
            style="margin-left: 2em"
          >
            <FormRow label="Staging Submissions Open">
              <DateTimePicker
                placeholder="Select Date/Time"
                v-model="form.staging_opening_date"
              />
            </FormRow>
            <FormRow label="Staging Submissions Close">
              <DateTimePicker
                placeholder="Select Date/Time"
                v-model="form.staging_closing_date"
              />
            </FormRow>

            <FormRow label="Staging: Auto Change Submission State">
              <template #tooltip>
                This determines whether the opportunity closes automatically at
                the "Submissions closed" time, in staging.
              </template>
              <Checkbox v-model="form.staging_auto_change_submission_state" />
            </FormRow>

            <FormRow
              label="Staging: Current State"
              v-if="currentUser.active_project.slug != 'referral'"
            >
              <Select
                style="max-width: 270px"
                v-model="form.staging_submission_state"
                :items="datasources.submission_form_states[form.project_type]"
              />
            </FormRow>
          </div>

          <!--
          <FormRow label="Send Emails on State Changes"> </FormRow>

          <div style="margin-left: 2em">
            <FormRow label="Send Emails on Review">
              <template #tooltip>
                Send emails to all participants to notify them that their
                submission is under review.
              </template>
              <Checkbox v-model="form.send_emails_when_review" />
            </FormRow>
            <FormRow label="Send Emails when Finalists are Announced">
              <template #tooltip>
                Send emails to all participants, finalists and non-finalists, to
                notify them that they have or have not been selected as
                finalists.
              </template>
              <Checkbox v-model="form.send_emails_when_finalists_announced" />
            </FormRow>
            <FormRow label="Send Emails when Campaign is Concluded">
              <template #tooltip>
                Send emails to finalists who were not selected as winners.
                (Winners will not be notified with an automated email, and
                should be notified directly instead.)
              </template>
              <Checkbox v-model="form.send_emails_when_campaign_concluded" />
            </FormRow>
          </div>
          -->

          <FormRow
            label="Create Audience Group on Review"
            tooltip="This will create a group of all users who submitted to the opportunity in MailerLite when the opportunity's state is changed to 'Review'"
          >
            <Checkbox v-model="form.create_audience_group_on_review" />
          </FormRow>

          <FormRow label="Don’t Send Submission Confirmation Emails">
            <template #tooltip>
              Check to disable submission confirmation emails.
            </template>
            <Checkbox v-model="form.dont_send_submission_confirmation_emails" />
          </FormRow>
        </FormPanel>
        <FormPanel label="Web Setup">
          <!--<FormRow
            :required="form.restrict_production_access"
            label="Live Site URL"
            tooltip="This will be the public URL for your Opportunity."
            v-if="currentUser.active_team_role == 'super_admin'"
          >
            <v-layout align-center>
              <span class="mr-3">https://</span>
              <div>
                <TextField
                  v-model="form.production_domain"
                  placeholder="example.com"
                  :rules="
                    form.restrict_production_access
                      ? [
                          validation.required,
                          validation.project.productionDomain,
                        ]
                      : [validation.project.productionDomain]
                  "
                />
              </div>
            </v-layout>
          </FormRow>-->
          <div v-if="form.production_domain">
            <FormRow
              label="Enable redirect"
              tooltip="If this is enabled, users will be redirected from the Opportunities page to an external URL to complete the opportunity. Badges, submissions, and other Opportunity features will be bypassed if this is enabled"
            >
              <RadioGroup
                v-model="form.production_redirect_enabled"
                :items="[
                  { title: 'No', value: false },
                  { title: 'Yes', value: true },
                ]"
              />
            </FormRow>
            <FormRow
              v-if="form.production_redirect_enabled"
              required
              label="Redirect URL"
            >
              <TextField
                v-model="form.production_redirect_url"
                placeholder="Redirect URL"
                :rules="[validation.required, validation.url]"
              />
            </FormRow>
          </div>
          <FormRow
            required
            label="Production Root Path"
            tooltip="This will be the production root path for your Opportunity."
          >
            <v-layout align-center>
              <div class="mr-3">
                <div v-if="form.production_domain">
                  https://{{ form.production_domain }}/
                </div>
              </div>
              <div>
                <TextField
                  v-model="form.production_root_path"
                  placeholder="example"
                  :rules="[validation.project.rootPath]"
                />
              </div>
            </v-layout>
          </FormRow>
          <FormRow label="Password Protect Live Site">
            <template #tooltip>
              Select "Yes" if you want to have password access to the URL above.
              Note, this is not user authentication, purely domain access.
            </template>
            <RadioGroup
              v-model="form.restrict_production_access"
              :items="[
                { title: 'No', value: false },
                { title: 'Yes', value: true },
              ]"
            />
          </FormRow>
          <div v-if="form.restrict_production_access">
            <FormRow
              required
              label="Live Site Access Username"
              tooltip="The username to access the Live Site URL"
            >
              <TextField
                v-model="form.production_login"
                placeholder="Live Site Access Username"
                :rules="[validation.required]"
              />
            </FormRow>
            <FormRow
              required
              label="Live Site Access Password"
              tooltip="The password to access the Live Site URL"
            >
              <TextField
                v-model="form.production_password"
                placeholder="Live Site Access Password"
                :rules="[validation.required]"
                type="password"
              />
            </FormRow>
          </div>
          <br />
          <v-divider />
          <br />
          <FormRow
            required
            label="Staging Root Path"
            tooltip="This will be the staging root path for your Opportunity."
          >
            <v-layout align-center>
              <div class="mr-3">
                <div v-if="form.staging_domain">
                  https://staging-{{ rootDomain }}/
                </div>
              </div>
              <div>
                <TextField
                  v-model="form.staging_root_path"
                  placeholder="example"
                  :rules="[validation.project.rootPath]"
                />
              </div>
            </v-layout>
          </FormRow>
          <FormRow label="Password Protect Staging Site">
            <template #tooltip>
              Select "Yes" if you want to have password access to the URL above.
              Note, this is not user authentication, purely domain access.
            </template>
            <RadioGroup
              v-model="form.restrict_staging_access"
              :items="[
                { title: 'No', value: false },
                { title: 'Yes', value: true },
              ]"
            />
          </FormRow>
          <div v-if="form.restrict_staging_access">
            <FormRow required label="Staging Site Access Username">
              <template #tooltip>
                The username to access the Staging Site URL
              </template>
              <TextField
                v-model="form.staging_login"
                placeholder="Staging Site  Access Username"
                :rules="[validation.required]"
              />
            </FormRow>
            <FormRow required label="Staging Site Access Password">
              <template #tooltip>
                The password to access the Staging Site URL
              </template>
              <TextField
                v-model="form.staging_password"
                placeholder="Staging Site Access Password"
                :rules="[validation.required]"
              />
            </FormRow>
          </div>

          <br />
          <v-divider />
          <br />

          <FormRow label="Publish opportunity">
            <template #tooltip>
              Select "Yes" if you want to make this opportunity visible on the
              frontend
            </template>
            <RadioGroup
              v-model="form.published"
              :items="[
                { title: 'No', value: false },
                { title: 'Yes', value: true },
              ]"
            />
          </FormRow>
          <FormRow label="Previous opportunity">
            <template #tooltip>
              Check if you want to move this opportunity to the "Previous
              Opportunities" section of the opportunities page and in admin.
            </template>
            <Checkbox v-model="form.previous" />
          </FormRow>
        </FormPanel>
        <!-- <FormPanel label="Social Media">
          <FormRow label="LinkedIn" tooltip="Enter your LinkedIN handle here.">
            <TextField v-model="form.linkedin" placeholder="LinkedIn" />
          </FormRow>
          <FormRow label="Facebook" tooltip="Enter your Facebook handle here.">
            <TextField v-model="form.facebook" placeholder="Facebook" />
          </FormRow>
          <FormRow label="TikTok" tooltip="Enter your TikTok handle here.">
            <TextField v-model="form.ticktok" placeholder="TikTok" />
          </FormRow>
          <FormRow
            label="Instagram"
            tooltip="Enter your Instagram handle here."
          >
            <TextField v-model="form.instagram" placeholder="Instagram" />
          </FormRow>
          <FormRow label="Twitter" tooltip="Enter your Twitter handle here.">
            <TextField v-model="form.twitter" placeholder="Twitter" />
          </FormRow>
        </FormPanel> -->
        <FormPanel label="Release Content" v-if="form.release_content">
          <FormRow
            tooltip="This image is what shows up on the left side of your opportunity. If an image is not uploaded, a default image will appear on your Opportunity site."
            label="Opportunity Lede Background"
            v-if="['gig', 'ideation_short'].includes(form.project_type)"
          >
            <UploaderWithBgPosition
              :ratio="3750 / 3040"
              v-model="form.release_content.lede_background"
              :backgroundStyles="form.release_content.lede_background_styles"
            />
            <template #additional>
              <a @click="openGDriveFor('lede_background')"
                >Choose from Image Library</a
              >
            </template>
          </FormRow>

          <FormRow
            tooltip="The preview image shown when the site is shared on social media."
          >
            <template #label>Social Share Preview<br />(1200x627)</template>
            <Uploader
              :ratio="1200 / 627"
              v-model="form.release_content.social_share_preview"
            />
          </FormRow>
          <FormRow
            label="Social Share Title"
            tooltip="The title shown on the social share preview. Keep this under 55-60 characters."
          >
            <TextField
              v-model="form.release_content.social_share_title"
              placeholder="Social Share Title"
            />
          </FormRow>
          <FormRow
            label="Social Share Site Name"
            tooltip="The site listed on the social share preview."
          >
            <TextField
              v-model="form.release_content.social_share_site_name"
              placeholder="i.e. “community.ideasunited.com’"
            />
          </FormRow>
          <FormRow
            label="Social Share Description"
            tooltip="Keep this under 200 characters. The shorter the better."
          >
            <TextArea
              v-model="form.release_content.social_share_description"
              placeholder="Social Share Description"
            />
          </FormRow>
          <FormRow
            label="SEO/Default Page Title"
            tooltip="Keep this 50–70 characters or less. This is the title of your page, as it appears in browsers or tabs, and search results."
          >
            <template #additional
              >(in browser/tab, and search engine results)</template
            >
            <TextField
              v-model="form.release_content.seo_title"
              placeholder="SEO Title"
            />
          </FormRow>
          <FormRow
            label="SEO Description"
            tooltip="Keep this under 160 characters. The shorter the better."
          >
            <TextArea
              v-model="form.release_content.seo_description"
              placeholder="SEO Description"
            />
          </FormRow>
        </FormPanel>
        <FormPanel label="Reports" v-show="form.is_root">
          <FormRow label="Emerging Users">
            <TextField
              v-model="form.emerging_users"
              placeholder="Emerging Users"
            />
          </FormRow>
          <FormRow label="Instagram (total followers)">
            <TextField
              v-model="form.total_instagram_followers"
              placeholder="Instagram (total followers)"
            />
          </FormRow>
          <FormRow label="LinkedIn (total followers)">
            <TextField
              v-model="form.total_linkedin_followers"
              placeholder="LinkedIn (total followers)"
            />
          </FormRow>
          <FormRow label="$ paid out YTD">
            <TextField
              v-model="form.money_paid_out_ytd"
              placeholder="$ paid out YTD"
            />
          </FormRow>
          <FormRow label="Active Community members on projects">
            <TextField
              v-model="form.active_community_members_on_projects"
              placeholder="Active Community members on projects"
            />
          </FormRow>
        </FormPanel>
        <FormPanel label="Opportunity Details">
          <FormRow
            required
            label="Base Timezone"
            tooltip="This is the timezone in which all dates & times for your opportunity will be linked."
          >
            <TimezoneSelector
              v-model="form.timezone"
              :rules="[validation.required]"
            />
          </FormRow>
          <FormRow
            required
            label="Display Local Timezone"
            tooltip="Select this if event and schedule times should be shown in the site visitor's local timezone, such as for a nation-wide or international event."
          >
            <Checkbox
              class="form-builder__required"
              v-model="form.display_local_timezone"
            />
          </FormRow>
        </FormPanel>
        <FormPanel
          v-if="currentUser.active_team_role == 'super_admin'"
          label="Storage"
        >
          <FormRow label="Storage Used" clean>
            <div class="layout align-center" style="margin-top: 15px">
              {{ Math.round(form.storage_amount / 1048576) }} MB
            </div>
          </FormRow>
          <FormRow label="Storage Limit (MB)" clean>
            <TextField
              :value="Math.round(form.storage_limit / 1048576)"
              placeholder="Storage Limit"
              :rules="[validation.integer]"
              @input="updateStorageLimit($event)"
            />
          </FormRow>
        </FormPanel>
      </FormPanels>
      <SubmitFooter v-if="btnText" :refs="$refs" :submit="submit">
        {{ btnText }}
        <template #actions>
          <div v-if="form.id && currentUser.active_team_role == 'super_admin'">
            <v-btn
              outlined
              color="primary"
              elevation="0"
              rounded
              large
              @click="duplicateProject(form)"
            >
              Duplicate
            </v-btn>
            <v-btn
              outlined
              color="primary"
              elevation="0"
              rounded
              large
              @click="destroy"
              v-if="!form.is_template && !form.is_root && !form.is_profile"
            >
              Recycle Bin
            </v-btn>
          </div>
        </template>
      </SubmitFooter>
    </v-form>
    <ProjectDuplicateDialog />
    <GDriveImageLibraryDialog @selectImage="selectImage" />
  </div>
</template>

<script>
import mixin from "../_extends/form.extends.js";
import ProjectDuplicateDialog from "@/components/ProjectDuplicateDialog.vue";
import projectDuplicateMixin from "../../mixins/project-duplicate.mixin";
import GDriveImageLibraryDialog from "@/components/GDriveImageLibraryDialog.vue";
import ReferenceExamplesEditor from "@/components/ReferenceExamplesEditor.vue";

export default {
  mixins: [mixin, projectDuplicateMixin],
  components: {
    ProjectDuplicateDialog,
    GDriveImageLibraryDialog,
    ReferenceExamplesEditor,
  },
  data() {
    return {
      rootDomain: process.env.VUE_APP_ROOT_DOMAIN,
      projectTypes: [
        { name: "EVP", value: "evp" },
        { name: "Gig", value: "gig" },
        { name: "Ideation Short", value: "ideation_short" },
      ],
      statuses: [
        { name: "Active/Sold", value: "active" },
        { name: "Pitch", value: "pitch" },
      ],
      gDriveImageTarget: "",
      studioDepartments: [
        { name: "Production", value: "production" },
        { name: "Post-production", value: "post_production" },
        { name: "Design", value: "design" },
        { name: "Writing", value: "writing" },
        { name: "Events", value: "events" },
      ],
      projectTiers: [
        { name: "Tier 1", value: "no_creative" },
        {
          name: "Tier 2",
          value: "creative_thought_starters",
        },
        { name: "Tier 3", value: "creative_component" },
        { name: "Tier 4", value: "high" },
      ],
      communityNeedOptions: [
        { name: "Internal Bandwidth Exhausted", value: "bandwidth" },
        { name: "Seeking Specific Skillset", value: "skillset" },
        { name: "Local Support Needed", value: "location" },
        { name: "Other", value: "other" },
      ],
    };
  },
  mounted() {
    if (this.form && !this.form.release_content) {
      this.form.release_content = {
        lede_background_styles: {
          backgroundPositionX: 50,
          backgroundPositionY: 50,
        },
      };
    }
  },
  methods: {
    updateStorageLimit($event) {
      this.form.storage_limit = $event * 1048576;
    },
    async destroy() {
      if (this.currentUser.active_team_role != "super_admin") return;
      const confirmed = await this.$confirm({
        title: "Are you sure?",
        description:
          "This will put the project in the recycle bin, making it inaccessible. After 30 days, the project and all its data will be permanently deleted.",
      });
      if (!confirmed) return;
      await this.$api.Project.archive({ id: this.form.id });
      this.$router.push("/projects");
    },
    openGDriveFor(fieldName) {
      this.gDriveImageTarget = fieldName;
      this.$root.$emit("GDriveImageLibraryDialog");
    },
    async selectImage(value) {
      if (this.gDriveImageTarget == "lede_background")
        this.form.release_content[this.gDriveImageTarget] = value;
      else this.form[this.gDriveImageTarget] = value;
    },
    prepopulateStagingTimes($event) {
      if ($event) {
        if (!this.form.staging_opening_date && this.form.opening_date)
          this.form.staging_opening_date = this.form.opening_date;
        if (!this.form.staging_closing_date && this.form.closing_date)
          this.form.staging_closing_date = this.form.closing_date;
      }
    },
    studioDepartmentName(value) {
      if (!value) return "N/A";
      return this.studioDepartments.find((sd) => sd.value == value)?.name;
    },
  },
};
</script>

<style></style>
