<template>
  <TagSelector
    :items="qbClasses"
    :value="value ? value.qb_class_id : null"
    @input="$emit('input', $event)"
    :placeholder="placeholder"
  />
</template>

<script>
export default {
  props: {
    value: Object,
    placeholder: String,
  },
  data() {
    return {
      qbClasses: [],
    };
  },
  created() {
    this.setQbClasses();
  },
  methods: {
    async setQbClasses() {
      const { data } = await this.$api.QbClass.lite();
      this.qbClasses = data;
    },
  },
};
</script>

<style></style>
