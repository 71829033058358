<template>
  <div>
    <v-btn
      @click="opened = true"
      class="new-theme elevation-0"
      small
      color="primary"
    >
      New Theme
    </v-btn>
    <v-dialog max-width="450" v-model="opened">
      <v-card>
        <v-card-title>New Theme</v-card-title>
        <Form @close="opened = false" v-model="form" :submit="create">
          <br />
          <Select
            placeholder="Use Template"
            :items="templates"
            v-model="form.template_theme_id"
            v-if="!form.id"
            hideDetails="auto"
            item-text="title"
            item-value="id"
          />
        </Form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Form from "./_form.themes.page";
export default {
  components: { Form },
  data() {
    return {
      form: {},
      opened: false,
      templates: [],
    };
  },
  created() {
    this.setTemplates();
    this.$root.$on("themesChanged", () => this.setTemplates());
  },
  methods: {
    async setTemplates() {
      const { data } = await this.$api.Theme.lite({ template: true });
      this.templates = data;
    },
    async create() {
      const { data } = await this.$api.Theme.create(this.form);
      this.$emit("submit", data.id);
      this.form = {};
      this.opened = false;
    },
  },
};
</script>

<style scoped lang="scss">
.new-theme {
  margin-right: 15px;
}
</style>
