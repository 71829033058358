<template>
  <div>
    <div class="sidebar-title" v-if="title">{{ title }}</div>
    <div v-for="(item, idx) in items" :key="idx">
      <v-list-group v-if="item.children" :group="item.to" class="sidebar-group" :value="item.opened">
        <template v-slot:appendIcon>
          <v-icon class="sidebar-group__toggle-icon"> $chevron_down </v-icon>
        </template>
        <template v-slot:activator>
          <v-tooltip
            open-delay="500"
            :disabled="!item.tooltip"
            max-width="300"
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" class="sidebar-item" v-on="on" @click="item.createOpp ? openNewOppDialog() : false">
                <v-list-item-icon>
                  <v-icon>${{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title> {{ item.text }} </v-list-item-title>
              </v-list-item>
            </template>
            <span>{{ item.tooltip }}</span>
          </v-tooltip>
        </template>

        <div>
          <v-tooltip
            v-for="(subItem, sidx) in item.children"
            :key="sidx"
            open-delay="500"
            :disabled="!subItem.tooltip"
            max-width="300"
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                link
                :to="subItem.to"
                class="sidebar-subitem"
                active-class="active"
                v-bind="attrs"
                v-on="on"
                @click="subItem.createOpp ? openNewOppDialog() : false"
              >
                <v-list-item-title>{{ subItem.text }}</v-list-item-title>
              </v-list-item>
            </template>
            <span>{{ subItem.tooltip }}</span>
          </v-tooltip>
        </div>
      </v-list-group>
      <v-tooltip
        v-else
        open-delay="500"
        :disabled="!item.tooltip"
        max-width="300"
        right
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item
            :to="item.to"
            class="sidebar-item"
            active-class="active"
            v-bind="attrs"
            v-on="on"
          >
            <v-list-item-icon>
              <v-icon>${{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </template>
        <span>{{ item.tooltip }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
    title: String,
  },
  methods: {
    openNewOppDialog() {
      this.$root.$emit('closeSideBar');
      this.$root.$emit("NewOpportunityDialog");
    }
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  &-title {
    padding-left: 50px;
    margin-bottom: 10px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.071429em;
    color: var(--v-dark-base);
  }
  &-subitem {
    padding-left: 88px;
  }

  &-item {
    padding-left: 45px;

    &::v-deep {
      min-height: 38px;
      .v-list-item__title {
        color: var(--v-text-base) !important;
        font-weight: bold !important;
      }
      .v-list-item__icon {
        margin-right: 18px;
      }
    }
  }

  &-item,
  &-subitem {
    &.active {
      background: var(--v-primary-base);

      &,
      &::v-deep .v-list-item__title {
        color: white !important;
      }

      &:before {
        opacity: 0;
      }
    }
  }
  &-group {
    &::v-deep .v-list-group__header {
      padding: 0 !important;
    }

    &__toggle-icon {
      position: relative;
      left: -8px;
    }
  }
}
</style>
