<template>
  <div>
    <div v-if="value">
      <div v-if="value.length">
        <v-data-table
          disable-pagination
          :items-per-page="-1"
          :hide-default-footer="showMore"
          :headers="headers"
          :items="value"
          item-key="id"
          show-expand
          class="elevation-0 data-table"
        >
          <template v-slot:body="{ items }">
            <tbody v-for="item in items" :key="item.id">
              <tr class="data-table__row">
                <td />

                <td class="nowrap">
                  <router-link :to="`/logs/${item.id}`">
                    {{
                      item.created_at
                        | moment(
                          "timezone",
                          currentUser.active_project.timezone,
                          "MM/DD/YY h:mm A"
                        )
                    }}
                  </router-link>
                </td>
                <td>
                  {{ item.who ? item.who.full_name : 'N/A' }}
                </td>
                <td>
                  {{ item.what }}
                </td>
                <td>{{ item.message }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </div>
      <div v-else class="no-entries">No Entries</div>
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    showMore: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    headers() {
      return [
        {
          text: "Date",
          align: "start",
          value: "created_at",
          sortable: false,
        },
        {
          text: "Who",
          align: "start",
          value: "who",
          sortable: false,
        },
        {
          text: "What",
          align: "start",
          value: "what",
          sortable: false,
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.ticket-status {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  padding: 5px 12px;
  border-radius: 100px;
  text-align: center;
  display: inline-block;

  &.pending {
    color: white;
    background: var(--v-primary-base);
  }
  &.closed {
    color: white;
    background: var(--v-text-base);
  }
}
</style>
