<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Text Block Info">
        <FormRow required label="Title">
          <TextField placeholder="Title" v-model="form.title"></TextField>
        </FormRow>
        <FormRow required label="Layout">
          <TextBlockLayoutSelector v-model="form.layout" />
        </FormRow>
        <div v-for="(block, idx) in blocks" :key="idx">
          <FormRow :label="block.label + ' Block Title'">
            <TextField
              :placeholder="block.label + ' Block Title'"
              v-model="form[`block_title` + (idx + 1)]"
            ></TextField>
          </FormRow>
          <FormRow :label="block.label + ' Block Description'">
            <TextArea
              :placeholder="block.label + ' Block Description'"
              v-model="form[`block_description` + (idx + 1)]"
            ></TextArea>
          </FormRow>

          <FormRow :label="block.label + ' Block Link'">
            <TextField
              :placeholder="block.label + ' Block Link'"
              v-model="form[`block_link` + (idx + 1)]"
            ></TextField>
          </FormRow>
          <FormRow>
            <template #label
              >{{ block.label + " Block Image" }}<br />(320x180)</template
            >
            <Uploader
              :ratio="320 / 180"
              v-model="form[`block_preview` + (idx + 1)]"
            />
          </FormRow>

          <FormRow :label="block.label + ' Text Color'">
            <ColorPicker
              :placeholder="block.label + ' Text Color'"
              v-model="form[`text_color` + (idx + 1)]"
            />
          </FormRow>
        </div>
      </FormPanel>

      <FormPanel label="Text Block Details">
        <FormRow label="Display on Pages">
          <PagesSelector multiple v-model="form.page_ids"></PagesSelector>
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../../_extends/form.extends.js";
export default {
  extends: mixin,
  computed: {
    blocks() {
      const result = [
        {
          label: this.form.layout == 4 ? "First" : this.form.layout == 1 ? "" : "Left",
          visible: !!this.form.layout,
        },
        {
          label: this.form.layout == 4 ? "Second" : this.form.layout == 2 ? "Right" : "Top Right",
          visible: [3, 2, 4].includes(this.form.layout),
        },
        {
          label:this.form.layout == 4 ? "Third" :  "Bottom Right",
          visible: [3, 4].includes(this.form.layout),
        },
        {
          label: "Fourth",
          visible: [4].includes(this.form.layout),
        },
      ];

      return result.filter((t) => t.visible);
    },
  },
};
</script>

<style></style>
