<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Project Form Info">
        <FormRow label="User">
          <UsersSelector v-model="form.user_id"></UsersSelector>
        </FormRow>
        <FormRow label="Project Code" required>
          <InternalProjectsSelector v-model="form.internal_project_id" />
        </FormRow>
        <FormRow label="Notes">
          <TextArea placeholder="Notes" v-model="form.notes"></TextArea>
        </FormRow>
        <FormRow label="Reviewer">
          <UsersSelector v-model="form.reviewer_id"></UsersSelector>
        </FormRow>
        <FormRow label="Rating">
          <TextField
            type="number"
            min="1"
            max="5"
            placeholder="Rating"
            v-model="form.rating"
          ></TextField>
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../_extends/form.extends.js";

export default {
  extends: mixin,
};
</script>

<style></style>
