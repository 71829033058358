<template>
  <!-- <TextField :label="label" :value="value" @input="$emit('input', $event)" /> -->
  <div>
    <Uploader
      v-if="!fileInput"
      :rules="rules"
      :value="value"
      @input="$emit('input', $event)"
    />
    <FileField
      v-else
      :placeholder="label"
      :value="value"
      @input="$emit('input', $event)"
      :rules="rules"
      acceptFiletypes="all"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: [String, File, Object],
    rules: {
      type: Array,
      default: () => [],
    },
    fileInput: Boolean,
  },
};
</script>

<style></style>
