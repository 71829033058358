<template>
  <v-textarea
    :value="value"
    @input="$emit('input', $event)"
    hide-details="auto"
    auto-grow
    rounded
    outlined
    class="text-area"
    :placeholder="placeholder"
    :label="label"
    :type="type"
    :rows="rows"
    :rules="rules"
    :disabled="disabled"
  ></v-textarea>
</template>

<script>
export default {
  props: {
    placeholder: String,
    label: String,
    value: String,
    rows: Number,
    type: {
      type: String,
      default: "text",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
  },
};
</script>

<style scoped lang="scss">
.text-area {
  width: 100%;

  &::v-deep {
    &:not(.v-input--has-state) {
      fieldset {
        border-color: var(--v-border-base);
      }

      .v-input__slot:hover fieldset {
        border-color: rgba(0, 0, 0, 0.3);
      }
    }
  }
}
</style>
