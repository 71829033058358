<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="list"
      item-key="id"
      show-expand
      class="elevation-0 data-table"
    >
      <template v-slot:body="{ items }">
        <tbody v-for="item in items" :key="item.id">
          <tr
            class="data-table__row"
            @click="$router.push('/users/participants/' + item.id)"
          >
            <td />

            <td>
              <!-- <a :href="`mailto:${item.email}`">{{ item.email }}</a> -->
              {{ item.email }}
            </td>
            <td class="nowrap td-name">
              <div>
                <div>{{ item.full_name }}</div>
                <div
                  v-if="
                    item.account_type == 'business' && item.doing_business_as
                  "
                >
                  ({{ item.doing_business_as }})
                </div>
              </div>
              <v-btn
                @click.stop="loginTo(item)"
                outlined
                style="margin-left: 15px"
                color="primary"
                x-small
                >Login</v-btn
              >
            </td>
            <td>{{ item.average_profile_rating }}</td>
            <td>
              <v-tooltip open-delay="500" top max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="participant-badges">
                    {{
                      item.badges
                        .filter((it, i, ar) => ar.indexOf(it) === i)
                        .join(", ")
                    }}
                  </div>
                </template>
                <span>{{
                  item.badges
                    .filter((it, i, ar) => ar.indexOf(it) === i)
                    .join(", ")
                }}</span>
              </v-tooltip>
            </td>
            <td>
              {{
                item.verified_at
                  | moment(
                    "timezone",
                    currentUser.active_project.timezone,
                    "MM/DD/YY h:mm A zz"
                  )
              }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    loginTo(user) {
      const domain =
        this.currentUser.active_project.domains[
          this.currentUser.active_project_env
        ];
      window.open(
        "https://" + domain + "/token-login?token=" + user.one_click_auth_token
      );
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Email",
          align: "start",
          value: "email",
        },
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        {
          text: "Average profile rating",
          align: "start",
          value: "average_profile_rating",
        },
        {
          text: "Badges",
          align: "start",
          value: "badges",
        },
        {
          text: "Verified At",
          align: "start",
          value: "verified_at",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.participant-badges {
  overflow: hidden;
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    // overflow: auto;
    // white-space: pre-line;
  }
}

.data-table.v-data-table > .v-data-table__wrapper .data-table__row {
  cursor: pointer;

  &:hover td {
    background: #e7e7e7 !important;
  }
}

.td-name {
  display: flex;
  align-items: center;
}
</style>

<style lang="scss">
.env-production {
  .data-table.v-data-table > .v-data-table__wrapper .data-table__row {
    &:hover td {
      background: #4a4a4a !important;
    }
  }
}
</style>
