import store from "@/store";

export const ifNotAuthenticated = async (to, from, next) => {
  const currentUser = await fetchUser();

  if (!currentUser) {
    next();
    return;
  }
  next("/");
};

export const ifAuthenticated = async (to, from, next) => {
  const currentUser = await fetchUser();

  if (!currentUser) return next({ name: "login" });

  const rules = [
    {
      condition: !currentUser.onboarding_finished,
      allowedPages: ["onboarding"],
      redirectTo: "onboarding",
    },
    {
      condition: !currentUser.active_team?.id,
      allowedPages: ["newTeam", "settings", "onboarding"],
      redirectTo: "newTeam",
    },
    {
      condition: !currentUser.active_project?.id,
      allowedPages: [
        "projects",
        "newProject",
        "editProject",
        "newTeam",
        "editTeam",
        "settings",
        "onboarding",
        "teamProjects",
      ],
      redirectTo: "projects",
    },
  ];

  for (const rule of rules) {
    if (rule.condition && !rule.allowedPages.includes(to.name)) {
      return next({ name: rule.redirectTo });
    }
  }

  next();
};

const fetchUser = async () => {
  return await store.dispatch("user/fetch");
};
