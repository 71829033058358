<template>
  <div class="color-picker">
    <v-text-field
      :value="value"
      @input="$emit('input', $event)"
      hide-details="auto"
      rounded
      outlined
    >
      <template v-slot:append>
        <v-menu :close-on-content-click="false" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="color-picker__picker" icon>
              <v-icon>$picker</v-icon>
            </v-btn>
          </template>

          <Sketch @input="$emit('input', $event.hex8)" :value="value" />
        </v-menu>
      </template>
      <template v-slot:prepend-inner>
        <div :style="{ background: value }" class="color-picker__box"></div>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import { Sketch } from "vue-color";

export default {
  components: { Sketch },
  props: {
    value: {
      type: String,
      default: "#194d33",
    },
  },
};
</script>

<style scoped lang="scss">
.color-picker {
  max-width: 270px;

  &__box {
    height: 18px;
    width: 18px;
    position: relative;
    top: 0;
    left: -4px;
    border: 1px solid var(--v-border-base);
  }

  &__picker {
    position: relative;
    top: -5px;
  }

  &::v-deep {
    .v-input__slot {
      min-height: 50px;
    }

    &:hover fieldset {
      border-color: rgba(0, 0, 0, 0.3);
    }

    fieldset {
      border-color: var(--v-border-base);
    }

    .v-input__append-inner {
      margin-top: 13px;
    }
  }
}
</style>
