<template>
  <div class="env-selector-dialog">
    <div class="page-title">Product Releases</div>
    <div class="env-selector-dialog__content">
      <div class="env-selector-dialog__content-wrapper">
        <EnvSelectorDialogReleases
          @setReleases="setReleases"
          @setDeployments="setDeployments"
          @pushDeployed="deployedIds.push($event)"
          @watchDeployment="watchDeployment"
          :items="releases"
          :deployedIds="deployedIds"
        />
        <EnvSelectorDialogDeployments
          @setReleases="setReleases"
          @setDeployments="setDeployments"
          :items="deployments"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EnvSelectorDialogReleases from "./env-selector/EnvSelectorDialogReleases";
import EnvSelectorDialogDeployments from "./env-selector/EnvSelectorDialogDeployments";

export default {
  components: {
    EnvSelectorDialogReleases,
    EnvSelectorDialogDeployments,
  },
  data() {
    return {
      releases: null,
      deployedIds: [],
      deployments: null,
      watchDeployments: {},
    };
  },
  created() {
    this.setReleases();
    this.setDeployments();
  },
  beforeDestroy() {
    for (const watchers of Object.values(this.watchDeployments)) {
      clearInterval(watchers);
    }
  },
  methods: {
    async watchDeployment(id) {
      this.watchDeployments[id] = setInterval(async () => {
        const { data } = await this.$api.Deployment.get({ id });
        this.deployments = this.deployments.map((deployment) => {
          if (deployment.id == id) {
            if (data.state == "deployed")
              clearInterval(this.watchDeployments[id]);
            return { ...data };
          }
          return { ...deployment };
        });
      }, 3000);
    },
    async setReleases() {
      const { data } = await this.$api.Release.get();
      this.releases = data;
    },
    async setDeployments() {
      const { data } = await this.$api.Deployment.get();
      this.deployments = data;
    },
  },
};
</script>

<style scoped lang="scss">
.page-title {
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 15px;
}
.env-selector-dialog {
  background: white;
  min-height: 300px;
  padding: 25px;

  &__content {
    min-height: 400px;
    position: relative;
    &-wrapper {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 20px;
      right: 0;
      display: flex;
      margin: -15px;
    }
  }
}
</style>
