<template>
  <div>
    <ListPanels :loaded="!!list">
      <div v-if="list && list.length">
        <ListPanel
          v-for="section in list"
          :key="section.id"
          :action-path="`/widgets/submission-forms/${section.id}`"
          :title="section.title"
          hide-destroy
          hide-duplicate
        >
          <template #bottom>
            <SubmissionStage
              :value="section.state"
              :stagingState="section.staging_state"
            />
          </template>

          <SubmissionsDataTable
            :params="{ user_id: userId }"
            :entity="section"
          />
        </ListPanel>
      </div>
      <div v-else class="no-entries">No Submission Forms</div>
    </ListPanels>
  </div>
</template>

<script>
import SubmissionsDataTable from "@/components/dataview/submissions.datatable";

export default {
  components: { SubmissionsDataTable },
  data() {
    return {
      list: null,
      userId: this.$route.params.id,
    };
  },
  created() {
    this.fetchList();
  },
  methods: {
    async fetchList() {
      const { data } = await this.$api.SubmissionForm.get({
        user_id: this.userId,
      });

      this.list = data;
    },
  },
};
</script>

<style></style>
