<template>
  <div class="badge">
    <div
      class="badge-preview"
      :style="{ backgroundImage: `url(${badge.preview.url})` }"
    >
      <v-icon v-if="!badge.preview.url" color="#f4cb21" size="60">$seal</v-icon>
    </div>
    <div class="badge-wrapper">
      <div class="badge-name">{{ badge.title }}</div>
      <div class="badge-type">{{ badge.badge_type }}</div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    badge: Object,
  },
};
</script>

<style scoped lang="scss">
.badge {
  margin-bottom: 15px;
  border: 1px solid #e7e7e7;
  height: 100px;
  display: flex;

  &-name {
    font-weight: bold;
    line-height: 1;
    text-transform: capitalize;
  }

  &-type {
    text-transform: uppercase;
    font-weight: bold;
    color: #a8a8a8;
    margin-bottom: 10px;
    font-size: 10px;
  }

  &-preview {
    height: 100%;
    width: 100px;
    background-color: #f7f7f7;
    background-size: cover;
    background-position: center;
    border-right: 1px solid #e7e7e7;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-wrapper {
    padding: 15px;
  }

  &-count {
    display: flex;
    align-items: center;

    &__value {
      padding: 0 10px;
    }
  }
}
</style>
