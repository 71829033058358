<template>
  <v-autocomplete
    :value="value"
    @input="$emit('input', $event)"
    :items="items"
    :chips="multiple"
    outlined
    rounded
    height="40"
    :label="label"
    :multiple="multiple"
    class="tag-selector"
    :class="{ multiple, 'hidden-input': multiple && maximum && value.length >= maximum }"
    :item-text="itemText"
    :item-value="itemValue"
    :deletable-chips="multiple"
    :clearable="!multiple"
    hide-details="auto"
    :rules="rules"
    :menu-props="{
      closeOnContentClick: multiple && maximum && value.length >= maximum,
    }"
    dense
  >
    <template v-slot:append>
      <v-icon>$chevron_down</v-icon>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    label: String,
    value: [String, Number, Array],
    multiple: Boolean,
    maximum: Number,
    returnObject: Boolean,
    rules: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: "title",
    },
    itemValue: {
      type: [Number, String],
      default: "id",
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
.tag-selector {
  width: 100%;
  max-width: 270px;

  &.multiple {
    max-width: 100%;
  }

  &::v-deep button.v-icon.v-chip__close {
    &:after {
      content: "✕";
      position: relative;
      color: var(--v-text-base);
      font-size: 12px;
      opacity: 1;
      background: transparent;
      padding: 3px;
    }

    &:hover:after {
      color: var(--v-primary-base);
    }

    svg {
      display: none;
    }
  }

  &::v-deep {

    &.hidden-input input {
      display: none;
    }
    &.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections {
      min-height: 40px;
    }
    &.v-text-field--outlined > .v-input__control > .v-input__slot {
      min-height: 50px;
    }
    &.v-text-field--enclosed .v-input__append-inner {
      margin-top: 12px !important;
    }
    
    fieldset {
      border-color: var(--v-border-base);
    }

    .v-input__slot:hover fieldset {
      border-color: rgba(0, 0, 0, 0.3);
    }

    &.v-text-field--outlined.v-input--has-state fieldset {
      border-color: red;
    }

    label {
      font-size: 14px !important;
      color: rgba(0, 0, 0, 0.38);
    }
    &.v-text-field--outlined.v-input--dense {
      .v-label {
        top: 14px;
      }
      .v-label--active {
        transform: translateY(-21px) scale(0.75);
      }
    }
  }
}
</style>
