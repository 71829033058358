<template>
  <div>
    <Banner theme="orange">
      <template v-slot:title> Schools </template>
      <template v-slot:description>
        Schools provide autocomplete search results for a creator when updating the school in their profile.
      </template>
      <template v-slot:image>
        <img src="@/assets/banners/badges.svg" alt="Schools" />
      </template>
    </Banner>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="New School"
      btn-to="/schools/new"
      :sort-by.sync="sortBy"
      :search-text.sync="searchText"
      search-placeholder="Search Schools"
    />

    <div class="page-subtitle">Schools</div>
    <div v-if="list">
      <div v-if="computedList.length">
        <SchoolsDataTable :list="computedList" @destroy="destroy" />
      </div>
      <div v-else class="no-entries">No Schools</div>
    </div>
    <Spinner v-else relative />
  </div>
</template>

<script>
import SchoolsDataTable from "@/components/dataview/schools.datatable";
import IndexPage from "./index-page";

export default {
  name: "SchoolsPage",
  components: { SchoolsDataTable },
  extends: IndexPage,
  data() {
    return {
      searchFields: ['title', 'aliases', 'city', 'state'],
      sortList: [
        { text: 'Title', value: 'title', order: "ASC" },
        { text: 'Created date, most recent', value: 'created_at', order: "DESC" },
        { text: 'Created date, ascending', value: 'created_at', order: "ASC" },
        { text: 'Updated date, most recent', value: 'updated_at', order: "DESC" },
        { text: 'Updated date, ascending', value: 'updated_at', order: "ASC" },
      ],
    };
  },
  methods: {
    async fetchList() {
      const params = { sort_by: this.sortBy }
      console.info('fetching list this.sortBy', this.sortBy)
      const sortByObj = this.sortList.find((e) => e.value == this.sortBy)
      console.info('fetching list order', sortByObj)
      if(sortByObj) params.sort_order = sortByObj.order;
      console.info('fetching list params.sort_order', params.sort_order)
      const { data } = await this.$api.School.get(params);
      console.info('fetchList data', data)
      this.list = data;
    },
    async destroy({ id }) {
      const confirmed = await this.$confirm({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.School.destroy({ id });
      this.list = this.list.filter((t) => t.id != id);
    },
  },
};
</script>

<style scoped lang="scss"></style>
