<template>
  <div>
    <Banner theme="orange">
      <template v-slot:title> Badges </template>
      <template v-slot:description>
        Badges are labels to mark users with achievement, roles, etc
      </template>
      <template v-slot:image>
        <img src="@/assets/banners/badges.svg" alt="Badges" />
      </template>
    </Banner>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="New Badge"
      btn-to="/badges/new"
      :sort-by.sync="sortBy"
      :search-text.sync="searchText"
      search-placeholder="Search Badge"
    />

    <div class="page-subtitle">Badges</div>
    <div v-if="list">
      <div v-if="computedList.length">
        <BadgesDataTable :list="computedList" @destroy="destroy" />
        <!-- <Pagination :perPage.sync="perPage" :page.sync="page" /> -->
      </div>
      <div v-else class="no-entries">No Badges</div>
    </div>
    <Spinner v-else relative />
  </div>
</template>

<script>
import BadgesDataTable from "@/components/dataview/badges.datatable";
import IndexPage from "./index-page";

export default {
  name: "BadgesPage",
  components: { BadgesDataTable },
  extends: IndexPage,
  methods: {
    async fetchList() {
      const { data } = await this.$api.Badge.get({
        sort_by: this.sortBy,
      });
      this.list = data;
    },
    async destroy({ id }) {
      const confirmed = await this.$confirm({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.Badge.destroy({ id });
      this.list = this.list.filter((t) => t.id != id);
    },
  },
};
</script>

<style scoped lang="scss"></style>
