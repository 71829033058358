<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="List Info">
        <FormRow
          label="Title"
          required
          tooltip="This is the title of the People List as it will be displayed on the Admin side of your site."
        >
          <TextField
            v-model="form.title"
            :rules="[validation.required]"
            placeholder="title"
          />
        </FormRow>
        <FormRow
          label="Headline"
          required
          tooltip="This is the headline text as it will be displayed on the front-end of your site."
        >
          <TextField v-model="form.headline" placeholder="Headline" />
        </FormRow>
        <FormRow label="Description" required>
          <template #tooltip>
            Add any finer detail to be displayed on the front-end of your site
            under the "Headline."
          </template>
          <TextArea v-model="form.description" placeholder="Description" />
        </FormRow>
        <FormRow
          label="Display Page(s)"
          tooltip="Select the page or pages on which you'd like this List to appear."
        >
          <PagesSelector v-model="form.page_ids" multiple />
        </FormRow>
        <FormRow
          label="Sort By"
          required
          tooltip="This is the order in which your list will be sorted."
        >
          <RadioGroup
            v-model="form.sort_by"
            :rules="[validation.required]"
            :items="sortByItems"
          />
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">
      {{ btnText }}
    </SubmitFooter>
  </v-form>
</template>

<script>
import mixin from '../../_extends/form.extends.js';
export default {
  extends: mixin,
  data() {
    return {
      sortByItems: [
        { title: 'A-Z', value: 'az' },
        { title: 'Z-A', value: 'za' },
        { title: 'Ascending Created Date#', value: 'created_at_asc' },
        { title: 'Descending Created Date#', value: 'created_at_desc' },
      ],
    };
  },
};
</script>

<style></style>
