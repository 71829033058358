<template>
  <div>
    <v-form ref="form">
      <FormPanels>
        <FormPanel label="Page Info">
          <FormRow
            label="Hidden"
            tooltip="Select this checkbox if you want to page excluded from the public opportunity site."
          >
            <Checkbox v-model="form.hidden" />
          </FormRow>
          <FormRow label="Url Type" required>
            <template #tooltip>
              Select whether you want this page to live within your Opportunity
              domain (Branch URL) or link "offsite" (External Link).
            </template>
            <RadioGroup :items="urlTypes" v-model="form.url_type" />
          </FormRow>
          <FormRow
            v-if="form.url_type != 'none'"
            tooltip="Here you can give your page a custom URL at your Commons domain."
            label="Custom URL"
            :required="form.url_type == 'absolute'"
          >
            <v-layout align-center v-if="form.url_type == 'relative'">
              <span class="mr-3">
                <div v-if="currentUser.active_project_env == 'production'">
                  {{ currentUser.production_domain }}/
                </div>
                <div v-else>{{ currentUser.staging_domain }}/</div>
              </span>
              <div>
                <TextField
                  placeholder="Custom URL (Root if empty)"
                  v-model="form.url"
                />
              </div>
            </v-layout>

            <TextField
              v-else
              placeholder="https://example.com"
              v-model="form.absolute_url"
              :rules="[validation.required, validation.urlOrMailto]"
            />
          </FormRow>

          <FormRow
            required
            label="Page Name"
            tooltip="This is the name of your page, as it appears within the Opportunity."
          >
            <TextField
              :rules="[validation.required]"
              placeholder="Page Name"
              v-model="form.title"
            ></TextField>
          </FormRow>

          <!-- <div v-if="form.url_type == 'relative'">
            <FormRow
              tooltip="This is the title of your page, as it appears in browsers or tabs."
              label="Page Display Title (in browser/tab)"
            >
              <TextField
                placeholder="Page Display Title (in browser/tab)"
                v-model="form.headline"
              ></TextField>
            </FormRow>

            <FormRow
              label="Page Meta Tags"
              tooltip="Add any meta data tags you'd like in this field."
            >
              <MetaTagsEditor v-model="form.meta_tags" />
            </FormRow>
          </div> -->
        </FormPanel>
        <FormPanel label="Page Content">
          <FormRow
            tooltip="This image is what shows up on the left side of your opportunity. If an image is not uploaded, a default image will appear on your Opportunity site."
            label="Lede Background"
          >
            <UploaderWithBgPosition
              :ratio="3750 / 3040"
              v-model="form.lead_background"
              :backgroundStyles="form.lead_background_styles"
            />
            <template #additional>
              <a @click="openGDriveFor('lead_background')"
                >Choose from Image Library</a
              >
            </template>
          </FormRow>

          <FormRow
            tooltip="Optional. This will appear at the top of this page on the opportunity site."
            label="Page Header"
          >
            <TextField
              placeholder="Page Header"
              v-model="form.content_header"
            ></TextField>
          </FormRow>

          <FormRow
            tooltip="Optional. This will appear at the top of this page on the opportunity site, below the Page Header."
            label="Page Subhead"
          >
            <TextField
              placeholder="Page Subhead"
              v-model="form.content_subhead"
            ></TextField>
          </FormRow>
        </FormPanel>
        <FormPanel label="Page Details">
          <!-- <div v-if="form.url_type == 'relative'">
            <FormRow
              label="Page Theme (Default If Empty)"
              tooltip="Choose the theme for this page."
            >
              <ThemesSelector v-model="form.theme_id"></ThemesSelector>
            </FormRow>
            <FormRow
              label="Role-based Access"
              tooltip="Select a role or roles to restirct access to this page for those groups of users."
            >
              <RolesSelector multiple v-model="form.role_ids"></RolesSelector>
            </FormRow>
            <FormRow
              label="Require Authentication"
              tooltip="Select this checkbox if you want to require site authentication to access this page."
            >
              <Checkbox v-model="form.authorized" />
            </FormRow>
          </div> -->

          <FormRow
            required
            label="Add to Sidebar"
            tooltip="Select if you want this page linked in the Sidebar. This applys to Opportunities with multiple pages like EVPs."
          >
            <RadioGroup :items="addToMenuItems" v-model="form.add_to_menu" />
          </FormRow>

          <!-- <div v-if="['menu_bar'].includes(form.add_to_menu)">
            <FormRow
              required
              label="Add to Sub-Menu"
              tooltip="Select a parent page."
            >
              <PagesSelector
                :condition="(page) => page.id != form.id"
                v-model="form.parent_menu_id"
              />
            </FormRow>
          </div> -->
          <div v-if="['menu_bar', 'grid_menu'].includes(form.add_to_menu)">
            <FormRow
              required
              label="Page Name in Sidebar"
              tooltip="This is the name of your page, as it appears in the Sidebar if applicable."
            >
              <TextField
                :rules="[validation.required]"
                placeholder="Page Name in Sidebar"
                v-model="form.menu_title"
              />
            </FormRow>
            <FormRow v-if="form.add_to_menu == 'grid_menu'">
              <template #label>Thumbnail Image<br />(320x180)</template>
              <Uploader :ratio="320 / 180" v-model="form.grid_menu_image" />
            </FormRow>
            <FormRow
              label="Menu Link Type"
              tooltip="Select whether you want the link to appear as text or a button."
            >
              <RadioGroup :items="menuStyles" v-model="form.menu_style" />
            </FormRow>
            <div v-if="form.menu_style == 'button'">
              <FormRow label="Button Background">
                <ColorPicker v-model="form.menu_button_background" />
              </FormRow>
              <FormRow label="Button Text Color">
                <ColorPicker v-model="form.menu_button_text_color" />
              </FormRow>
            </div>
          </div>

          <div v-if="form.add_to_menu == 'menu_bar_footer'">
            <FormRow required label="Small Link Title">
              <TextField
                :rules="[validation.required]"
                placeholder="Small Link Title"
                v-model="form.footer_menu_title"
              />
            </FormRow>
          </div>
        </FormPanel>
        <FormPanel
          label="Meta Tags, Social Share Content"
          v-if="
            currentUser.active_project.is_root ||
            currentUser.active_project.is_profile
          "
        >
          <FormRow
            tooltip="The preview image shown when the site is shared on social media."
          >
            <template #label>Social Share Preview<br />(1200x627)</template>
            <Uploader :ratio="1200 / 627" v-model="form.social_share_preview" />
          </FormRow>
          <FormRow
            label="Social Share Title"
            tooltip="The title shown on the social share preview. Keep this under 55-60 characters."
          >
            <TextField
              v-model="form.social_share_title"
              placeholder="Social Share Title"
            />
          </FormRow>
          <FormRow
            label="Social Share Site Name"
            tooltip="The site listed on the social share preview."
          >
            <TextField
              v-model="form.social_share_site_name"
              placeholder="i.e. “community.ideasunited.com’"
            />
          </FormRow>
          <FormRow
            label="Social Share Description"
            tooltip="Keep this under 200 characters. The shorter the better."
          >
            <TextArea
              v-model="form.social_share_description"
              placeholder="Social Share Description"
            />
          </FormRow>

          <FormRow
            label="SEO/Page Display Title"
            tooltip="Keep this 50–70 characters or less. This is the title of your page, as it appears in browsers or tabs, and search results."
          >
            <template #additional
              >(in browser/tab, and search engine results)</template
            >
            <TextField
              placeholder="SEO/Page Display Title"
              v-model="form.headline"
            ></TextField>
          </FormRow>

          <FormRow
            label="SEO Description"
            tooltip="Add a description for search engines to use in link previews."
          >
            <TextArea
              placeholder="SEO Description"
              v-model="form.description"
            ></TextArea>
          </FormRow>
        </FormPanel>

        <FormPanel label="Custom Styles">
          <small>
            <b>{{ form.title || "page" }}.css</b>
          </small>
          <CodeEditor v-model="form.css" />
        </FormPanel>
      </FormPanels>

      <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
    </v-form>

    <GDriveImageLibraryDialog @selectImage="selectImage" />
  </div>
</template>

<script>
import mixin from "../_extends/form.extends.js";
import GDriveImageLibraryDialog from "@/components/GDriveImageLibraryDialog.vue";

export default {
  extends: mixin,
  components: { GDriveImageLibraryDialog },
  data() {
    return {
      roles: [],
      urlTypes: [
        { value: "relative", title: "Branch URL" },
        { value: "absolute", title: "External Link" },
        { value: "none", title: "None" },
      ],
      menuStyles: [
        { value: "link", title: "Text" },
        { value: "button", title: "Button" },
        { value: "home_logo", title: "Home Logo" },
      ],
    };
  },
  methods: {
    openGDriveFor(fieldName) {
      this.gDriveImageTarget = fieldName;
      this.$root.$emit("GDriveImageLibraryDialog");
    },
    async selectImage(value) {
      this.form[this.gDriveImageTarget] = value;
    },
  },
  computed: {
    addToMenuItems() {
      if (this.currentUser?.active_project?.is_root) {
        return [
          {
            value: "hidden",
            title: "Hide Page",
            tooltip:
              "This setting allows you to hide a Page. It doesn’t delete your Page, but you can only access it by its unique URL.",
          },
        ];
      } else {
        return [
          {
            value: "menu_bar",
            title: "Main Page Navigation",
            tooltip:
              "The Navigation Menu is located at the left of your Page and contains links to each section of content on this opportunity page.",
          },
          {
            value: "menu_bar_footer",
            title: "Fine Print",
            tooltip:
              "These links are included at the bottom of the Navigation Menu, generally used for links to official rules, terms & conditions, etc.",
          },
        ];
      }
    },
  },
};
</script>
