<template>
  <div class="submission-stage"
    :class="{'staging-dates': stagingState}"
  >
    <div class="submission-stage__items">
      <div
        v-for="(item, idx) in datasources.submission_form_states[currentUser.active_project.project_type]"
        :key="idx"
        class="submission-stage__item"
        :class="{ active: activeIndex >= idx }"
      >
        <div class="submission-stage__item-space"></div>
        <div class="submission-stage__item-name">
          <div class="submission-stage__item-name__icon">
            <v-icon color="white" size="12">$check_bold</v-icon>
          </div>
          <div class="submission-stage__item-name__text">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="submission-stage__staging-state" v-if="stagingState">*state from staging</div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    stagingState: Boolean,
  },
  computed: {
    activeIndex() {
      return this.datasources.submission_form_states[this.currentUser.active_project.project_type].findIndex(
        (t) => t.value == this.value
      );
    },
  },
};
</script>

<style scoped lang="scss">
.submission-stage {
  margin: 10px 0 20px 0;
  &__items {
    display: flex;
  }

  &__item {
    display: flex;
    align-items: center;

    &.active &-name__icon {
      background: var(--v-primary-base);
    }
    &.active &-name__text {
      color: var(--v-primary-base);
    }
    &.active &-space {
      background: var(--v-primary-base);
    }

    &-space {
      width: 60px;
      height: 2px;
      background: #56565a;
    }
    &-name {
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      display: flex;
      justify-content: center;

      &__icon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #56565a;
      }

      &__text {
        position: absolute;
        margin-top: 24px;
        color: #56565a;
        white-space: break-spaces;
        width: 100px;
      }
    }
  }
  
  &__staging-state {
    font-size: 12px;
    font-weight: bold;
    color: #f5c000;
    margin-top: 2.5em;
    margin-bottom: -2.5em;
  }

  &.staging-dates {
    .submission-stage__item {

      &.active .submission-stage__item-name__icon {
        background: #f5c000;
      }
      &.active .submission-stage__item-name__text {
        color: #f5c000;
      }
      &.active .submission-stage__item-space {
        background: #f5c000;
      }
    }
  }

  &__items > &__item:first-child &__item-space {
    display: none;
  }
}
</style>
