<template>
  <div>
    <Banner theme="green">
      <template v-slot:title> Pages </template>
      <template v-slot:description>
        Pages are the hubs for any type of content on Commons. It’s where you
        should start when building your site. Pages can house as much or as
        little as you’d like from a series of live events to a simple submission
        form.
      </template>
      <template v-slot:image>
        <img src="@/assets/banners/pages.svg" alt="Pages" />
      </template>
    </Banner>

    <IndexPageActions
      btn-text="New Page"
      btn-to="/pages/new"
      search-placeholder="Search Pages"
      :search-text.sync="searchText"
    />

    <ListPanels
      v-if="currentUser.active_project && currentUser.active_project.is_root"
      :loaded="!!list"
      ref="pageList"
    >
      <div class="page-list-group" v-if="computedList && computedList.length">
        <ListPanel
          v-for="(page, index) in computedList"
          :key="page.id"
          :hide-destroy="page.url == '/'"
          :icon="page.icon"
          :action-path="`/pages/${page.id}`"
          @destroy="destroy(page)"
          @duplicate="duplicate(page)"
          :orderArrows="true"
          :disableUp="index == 0"
          :disableDown="index == computedList.length - 1"
          @moveUp="menuUp(page.id)"
          @moveDown="menuDown(page.id)"
          :inactive="page.hidden"
        >
          <template #title>
            {{ page.title }}
          </template>
          <template #label>
            {{
              page.url_type == "absolute" ? page.absolute_url : `/${page.url}`
            }}
          </template>

          <div v-if="normalizeComponents(page.components).length">
            <component
              :is="'Page' + component.type"
              v-for="(component, idx) in normalizeComponents(page.components)"
              :key="component.id"
              :item="component"
              :widgets="page.widgets"
              @up="up(idx, page)"
              @down="down(idx, page)"
            />
          </div>

          <div v-else class="no-entries">No Content</div>
        </ListPanel>
      </div>
    </ListPanels>
    <ListPanels
      v-else
      :loaded="!!list"
      ref="pageList"
    >
      <div class="page-list-group" v-if="menuList && menuList.length">
        <div class="page-subtitle">Menu Pages</div>
        <ListPanel
          v-for="(page, index) in menuList"
          :key="page.id"
          :hide-destroy="page.url == '/'"
          :icon="page.icon"
          :action-path="`/pages/${page.id}`"
          @destroy="destroy(page)"
          @duplicate="duplicate(page)"
          :orderArrows="true"
          :disableUp="index == 0"
          :disableDown="index == menuList.length - 1"
          @moveUp="menuUp(page.id)"
          @moveDown="menuDown(page.id)"
          :inactive="page.hidden"
        >
          <template #title>
            {{ page.title }}
          </template>
          <template #label>
            {{
              page.url_type == "absolute" ? page.absolute_url : `/${page.url}`
            }}
          </template>

          <div v-if="normalizeComponents(page.components).length">
            <component
              :is="'Page' + component.type"
              v-for="(component, idx) in normalizeComponents(page.components)"
              :key="component.id"
              :item="component"
              :widgets="page.widgets"
              @up="up(idx, page)"
              @down="down(idx, page)"
            />
          </div>

          <div v-else class="no-entries">No Content</div>
        </ListPanel>
      </div>

      <div
        class="page-list-group"
        v-if="menuFooterList && menuFooterList.length"
      >
        <div class="page-subtitle">Small Links</div>
        <ListPanel
          v-for="(page, index) in menuFooterList"
          :key="page.id"
          :hide-destroy="page.url == '/'"
          :icon="page.icon"
          :action-path="`/pages/${page.id}`"
          @destroy="destroy(page)"
          @duplicate="duplicate(page)"
          :orderArrows="true"
          :disableDown="false"
          :disableUp="index == 0"
          @moveUp="footerUp(page.id)"
          @moveDown="footerDown(page.id)"
        >
          <template #title>
            {{ page.title }}
          </template>
          <template #label>
            {{
              page.url_type == "absolute" ? page.absolute_url : `/${page.url}`
            }}
          </template>

          <div v-if="normalizeComponents(page.components).length">
            <component
              :is="'Page' + component.type"
              v-for="(component, idx) in normalizeComponents(page.components)"
              :key="component.id"
              :item="component"
              :widgets="page.widgets"
              @up="up(idx, page)"
              @down="down(idx, page)"
            />
          </div>

          <div v-else class="no-entries">No Content</div>
        </ListPanel>
      </div>

      <div class="page-list-group" v-if="otherList && otherList.length">
        <div class="page-subtitle">Other Pages</div>

        <ListPanel
          v-for="page in otherList"
          :key="page.id"
          :hide-destroy="page.url == '/'"
          :icon="page.icon"
          :action-path="`/pages/${page.id}`"
          @destroy="destroy(page)"
          @duplicate="duplicate(page)"
        >
          <template #title>
            {{ page.title }}
          </template>
          <template #label>
            {{
              page.url_type == "absolute" ? page.absolute_url : `/${page.url}`
            }}
          </template>

          <div v-if="normalizeComponents(page.components).length">
            <component
              :is="'Page' + component.type"
              v-for="(component, idx) in normalizeComponents(page.components)"
              :key="component.id"
              :item="component"
              :widgets="page.widgets"
              @up="up(idx, page)"
              @down="down(idx, page)"
            />
          </div>

          <div v-else class="no-entries">No Content</div>
        </ListPanel>
      </div>

      <div v-if="!computedList || computedList.length == 0" class="no-entries">
        No Pages
      </div>
    </ListPanels>
    <PageDuplicateDialog />
  </div>
</template>

<script>
import _ from "lodash";
import IndexPage from "./index-page";
import PageDuplicateDialog from "@/components/PageDuplicateDialog.vue";

export default {
  name: "PagesPage",
  extends: IndexPage,
  components: { PageDuplicateDialog },
  data() {
    return {
      panel: [],
    };
  },
  methods: {
    up(idx, page) {
      if (idx < 1) return;
      const element = { ...page.components[idx] };
      page.components.splice(idx, 1);
      page.components.splice(idx - 1, 0, element);
      this.save(page);
    },
    down(idx, page) {
      if (idx >= page.components.length - 1) return;
      const element = { ...page.components[idx] };
      page.components.splice(idx, 1);
      page.components.splice(idx + 1, 0, element);
      this.save(page);
    },
    normalizeComponents(list) {
      return list.filter((t) => !["Notification"].includes(t.type));
    },
    async fetchList() {
      const { data } = await this.$api.Page.get({
        sort_by: this.sortBy,
      });
      this.list = data;
    },
    save: _.debounce(async function (page) {
      const pageParams = _.pick(page, ["id", "components"]);
      await this.$api.Page.updateComponents(pageParams);
    }, 300),
    async destroy(page) {
      const confirmed = await this.$confirm({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.Page.destroy({ id: page.id });
      this.list = this.list.filter((t) => t.id != page.id);
    },
    async duplicate(page) {
      const confirmResponse = await new Promise((resolve) => {
        this.$root.$emit("PageDuplicateDialog", {
          callback: (value) => {
            resolve(value);
          },
        });
      });
      if (!confirmResponse.confirmed) return;
      await this.$api.Page.duplicate({
        id: page.id,
        components: page.components,
        duplicate_content: confirmResponse.duplicateContent,
      });
      await this.fetchList();

      const y =
        this.$refs.pageList.$el.getBoundingClientRect().top +
        window.scrollY -
        100;
      if (window.scrollY > y) {
        window.scroll({
          top: y,
          behavior: "smooth",
        });
      }
    },
    async menuUp(id) {
      await this.$api.Page.menuUp({ id });
      await this.fetchList();
    },
    async menuDown(id) {
      await this.$api.Page.menuDown({ id });
      await this.fetchList();
    },
    async footerUp(id) {
      await this.$api.Page.footerUp({ id });
      await this.fetchList();
    },
    async footerDown(id) {
      await this.$api.Page.footerDown({ id });
      await this.fetchList();
    },
  },
  computed: {
    menuList() {
      if (!this.computedList || this.computedList.length == 0) return [];

      return _.sortBy(
        this.computedList.filter((item) => item.add_to_menu == "menu_bar"),
        (item) => item.menu_position
      );
    },
    menuFooterList() {
      if (!this.computedList || this.computedList.length == 0) return [];

      return _.sortBy(
        this.computedList.filter(
          (item) => item.add_to_menu == "menu_bar_footer"
        ),
        (item) => item.footer_position
      );
    },
    otherList() {
      if (!this.computedList || this.computedList.length == 0) return [];
      return this.computedList.filter(
        (item) => (!item.add_to_menu || item.add_to_menu == "hidden") && !item.add_to_footer
      );
    },
  },
};
</script>

<style scoped lang="scss">
.page-list-group {
  margin-bottom: 20px;
}

.footer-pages-separator {
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  align-items: center;

  &:before,
  &:after {
    display: block;
    content: "";
    flex-basis: 50%;
    height: 1px;
    background-color: var(--v-text-base);
    opacity: 0.5;
  }
  &:before {
    margin-right: 0.5em;
  }
  &:after {
    margin-left: 0.5em;
  }

  &__inactive {
    font-style: italic;
    &:before,
    &:after {
      opacity: 0.3;
    }
  }
}
</style>
