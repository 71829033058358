<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="FAQ Info" class="faq__info">
        <FormRow required label="FAQ Name">
          <TextField
            :rules="[validation.required]"
            placeholder="FAQ Name"
            v-model="form.title"
            :rows="3"
          />
        </FormRow>

        <FormRow label="Two-column layout">
          <Checkbox
            v-model="form.settings.two_column"
          />
        </FormRow>
        <FormRow required label="Questions" class="faq__questions">
          <div
            class="faq__item"
            v-for="(item, idx) in form.settings.items"
            :key="item.uuid"
          >
            <div class="faq__controls">
              <v-btn
                class="faq__close"
                icon
                @click="destroyQuestion(idx)"
              >
                <v-icon>$close</v-icon>
              </v-btn>
              <v-btn
                class="faq__moveup"
                icon
                @click="moveUp(idx)"
              >
                <v-icon>$arrow_up</v-icon>
              </v-btn>
              <v-btn
                class="faq__movedown"
                icon
                @click="moveDown(idx)"
              >
                <v-icon>$arrow_down</v-icon>
              </v-btn>
            </div>
            <div class="faq__content">
              <TextArea
                v-if="item.question !== undefined"
                :rules="[validation.required]"
                placeholder="Question"
                v-model="item.question"
                :rows="3"
              />
              <TextArea
                v-if="item.answer !== undefined"
                :rules="[validation.required]"
                placeholder="Answer"
                v-model="item.answer"
                :rows="3"
              />
              <TextArea
                v-if="item.header !== undefined"
                placeholder="Section Header"
                v-model="item.header"
                :rows="3"
              />
            </div>
          </div>
          <div class="faq__add-btns">
            <v-btn
              color="primary"
              elevation="0"
              rounded
              large
              @click="addQuestion"
            >
              <slot name="btn">
                <v-icon>$plus</v-icon>
                Add question
              </slot>
            </v-btn>
            <v-btn
              color="primary"
              elevation="0"
              rounded
              large
              @click="addSectionHeader"
            >
              <slot name="btn">
                <v-icon>$plus</v-icon>
                Add section header
              </slot>
            </v-btn>
          </div>
        </FormRow>

      </FormPanel>
      <FormPanel label="FAQ Details">
        <FormRow
          label="Display Page(s)"
          tooltip="Select the page or pages on which you'd like this FAQ to appear."
        >
          <PagesSelector multiple v-model="form.page_ids"></PagesSelector>
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../../_extends/form.extends.js";
import { v4 as uuidv4 } from "uuid";

export default {
  extends: mixin,
  methods: {
    addQuestion() {
      if (!this.form.settings.items) this.$set(this.form.settings, "items", []);
      this.form.settings.items.push({
        question: "",
        answer: "",
        uuid: uuidv4(),
      });
    },
    addSectionHeader() {
      if (!this.form.settings.items) this.$set(this.form.settings, "items", []);
      this.form.settings.items.push({
        header: "",
        uuid: uuidv4(),
      });
    },
    destroyQuestion(idx) {
      this.form.settings.items.splice(idx, 1);
    },
    moveUp(idx) {
      if (idx < 1) return;
      const element = { ...this.form.settings.items[idx] };
      this.form.settings.items.splice(idx, 1);
      this.form.settings.items.splice(idx - 1, 0, element);
    },
    moveDown(idx) {
      if (idx >= this.form.settings.items.length - 1) return;
      const element = { ...this.form.settings.items[idx] };
      this.form.settings.items.splice(idx, 1);
      this.form.settings.items.splice(idx + 1, 0, element);
    },
  }
};
</script>

<style lang="scss" scoped>
.faq {
  &__item {
    border: thin solid rgba(0, 0, 0, 0.1);
    border-radius: 0.5em;
    margin-bottom: 20px;
    position: relative;
  }

  &__content {
    padding: 1em 2.5em 1em 1em;
    > div {
      margin-bottom: 0.5em;
    }
    .text-field {
      width: 100%;
    }
  }

  &__controls {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
  }

  &__add-btns {
    margin-bottom: -20px;
    > button {
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }
}
</style>