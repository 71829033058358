<template>
  <div class="event-datagrid">
    <EventDataCard
      v-for="event in list"
      :key="event.id"
      :event="event"
      @destroy="$emit('destroy', $event)"
      @duplicate="$emit('duplicate', $event)"
    />
  </div>
</template>

<script>
import EventDataCard from '../datacard/event.datacard';

export default {
  components: { EventDataCard },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
.event-datagrid {
  margin: -20px;
  display: flex;
  flex-wrap: wrap;

  .event-datacard {
    margin: 20px;
    width: calc(33.33% - 40px);
  }
}
</style>
