<template>
  <v-dialog :max-width="600" :value="value" @input="$emit('input', $event)">
    <div class="popup-badges">
      <div class="page-title">Add new badge</div>
      <v-text-field
        prepend-inner-icon="$search"
        v-model="search"
        placeholder="Search badges"
      ></v-text-field>
      <Badge :badge="badge" v-for="badge in filteredBadges" :key="badge.id">
        <v-btn
          class="elevation-0"
          small
          color="primary"
          @click="$emit('add', badge.id)"
          >Add Badge</v-btn
        >
      </Badge>
    </div>
  </v-dialog>
</template>

<script>
import Badge from "./Badge";

export default {
  components: { Badge },
  props: {
    value: Boolean,
    badges: Array,
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    filteredBadges() {
      return this.badges.filter((t) =>
        t.title.toLowerCase().includes(this.search)
      );
    },
  },
  watch: {
    value() {
      this.search = "";
    },
  },
};
</script>

<style scoped lang="scss">
.popup-badges {
  padding: 25px;
  background: white;
}

.page-title {
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 0;
}
</style>
