<template>
  <div class="settings-page">
    <Profile :submit="submit" v-model="form" full btnText="Update Profile" />
  </div>
</template>

<script>
import Profile from "./profile.page";
export default {
  components: { Profile },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async setForm() {
      this.form = { ...this.currentUser };
    },
    async submit() {
      await this.$api.Profile.update(this.form);
      this.$store.dispatch("user/fetch");
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.settings-page {
  max-width: 800px;
  margin: auto;
}
</style>
