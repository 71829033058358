<template>
  <div class="search" :style="{ minWidth }">
    <v-combobox
      append-icon=""
      :items="entries"
      :loading="isLoading"
      :search-input.sync="search"
      hide-no-data
      hide-details
      item-text="title"
      return-object
      placeholder="College or University"
      solo
      flat
      clearable
      :filter="schoolFilter"
      outlined
      v-model="localValue"
      rounded
    >
      <template v-slot:item="data">
        <template>
          <v-list-item-content>
            <v-list-item-title>
              <div class="search-title">
                {{ data.item.title }}
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    initialValue: String,
    placeholder: String,
    minWidth: {
      type: String,
      default: "200px",
    },
  },
  data() {
    return {
      search: null,
      entries: [],
      isLoading: false,
      selected: null,
      init: true,
      localValue: null,
    };
  },
  mounted() {
    if(this.initialValue) this.localValue = this.initialValue;
  },
  methods: {
    searchCallback: _.debounce(async function (q) {
      try {
        this.isLoading = true;
        const { data } = await this.$api.School.search({ q });
        this.entries = data;
        if(this.init && this.value) {
          const match = this.entries.find(s => s.title == this.value);
          if(match) this.selected = match.title;
          this.init = false;
        }
      } finally {
        this.isLoading = false;
      }
    }, 300),
    schoolFilter() {
      return true;
    },
  },
  watch: {
    search(val) {
      if(!this.selected) {
        this.$emit('input', {
          id: null,
          title: val,
        });
      }
      if(this.search) this.searchCallback(val);
    },
    selected: {
      deep: true,
      handler(val) {
        if (val) {
          this.$emit('input', val);
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.search {
  &-title {
  }
  &-group {
    color: red;
    font-size: 10px;
    font-weight: bold;
  }
  &::v-deep {
    .v-input {
      position: relative;
    }
    .v-input:before {
      content: "";
      position: absolute;
      left: -1px;
      right: -1px;
      top: -1px;
      bottom: -1px;
      border: 1px solid transparent;
      border-radius: 28px;
      transition: 0.3s;
    }

    .v-input--is-focused {
      .v-icon__svg {
        fill: var(--v-primary-base);
      }
    }

    .v-input__prepend-inner {
      padding-right: 16px !important;
    }

    .v-label {
      color: var(--v-text-base);
      opacity: 0.5;
      font-size: 14px;
      max-width: 95%;
    }
    .v-text-field--outlined fieldset {
      border-color: var(--v-border-base);
    }
  }
}

@media screen and (max-width: 960px) {
  .search {
    min-width: 0 !important;
  }
}
</style>
