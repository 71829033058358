<template>
  <div v-if="options.length">
    <Checkbox
      v-for="item in options"
      :key="item.uuid"
      :label="item.title"
      :value="localValue[item.uuid]"
      @input="changeItem(item.uuid, $event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: Object,
    rules: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localValue: { ...this.value },
    };
  },
  methods: {
    changeItem(uuid, event) {
      this.localValue[uuid] = event ? true : false;
      this.$emit("input", this.localValue);
    },
  },
};
</script>

<style></style>
