<template>
  <div>
    <Banner theme="orange">
      <template v-slot:title> Specialties </template>
      <template v-slot:description> Specialties </template>
      <template v-slot:image>
        <img src="@/assets/banners/specialties.svg" alt="Specialties" />
      </template>
    </Banner>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="New Specialty"
      btn-to="/specialties/new"
      :sort-by.sync="sortBy"
      :search-text.sync="searchText"
      search-placeholder="Search Specialty"
    />

    <div class="page-subtitle">Specialties</div>
    <div v-if="list">
      <div v-if="computedList.length">
        <SpecialtiesDataTable :list="computedList" @destroy="destroy" />
        <!-- <Pagination :perPage.sync="perPage" :page.sync="page" /> -->
      </div>
      <div v-else class="no-entries">No Specialties</div>
    </div>
    <Spinner v-else relative />
  </div>
</template>

<script>
import SpecialtiesDataTable from "@/components/dataview/specialties.datatable";
import IndexPage from "./index-page";

export default {
  name: "SpecialtiesPage",
  components: { SpecialtiesDataTable },
  extends: IndexPage,
  methods: {
    async fetchList() {
      const { data } = await this.$api.Specialty.get({
        sort_by: this.sortBy,
      });
      this.list = data;
    },
    async destroy({ id }) {
      const confirmed = await this.$confirm({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.Specialty.destroy({ id });
      this.list = this.list.filter((t) => t.id != id);
    },
  },
};
</script>

<style scoped lang="scss"></style>
