<template>
  <ProfileReviews :params="{ user_id: $route.params.id }" />
</template>

<script>
import ProfileReviews from "@/pages/profile_reviews.page.vue";
export default {
  components: { ProfileReviews },
};
</script>

<style scoped lang="scss"></style>
